import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { makeStyles } from '@mui/styles';
import gql from 'graphql-tag';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Fab from '@mui/material/Fab';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { DatePicker } from '@mui/x-date-pickers';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';

import { snackBarSettings } from '../../../constants';
import CallListRow from './CallListRow';
import CallListSearch from './CallListSearch';

const useStyles = makeStyles({
  select: {
    display: 'flex',
    justifyContent: 'center',
    margin: '10px 18px 10px 18px',
    paddingTop: '10px',
    paddingRight: '35px',
    width: '100%',
  },
  formControl: {
    margin: '10px',
    minWidth: 120,
  },
  tabPanel: {
    padding: '24px 8px 5px 8px',
  },
  button: {
    color: 'red',
  },
  fab: {
    bottom: '10px',
    right: '10px',
    position: 'fixed',
  },
  mobileFab: {
    marginLeft: '18px',
    marginRight: '10px',
  },
  chips: {
    display: 'flex',
    margin: '0px 18px 0px 18px',
  },
  chip: {
    marginRight: '5px',
  },
});

const GET_BY_PAGE = gql`
  query calls($page_url: String!, $gatewayNumber: String!) {
    smsCall {
      callsByPage(page_url: $page_url, number: $gatewayNumber) {
        page_size
        previous_page_uri
        next_page_uri
        calls {
          direction
          sid
          from
          to
          to_formatted
          from_formatted
          answered_by
          duration
          status
          caller_name
          end_time
        }
      }
    }
  }
`;

const GATEWAYS_QUERY = gql`
  query gateways {
    gateways {
      getGateways {
        gateways {
          id
          gateway_type
          name
          phone_number
        }
      }
    }
  }
`;

const CALL_RECORDING_QUERY = gql`
  query calls(
    $gatewayNumber: String!
    $from_date: String!
    $inbound: Boolean
    $outbound: Boolean
    $startDate: String
    $endDate: String
    $search_number: String
  ) {
    smsCall {
      callsByFilters(
        number: $gatewayNumber
        from_date: $from_date
        inbound: $inbound
        outbound: $outbound
        start_time: $startDate
        end_time: $endDate
        search_number: $search_number
        page_size: 30
      ) {
        page_size
        previous_page_uri
        next_page_uri
        calls {
          direction
          sid
          from
          to
          to_formatted
          from_formatted
          answered_by
          duration
          status
          caller_name
          end_time
        }
      }
    }
  }
`;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 450,
      width: 300,
    },
  },
};

const date = moment().format('YYYY-MM-DD');

const CallRecordingTable = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [gatewayNumber, setGatewayNumber] = useState('');
  const [tab, setTab] = useState('incoming');
  const [showFilters, setShowFilters] = useState(false);
  const [allCalls, setAllCalls] = useState([]);
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const { loading: gateLoading, data: gateData } = useQuery(GATEWAYS_QUERY);
  const [getCallsForNumber, { called, loading }] = useLazyQuery(
    CALL_RECORDING_QUERY,
    {
      onCompleted: data => {
        const dataCalls = data?.smsCall?.callsByFilters?.calls || [];
        setAllCalls(dataCalls);
        setNextPageUrl(data?.smsCall?.callsByFilters?.next_page_uri || null);
      },
    },
  );
  const [getMoreCalls, { loading: infiniteLoading }] = useLazyQuery(
    GET_BY_PAGE,
    {
      onCompleted: data => {
        const moreCalls = data?.smsCall?.callsByPage?.calls || [];
        // Load more calls into the list
        setAllCalls([...allCalls, ...moreCalls]);
        setNextPageUrl(data?.smsCall?.callsByPage?.next_page_uri || null);
      },
    },
  );
  const windowHeight = window.innerHeight;
  const gateways =
    gateData?.gateways.getGateways?.gateways.sort((a, b) =>
      a.name.localeCompare(b.name),
    ) || [];
  const missingNumber =
    gatewayNumber === '' ? <p>Please select a gateway</p> : <> </>;
  const emptyList = called && isEmpty(allCalls);

  const handleGatewayChange = event => {
    setGatewayNumber(event.target.value);
  };

  const handleTabChange = (event, tabValue) => {
    setTab(tabValue);
  };

  const handleStartDateChange = date => {
    setStartDate(moment(date).format('YYYY-MM-DD'));
  };

  const handleEndDateChange = date => {
    var newDate = moment(date).add(1, 'days');
    setEndDate(moment(newDate).format('YYYY-MM-DD'));
  };

  const resetDates = () => {
    setEndDate(undefined);
    setStartDate(undefined);
  };

  const resetDateFromChip = isEndDate => {
    isEndDate ? setEndDate(undefined) : setStartDate(undefined);
  };

  const handleSearch = event => {
    if (event.key === 'Enter') {
      if (event.target.value.length === 10) {
        getCallsForNumber({
          variables: {
            gatewayNumber,
            from_date: date,
            inbound: tab === 'incoming',
            outbound: tab === 'outbound',
            startDate,
            endDate,
            search_number: event.target.value,
          },
        });
      } else {
        enqueueSnackbar(
          'Invalid number. Please enter a number including area code with no spaces.',
          snackBarSettings,
        );
      }
    }
  };

  const handleSearchReset = event => {
    getCallsForNumber({
      variables: {
        gatewayNumber,
        from_date: date,
        inbound: tab === 'incoming',
        outbound: tab === 'outbound',
        startDate,
        endDate,
        search_number: undefined,
      },
    });
  };

  useEffect(() => {
    if (tab && gatewayNumber !== '') {
      getCallsForNumber({
        variables: {
          gatewayNumber,
          from_date: date,
          inbound: tab === 'incoming',
          outbound: tab === 'outbound',
          startDate,
          endDate,
          search_number: undefined,
        },
        fetchPolicy: 'network-only',
      });
    }
  }, [tab, gatewayNumber, getCallsForNumber, startDate, endDate]);

  const infiniteLoad = ({ currentTarget }) => {
    if (
      currentTarget.scrollTop + currentTarget.clientHeight + 1 >=
      currentTarget.scrollHeight
    ) {
      if (nextPageUrl) {
        getMoreCalls({
          variables: {
            page_url: nextPageUrl,
            gatewayNumber,
          },
        });
      }
    }
  };

  if (gateLoading) {
    return <LoadingBackdrop open={true} />;
  }

  return (
    <div style={{ overflowX: 'hidden' }}>
      <Hidden lgDown>
        <FormControl
          variant="outlined"
          className={classes.select}
          style={{ width: '30%' }}
        >
          <InputLabel id="gateway-select-label">Gateway</InputLabel>
          <Select
            labelId="gateway-select-label"
            id="gateway-select"
            value={gatewayNumber}
            onChange={handleGatewayChange}
            MenuProps={MenuProps}
          >
            {gateways.map(({ id, name, phone_number }) => (
              <MenuItem key={id} value={phone_number}>
                {`${name} - ${phone_number}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Hidden>
      <Hidden lgUp>
        <FormControl variant="outlined" className={classes.select}>
          <InputLabel id="gateway-select-label">Gateway</InputLabel>
          <Select
            labelId="gateway-select-label"
            id="gateway-select"
            value={gatewayNumber}
            onChange={handleGatewayChange}
            MenuProps={MenuProps}
          >
            {gateways.map(({ id, name, phone_number }) => (
              <MenuItem key={id} value={phone_number}>
                {`${name} - ${phone_number}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Hidden>
      <Grid
        container
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
        }}
      >
        <Drawer
          anchor={'left'}
          open={showFilters}
          onClose={() => setShowFilters(false)}
        >
          <div style={{ display: 'flex' }}>
            <IconButton
              style={{
                display: 'flex',
                marginLeft: 'auto',
              }}
              onClick={() => setShowFilters(false)}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </div>
          <h2 style={{ padding: '20px' }}>Filters</h2>
          <Divider
            style={{
              marginLeft: '20px',
              marginRight: '20px',
            }}
          />
          <List
            style={{
              padding: '10px',
            }}
          >
            <Button className={classes.button} onClick={() => resetDates()}>
              <HighlightOffIcon /> RESET ALL
            </Button>
            <ListItem>
              <DatePicker
                disableToolbar
                variant="inline"
                format="L"
                margin="normal"
                id="start-date"
                label="Start Date"
                value={startDate && moment(startDate)}
                onChange={handleStartDateChange}
              />
            </ListItem>
            <ListItem>
              <DatePicker
                disableToolbar
                variant="inline"
                format="L"
                margin="normal"
                id="end-date"
                label="End Date"
                value={endDate ? moment(endDate).subtract(1, 'days') : null}
                onChange={handleEndDateChange}
              />
            </ListItem>
          </List>
        </Drawer>
        <Hidden lgDown>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <Tooltip title="Filter List">
                <Fab
                  color="primary"
                  onClick={() => setShowFilters(true)}
                  size="small"
                  className={classes.mobileFab}
                >
                  <FilterListIcon />
                </Fab>
              </Tooltip>
              {startDate && (
                <Chip
                  className={classes.chip}
                  label={`Start: ${moment(startDate).format('MMM Do')}`}
                  onDelete={() => resetDateFromChip(false)}
                />
              )}
              {endDate && (
                <Chip
                  className={classes.chip}
                  label={`End: ${moment(endDate)
                    .subtract(1, 'days')
                    .format('MMM Do')}`}
                  onDelete={() => resetDateFromChip(true)}
                />
              )}
              {!startDate && !endDate && (
                <Chip
                  className={classes.chip}
                  label={`${moment(date).format('MMM Do')}`}
                  onDelete={() =>
                    enqueueSnackbar('A date filter needs to be applied')
                  }
                />
              )}
            </Grid>
            <Grid item xs={4} style={{ display: 'flex' }}>
              <CallListSearch
                handleSearch={handleSearch}
                handleReset={handleSearchReset}
                outline={false}
              />
            </Grid>
          </Grid>
        </Hidden>
        <Hidden lgUp>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
            }}
          >
            <CallListSearch
              handleSearch={handleSearch}
              handleReset={handleSearchReset}
              outline={true}
            />
          </Grid>
        </Hidden>
        <Hidden lgUp>
          <Grid item xs={12} className={classes.chips}>
            {startDate && (
              <Chip
                className={classes.chip}
                label={`Start: ${moment(startDate).format('MMM Do')}`}
                onDelete={() => resetDateFromChip(false)}
              />
            )}
            {endDate && (
              <Chip
                className={classes.chip}
                label={`End: ${moment(endDate)
                  .subtract(1, 'days')
                  .format('MMM Do')}`}
                onDelete={() => resetDateFromChip(true)}
              />
            )}
            {!startDate && !endDate && (
              <Chip
                className={classes.chip}
                label={`${moment(date).format('MMM Do')}`}
                onDelete={() =>
                  enqueueSnackbar('A date filter needs to be applied')
                }
              />
            )}
          </Grid>
        </Hidden>
      </Grid>
      <TabContext value={tab}>
        <TabList onChange={handleTabChange} variant="fullWidth">
          <Tab
            label="Incoming"
            style={{ marginRight: '20px' }}
            value="incoming"
          />
          <Tab
            label="Outgoing"
            style={{ marginLeft: '20px' }}
            value="outbound"
          />
        </TabList>
        <TabPanel value="incoming" className={classes.tabPanel}>
          {!loading && (
            <TableContainer
              component={Paper}
              style={{ maxHeight: windowHeight - 330 }}
              onScroll={e => infiniteLoad(e)}
            >
              <Table>
                <TableBody>
                  {allCalls.map(call => (
                    <TableRow key={call.sid}>
                      <CallListRow
                        call={call}
                        outbound={false}
                        gatewayNumber={gatewayNumber}
                      />
                    </TableRow>
                  ))}
                  {emptyList && !loading && (
                    <>No incoming call recordings found.</>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {missingNumber}
        </TabPanel>
        <TabPanel value="outbound" className={classes.tabPanel}>
          {!loading && (
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  {allCalls.map(call => (
                    <TableRow key={call.sid}>
                      <CallListRow
                        call={call}
                        outbound={true}
                        gatewayNumber={gatewayNumber}
                      />
                    </TableRow>
                  ))}
                  {emptyList && !loading && (
                    <>No outgoing call recordings found.</>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {missingNumber}
        </TabPanel>
      </TabContext>
      <Hidden lgUp>
        <Tooltip title="Filter List">
          <Fab
            color="primary"
            onClick={() => setShowFilters(true)}
            size="medium"
            className={classes.fab}
          >
            <FilterListIcon />
          </Fab>
        </Tooltip>
      </Hidden>
      <LoadingBackdrop open={loading || infiniteLoading} />
    </div>
  );
};

export default CallRecordingTable;
