/* external */
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { startCase } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';

/* Material UI */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Alert from '@mui/material/Alert';
import TextFieldControl from 'components/MaterialUI/TextFieldControl';

/* internal */
import ConfirmDialog from 'components/MaterialUI/ConfirmDialog';
import { useUserContext } from 'components/MaterialUI/UserContext';

import { FREQUENCY_NAMES, PAYMENT_TYPE } from 'modules/pitches/const';
import { errorHandler, processPitchData } from 'modules/pitches/utils';

import { Finance, formatPrice } from 'utils';

import PaymentOptionDialog from './PaymentOptionDialog';
import PitchVehicleSummaryCard from './PitchVehicleSummaryCard';
import { Role } from 'constants.js';

const { FINANCE, LEASE, CASH } = PAYMENT_TYPE;

const PITCH_FRAGMENT = gql`
  fragment PaymentStepPitch on Pitch {
    appraisals {
      id
      lienAmount
      value
    }
    vehicles {
      id
      salePrice
      discounts {
        id
        price
      }
      accessories {
        id
        price
      }
      paymentOptions {
        id
        allowedMileage
        amount
        cashAmount
        cashDown
        dueAtDelivery
        frequency
        hideRate
        paymentType
        price
        rate
        residualAmount
        term
      }
      permissions {
        addPaymentOptions
      }
    }
    ...PitchVehicleSummaryCardPitch
  }
  ${PitchVehicleSummaryCard.fragments.pitch}
`;

const UPDATE_PITCH = gql`
  mutation updatePitch($id: Int!, $data: PitchUpdate!) {
    pitches {
      updatePitch(id: $id, data: $data) {
        id
        ...PaymentStepPitch
      }
    }
  }
  ${PITCH_FRAGMENT}
`;

const useStyles = makeStyles(theme => ({
  addButton: {
    color: theme.actions.create.color,
    '& disabled': theme.actions.disabled,
  },
  cancelButton: {
    margin: theme.spacing(1),
    ...theme.actions.cancel,
  },
  finishButton: {
    ...theme.actions.confirm,
    margin: theme.spacing(1),
    '& disabled': theme.actions.disabled,
  },
}));

const useInputLabelStyles = makeStyles(theme => ({
  root: {
    '&.Mui-focused': {
      color: theme.actions.create.color,
    },
  },
  focused: {},
}));

const useInputStyles = makeStyles(theme => ({
  root: {
    '&$underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: theme.actions.create.color,
    },
    '&$underline:after': {
      borderBottomColor: theme.actions.create.color,
    },
  },
  underline: {},
}));

const floatsEqual = (aFloat, bFloat, tolerance = 0.005) =>
  Math.abs(parseFloat(aFloat) - parseFloat(bFloat)) < tolerance;

const paymentOptionsStale = (paymentOptions, newPrice) =>
  paymentOptions.some(({ price }) => !floatsEqual(price, newPrice));

const PaymentStep = ({
  pitch,
  onBack,
  onCancel,
  onFinish,
  onUpdate,
  onPrevious,
}) => {
  const theme = useTheme();
  const {
    currentUser: { role },
  } = useUserContext();
  const classes = useStyles(theme);
  const inputLabelClasses = useInputLabelStyles(theme);
  const inputClasses = useInputStyles(theme);
  const { enqueueSnackbar: snackIt } = useSnackbar();
  const [editPaymentOption, setEditPaymentOption] = useState(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showPriceWarningAlert, setShowPriceWarningAlert] = useState(false);
  const [updatePitch, { loading }] = useMutation(UPDATE_PITCH);
  const methods = useForm({
    defaultValues: pitch ?? {},
    shouldUnregister: true,
  });
  const vehicles = pitch?.vehicles ?? [];
  const { permissions } = vehicles?.[0] ?? {};

  const isManagerOrAbove =
    Role.ALL_SALES_MANAGERS.includes(role) || role === Role.ORGANIZATION_ADMIN;

  const {
    control,
    clearErrors,
    handleSubmit,
    formState,
    register,
    setError,
    setValue,
  } = methods;

  const shouldDirty = true;
  const { isDirty } = formState;

  register('vehicles.0.id'); // vehicle will have an id at this point, and we
  // must submit it with the form data or else the pitch-service will create a
  // new one
  register('vehicles.0.paymentOptions');

  const salePrice = useWatch({ control, name: 'vehicles.0.salePrice' });
  const { id, appraisals = [] } = pitch;
  const discounts = useWatch({ control, name: 'vehicles.0.discounts' });
  const accessories = useWatch({ control, name: 'vehicles.0.accessories' });

  const paymentOptions = useWatch({
    control,
    name: 'vehicles.0.paymentOptions',
    defaultValue: vehicles[0]?.paymentOptions,
  });

  const tradeInTotal = appraisals.reduce(
    (total, appraisal) =>
      total + parseFloat((appraisal.value ?? 0) - (appraisal.lienAmount ?? 0)),
    0,
  );

  const discountsTotal = discounts.reduce(
    (total, { price }) => total + parseFloat(price ?? 0),
    0,
  );

  const accessoriesTotal = accessories.reduce(
    (total, { price }) => total + parseFloat(price ?? 0),
    0,
  );

  const adjustmentsTotal = accessoriesTotal - discountsTotal;
  const totalPrice = parseFloat(salePrice) + adjustmentsTotal - tradeInTotal;

  useEffect(() => {
    if (
      paymentOptionsStale(paymentOptions, totalPrice) &&
      !showPriceWarningAlert
    )
      setShowPriceWarningAlert(true);
    else if (
      !paymentOptionsStale(paymentOptions, totalPrice) &&
      showPriceWarningAlert
    )
      setShowPriceWarningAlert(false);
  }, [totalPrice, paymentOptions, showPriceWarningAlert]);

  const handleConfirmPaymentOptionDialog = data => {
    if (data.id)
      // edited
      setValue(
        'vehicles.0.paymentOptions',
        paymentOptions.map(po =>
          parseInt(po.id, 10) === parseInt(data.id, 10) ? data : po,
        ),
        {
          shouldDirty,
        },
      );
    else if (data.index || data.index === 0)
      // edited paymentOption that hasn't been saved yet
      setValue(
        'vehicles.0.paymentOptions',
        paymentOptions.map((po, poIndex) =>
          poIndex === parseInt(data.index, 10) ? data : po,
        ),
        { shouldDirty },
      );
    // created new
    else
      setValue('vehicles.0.paymentOptions', [...paymentOptions, data], {
        shouldDirty,
      });
    setEditPaymentOption(null);
  };

  const handleClickCancel = () =>
    isDirty ? setShowConfirmDialog(true) : onCancel();

  const handleClickFinish = () => {
    clearErrors();
    clearErrors('error');
    handleSubmit(data =>
      updatePitch({ variables: { id, data: processPitchData(data) } }).then(
        () => {
          onUpdate();
          onFinish();
        },
        errorHandler(snackIt, setError),
      ),
    )();
  };

  const handleUpdatePaymentOptions = () => {
    setValue(
      `vehicles.0.paymentOptions`,
      paymentOptions.map(po =>
        po.paymentType === FINANCE
          ? {
              ...po,
              price: totalPrice,
              amount: Finance.payment(
                totalPrice - po.cashDown,
                po.rate / 100.0,
                (po.term / 12.0) * po.frequency,
                0,
                0,
                po.frequency,
              ),
            }
          : po.paymentType === LEASE
          ? {
              ...po,
              price: totalPrice,
              amount: Finance.leasePayment(
                totalPrice - po.cashDown,
                po.rate / 100.0,
                po.term,
                po.residualAmount,
                po.frequency,
              ),
            }
          : // If it's cash type, and we've set the cash amount to the price,
          // then we probably want to update the cash amount to the NEW price
          // If the cash amount is NOT the same as the price, then there's no way
          // to determine what the new cashAmount should be, so just update the
          // price on the paymentOption and leave the cashAmount and amount alone
          po.paymentType === CASH && floatsEqual(po.price, po.cashAmount)
          ? {
              ...po,
              price: totalPrice,
              cashAmount: totalPrice,
              amount: totalPrice,
            }
          : { ...po, price: totalPrice },
      ),
      { shouldDirty },
    );
  };

  const InputLabelProps = { shrink: true, classes: inputLabelClasses };
  const InputProps = { classes: inputClasses };

  return (
    <Box m={3}>
      <ConfirmDialog
        isOpen={showConfirmDialog}
        onConfirm={onCancel}
        onClose={() => setShowConfirmDialog(false)}
        text={
          'The data on this page has changed - are you sure you want to cancel?  \
        You will lose any unsaved changes.'
        }
      />
      <Grid container direction="row">
        <Grid
          alignContent="flex-start"
          alignItems="stretch"
          container
          direction="row"
          item
          sm={6}
          spacing={2}
          xs={12}
        >
          <Grid item xs={12}>
            <Typography variant="h5">Payment Worksheet</Typography>
            {showPriceWarningAlert && (
              <Alert>
                Sale price has been modified. Update payment options?{' '}
                <Button onClick={handleUpdatePaymentOptions}>Update</Button>
              </Alert>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldControl
              disabled={!isManagerOrAbove}
              InputLabelProps={InputLabelProps}
              InputProps={{
                ...InputProps,
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              control={control}
              label="Sale Price"
              name="vehicles.0.salePrice"
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldControl
              disabled
              name="vehicles.0.regularPrice"
              type="number"
              control={control}
              InputProps={{
                ...InputProps,
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              label="List Price"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel shrink>Trade-In Value</InputLabel>
            <Input
              disabled
              value={tradeInTotal}
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} container alignContent="flex-end">
            <Grid item xs={12}>
              <Box
                fontSize="18px"
                display="flex"
                justifyContent="space-between"
                paddingRight={3}
              >
                <Box component="span">Adjustments:</Box>
                <Box>{formatPrice(adjustmentsTotal, { cents: true })}</Box>
              </Box>
            </Grid>
          </Grid>
          <Grid item sm={6}></Grid>
          <Grid item xs={12} sm={6} container alignContent="flex-end">
            <Grid item xs={12}>
              <Box
                fontWeight="bold"
                fontSize="18px"
                display="flex"
                justifyContent="space-between"
                paddingRight={3}
              >
                <Box>Total Price:</Box>
                <Box>{formatPrice(totalPrice, { cents: true })}</Box>
              </Box>
            </Grid>
          </Grid>
          {/* <Grid item xs={12}>
            <Box paddingTop={3}>
              <Typography variant="h6">Vehicle Incentives</Typography>
              Vehicle incentives data?
            </Box>
          </Grid> */}
        </Grid>
        <Grid container item xs={12} sm={6}>
          <FormProvider {...methods}>
            <PitchVehicleSummaryCard
              pitchVehicle={pitch?.vehicles?.[0]}
              skipSync
              style={{ width: '100%' }}
            />
          </FormProvider>
        </Grid>
      </Grid>

      <Typography variant="h6">
        Payment Options{' '}
        <IconButton
          disabled={!permissions?.addPaymentOptions}
          onClick={() => setEditPaymentOption({})}
          className={classes.addButton}
          size="large"
        >
          <AddBoxIcon />
        </IconButton>
        <PaymentOptionDialog
          paymentOption={editPaymentOption}
          open={Boolean(editPaymentOption)}
          onClose={() => setEditPaymentOption(null)}
          onConfirm={handleConfirmPaymentOptionDialog}
          salePrice={totalPrice}
        />
      </Typography>
      <Grid container spacing={3} alignItems="flex-start">
        {paymentOptions.map((paymentOption, index) => {
          const {
            allowedMileage,
            amount,
            cashAmount,
            cashDown,
            dueAtDelivery,
            frequency,
            hideRate,
            paymentType,
            rate,
            residualAmount,
            term,
          } = paymentOption;

          let displayData = [
            ['Down', formatPrice(cashDown)],
            ['Term', `${term} mo`],
            ['Rate', `${parseFloat(rate).toFixed(2)}%`],
          ];
          if (paymentType === 'lease')
            displayData = [
              ...displayData,
              ['Residual', formatPrice(residualAmount)],
              ['Due at Delivery', formatPrice(dueAtDelivery)],
              ['Mileage Allow (km/yr)', allowedMileage],
            ];
          else if (paymentType === 'cash') displayData = [];
          return (
            <Grid container item key={index} xs={3}>
              <Grid item xs={12} style={{ margin: theme.spacing(1) }}>
                <Typography
                  variant="h5"
                  style={{ fontWeight: 'bold', textAlign: 'center' }}
                >
                  Option {index + 1}
                  <IconButton
                    size="small"
                    onClick={() =>
                      setEditPaymentOption({ ...paymentOption, index })
                    }
                    style={{ float: 'right', paddingLeft: theme.spacing(1) }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() =>
                      setValue(
                        'vehicles.0.paymentOptions',
                        paymentOptions.filter((_, _index) => _index !== index),
                        { shouldDirty: true },
                      )
                    }
                    style={{ float: 'right', paddingLeft: theme.spacing(1) }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Typography>
              </Grid>
              <Box border={1} width="100%">
                <Typography align="center" variant="h6">
                  {startCase(paymentType)}
                </Typography>
                {displayData.map(([label, value]) => (
                  <Grid container justifyContent="center" key={label}>
                    <Grid
                      style={{ margin: theme.spacing(1) }}
                      container
                      item
                      xs={9}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item xs={4}>
                        {label}
                      </Grid>
                      {label === 'Rate' && hideRate && (
                        <Grid item xs={4} style={{ paddingTop: '3px' }}>
                          <VisibilityOffIcon fontSize="small" />
                        </Grid>
                      )}
                      <Grid item xs={4}>
                        <Chip style={{ width: '100%' }} label={value} />
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
                <Box padding={1}>
                  <hr />
                </Box>
                <Box textAlign="center" fontSize="16px">
                  {`${
                    paymentType === 'cash'
                      ? 'Total'
                      : FREQUENCY_NAMES[frequency]
                  } Payment:`}
                </Box>
                <Box textAlign="center" fontSize="20px" fontWeight="bold">
                  {formatPrice(paymentType === 'cash' ? cashAmount : amount)}
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
      <Box display="flex" justifyContent="flex-end" m={1}>
        <Button
          className={classes.cancelButton}
          variant="contained"
          color="secondary"
          onClick={handleClickCancel}
        >
          Cancel
        </Button>
        <Button
          className={classes.cancelButton}
          onClick={onPrevious}
          variant="contained"
        >
          Back
        </Button>
        <Button
          disabled={loading}
          className={classes.finishButton}
          onClick={handleClickFinish}
          variant="contained"
        >
          Finish
        </Button>
      </Box>
    </Box>
  );
};

PaymentStep.fragments = {
  pitch: PITCH_FRAGMENT,
};
PaymentStep.label = 'Payment Worksheet';
export default PaymentStep;
