/* External */
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import { NetworkStatus } from '@apollo/client';
import moment from 'moment';

/* Material UI */
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import ErrorDisplay from 'components/MaterialUI/ErrorDisplay';
import Loading from 'components/MaterialUI/Loading';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';

/* Internal */
import GatewayPicker from '../GatewayPicker';
import MessageSearchFilters from './MessageSearchFilters';
import { HEAD_CELLS } from './const';
import MessageSearchTableRows from './MessageSearchTableRows';
import ActionsMenu from '../ActionsMenu';

// ** QUERIES/MUTATIONS **

const GATEWAY_QUERY = gql`
  query gatewayQuery($gatewayId: Int!) {
    sms {
      gateway(id: $gatewayId) {
        id
        name
        phone_number
      }
      gatewayUsers(id: $gatewayId) {
        display_name
        username
      }
    }
  }
`;

const MESSAGES_QUERY = gql`
  query messagesQuery(
    $filters: [QueryFilter]
    $sort: [QuerySortElement]
    $pageSize: Int
    $page: Int
  ) {
    sms {
      messages(
        filters: $filters
        sort: $sort
        pageSize: $pageSize
        page: $page
      ) {
        results {
          id
          body
          bulk_send_label
          bulksend_id
          date_created
          direction
          delivery_status
          sent_to
          conversation_id
          operator_username
          error_msg
          conversation {
            id
            archived
            customer {
              _id
              fullname
            }
            operator_user {
              display_name
              username
            }
          }
        }
        pagination {
          total
          nextPage
          page
          totalPages
        }
      }
    }
  }
`;

// ** STYLES **

const useStyles = makeStyles(theme => ({
  pageTitle: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: '30px',
    flexWrap: 'wrap',
    flexGrow: 1,
    [theme.breakpoints.down('md')]: {
      marginBottom: '10px',
    },
  },
  phoneNumber: {
    fontWeight: 400,
    color: theme.colours.greys.greytext1,
    marginLeft: '10px',
  },
  table: {
    flexGrow: 2,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

// ** COMPONENTS **
const MessageSearch = ({ gatewayId }) => {
  const classes = useStyles();
  const location = useLocation();
  // we're passing filters using useHistory and state as a param in the BulkSendCountCards
  const filtersParams = location.state ?? [];
  const [bulkSendFilters, setBulkSendFilters] = useState(
    filtersParams[0] ?? [],
  );
  const bulkSendName = filtersParams[1] ?? '';

  const [selectedGatewayId, setSelectedGatewayId] = useState(gatewayId);

  const messageFilters = selectedGatewayId
    ? [
        {
          model: 'Message',
          field: 'gateway_id',
          op: 'eq',
          value: selectedGatewayId,
        },
        ...bulkSendFilters,
      ]
    : bulkSendFilters;

  useEffect(() => {
    if (gatewayId !== selectedGatewayId) {
      setBulkSendFilters([]);
    }
  }, [gatewayId, selectedGatewayId]);

  const [searchFilters, setSearchFilters] = useState([
    {
      model: 'Message',
      field: 'date_created',
      op: '>=',
      value: moment().startOf('day').subtract(1, 'month'),
    },
  ]);

  const [sort, setSort] = useState([
    { model: 'Message', field: 'date_created', direction: 'desc' },
  ]);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('date_created');
  const handleRequestSort = (model, property) => {
    setSort({
      model,
      field: property,
      direction: order === 'asc' ? 'desc' : 'asc',
    });
    setOrder(order === 'asc' ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const [initialGateway, setInitialGateway] = useState();
  const { loading, data, error } = useQuery(GATEWAY_QUERY, {
    variables: {
      gatewayId: selectedGatewayId,
    },
    skip: !selectedGatewayId,
    onCompleted: result =>
      // Check selectedGatewayId here because for some reason it doesn't always obey the `skip` above
      selectedGatewayId !== '' &&
      setInitialGateway(prev => prev || result.sms.gateway),
  });

  const {
    loading: messagesLoading,
    data: messagesData,
    error: messagesError,
    fetchMore,
    networkStatus,
  } = useQuery(MESSAGES_QUERY, {
    variables: {
      filters: [...messageFilters, ...searchFilters],
      sort,
      pageSize: 20,
      page: 1,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  if (
    loading ||
    (messagesLoading &&
      networkStatus !== NetworkStatus.fetchMore &&
      networkStatus !== NetworkStatus.setVariables)
  ) {
    return <Loading />;
  }

  if (error || messagesError) {
    return <ErrorDisplay error={error} action="Loading Message Data" />;
  }

  const messages = messagesData?.sms?.messages?.results ?? [];

  const { total, page, totalPages } =
    messagesData?.sms?.messages?.pagination ?? {};

  const gatewayUsers = data?.sms.gatewayUsers ?? [];

  const fetchMoreHandler = () => {
    if (page < totalPages) {
      fetchMore({
        variables: {
          page: page + 1,
        },
        updateQuery: (prev, { fetchMoreResult: more }) => {
          if (!more.sms.messages.results) return prev;
          const newData = {
            sms: {
              messages: {
                results: [
                  ...prev.sms.messages.results,
                  ...more.sms.messages.results,
                ],
                pagination: more.sms.messages.pagination,
                __typename: prev.sms.messages.__typename,
              },
              __typename: prev.sms.__typename,
            },
          };
          return newData;
        },
      });
    }
  };

  const createSortHandler = (model, property) => {
    handleRequestSort(model, property);
  };

  return (
    <Box margin="40px 20px" height="95%" display="flex" flexDirection="column">
      <GatewayPicker
        selectedGatewayId={selectedGatewayId}
        setSelectedGatewayId={setSelectedGatewayId}
      />
      {/* // * PAGE TITLE * */}
      <Box className={classes.pageTitle}>
        <Typography variant="h5" style={{ fontWeight: 'bold' }}>
          {`Message Search${
            data?.sms.gateway.name ? ` - ${data?.sms.gateway.name}` : ''
          }`}
        </Typography>
        <Typography variant="body1" className={classes.phoneNumber}>
          {data?.sms.gateway.phone_number}
        </Typography>
      </Box>
      {initialGateway && (
        <Box marginLeft="auto">
          <ActionsMenu gateway={initialGateway} version="detailed" />
        </Box>
      )}
      {/* // * FILTERS  */}
      <MessageSearchFilters
        gatewayUsers={gatewayUsers}
        setSearchFilters={setSearchFilters}
        bulkSendName={bulkSendName}
        bulkSendFilters={bulkSendFilters}
        setBulkSendFilters={setBulkSendFilters}
      />
      {/* // * PAGE CONTENT */}
      <Box mr="15px" mb="10px">
        <Typography style={{ textAlign: 'right' }}>
          Showing {messages.length} of {total || '0'}
        </Typography>
      </Box>
      <TableContainer className={classes.table}>
        <LoadingBackdrop
          open={messagesLoading || networkStatus === NetworkStatus.setVariables}
        >
          Loading Messages...
        </LoadingBackdrop>

        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell />
              {HEAD_CELLS.map(headCell => (
                <TableCell
                  key={headCell.id}
                  align={headCell.numeric ? 'right' : 'left'}
                  padding={headCell.disablePadding ? 'none' : 'normal'}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={() =>
                      createSortHandler(headCell.model, headCell.id)
                    }
                    disabled={!headCell.sortable}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <span className={classes.visuallyHidden}>
                        {order === 'desc'
                          ? 'sorted descending'
                          : 'sorted ascending'}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {messages.length === 0 ? (
              <TableRow>
                <TableCell colSpan={11}>
                  There are no messages to display
                </TableCell>
              </TableRow>
            ) : (
              <MessageSearchTableRows
                messages={messages}
                gatewayId={selectedGatewayId}
              />
            )}
            {!messagesLoading && (
              <TableRow style={{ height: '1px' }}>
                <TableCell colSpan={11} style={{ borderBottom: 0 }}>
                  <Waypoint onEnter={fetchMoreHandler} />
                </TableCell>
              </TableRow>
            )}
            {networkStatus === NetworkStatus.fetchMore && (
              <TableRow>
                <TableCell colSpan={5}>
                  <Typography
                    variant="body1"
                    style={{
                      fontWeight: 'bold',
                      padding: '10px',
                    }}
                  >
                    Loading more messages...
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default MessageSearch;
