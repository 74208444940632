import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React, { createContext, useContext, useState } from 'react';

import ErrorPage from 'components/MaterialUI/ErrorPage';
import Loading from 'components/MaterialUI/Loading';
import { useGlobalPersistedState } from 'utils';
import { verifyToken } from 'modules/app/utils';

// TODO: getting currentUser.dealers is deprecated - we should use greaseDealers, but
// it may take a bit to root out all the usage of currentUser.dealers
const CURRENT_USER = gql`
  {
    currentUser {
      dealer_ids
      dealers {
        dealer_id
        dealer_name
        province_name
      }
      greaseDealers {
        id
        name
        province_name
        postal_code
        phone_number
      }
      display_name
      goUserProfile {
        settings {
          default_dealer_id
          timezone
        }
        profile {
          cell_phone
          sales_licence
          first_name
          email_address
        }
      }
      organization_id
      role
      username
    }
  }
`;

const UserContext = createContext({ currentUser: null, me: null });

const UserContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState();
  const [token, setToken] = useGlobalPersistedState('market-jwt-token', null);

  const isLoggedIn = token && verifyToken(token);

  const { loading, error } = useQuery(CURRENT_USER, {
    onCompleted: data => setCurrentUser(data?.currentUser),
    skip: !isLoggedIn,
  });

  if (loading || (isLoggedIn && !currentUser)) return <Loading />;
  if (error) return <ErrorPage action="Fetching User Profile" error={error} />;

  return (
    /* adding me: currentUser for backwards compatibility.  TODO: remove
      once all uses of this context have been converted */
    <UserContext.Provider
      value={{ currentUser, me: currentUser, token, setToken, isLoggedIn }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);

export default UserContextProvider;
