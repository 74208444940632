/* external */
import { useForm, FormProvider } from 'react-hook-form';
import { useQuery } from '@apollo/react-hooks';
import React, { useEffect, useState } from 'react';

/* Material UI */
import {
  Box,
  Container,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Tab,
  Typography,
} from '@mui/material';

import { TabContext, TabList, TabPanel } from '@mui/lab';

/* internal */
import {
  useDealerContext,
  LoadingBackdrop,
  RadioControl,
  TextFieldControl,
} from 'components/MaterialUI';

import {
  CreateMake,
  CreateModel,
  LazyEditor,
  LazySelect,
  YearSelect,
  TagsContainer,
} from '../common';
import {
  GET_TYPES_AND_MANUFACTURERS,
  GET_MAKES,
  GET_MODELS,
} from '../common/Queries';

import StepButtons from './StepButtons';
import BrochureUpload from './BrochureUpload';

const UnitDetails = ({
  back,
  step,
  isLoading,
  vehicle,
  createNpvVehicle,
  updateNpvVehicle,
}) => {
  const { dealerId } = useDealerContext();
  const [tabIndex, setTabIndex] = useState(0);

  // Form State
  const formMethods = useForm({
    defaultValues: {
      dealerId,
      description: vehicle?.description ?? '',
      modelId: vehicle?.modelId,
      // if there's no value for odometer, TextFieldControl defaults it to an empty string
      // which throws an error as it's expecting an int. Setting null circumvents this
      odometer: vehicle?.odometer ?? null,
      odometerUnits: vehicle?.odometerUnits ?? 'KM',
      privateNotes: vehicle?.privateNotes ?? '',
      stockNumber: vehicle?.stockNumber,
      stockStatusId: 1, // TODO this can be hard coded to (in stock)
      stockType: vehicle?.stockType ?? 'NEW',
      vin: vehicle?.vin,

      npvTypeId: vehicle?.model?.npvType?.id ?? '',
      npvManufacturerId: vehicle?.model?.make?.manufacturer?.id ?? '',
      npvMakeId: vehicle?.model?.make?.id ?? '',
      selectedYear: vehicle?.model?.make?.year ?? new Date().getFullYear(),
      tag1Id: vehicle?.tag1Id ?? 0,
      tag2Id: vehicle?.tag2Id ?? 0,
    },
  });
  const { setValue, watch, resetField } = formMethods;

  const npvTypeId = watch('npvTypeId');
  const npvManufacturerId = watch('npvManufacturerId');
  const npvMakeId = watch('npvMakeId');
  const selectedYear = watch('selectedYear');

  // Set Values if Vehicle Exists
  useEffect(() => {
    if (vehicle) {
      setValue('description', vehicle.description);
      setValue('modelId', vehicle.modelId);
      setValue('odometer', vehicle.odometer);
      setValue('odometerUnits', vehicle.odometerUnits);
      setValue('privateNotes', vehicle.privateNotes);
      setValue('stockNumber', vehicle.stockNumber);
      setValue('stockStatusId', vehicle.stockStatusId);
      setValue('stockType', vehicle.stockType);
      setValue('vin', vehicle.vin);
      setValue('tag1Id', vehicle.tag1Id ?? 0);
      setValue('tag2Id', vehicle.tag2Id ?? 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicle]);

  // Conditionally disallow 'null' value for odometer if odometerUnits is 'KM' or 'MI'
  // we want to allow 'null' values if the npv type does not have an odometer
  const odometerUnitValue = watch('odometerUnits');

  // Drill Down Queries
  const { data: npvInitialData, loading: npvInitialLoading } = useQuery(
    GET_TYPES_AND_MANUFACTURERS,
  );

  const {
    data: npvMakeData,
    loading: npvMakesLoading,
    refetch: refetchMakes,
  } = useQuery(GET_MAKES, {
    variables: { year: selectedYear, manufacturerId: npvManufacturerId },
    skip: !npvManufacturerId,
  });

  const {
    data: npvModelData,
    loading: npvModelsLoading,
    refetch: refetchModels,
  } = useQuery(GET_MODELS, {
    variables: { makeId: npvMakeId, npvTypeId },
    skip: !npvMakeId || !npvTypeId,
  });

  // Drill Down Data
  const npvTypes = npvInitialData?.npv?.getNpvTypes ?? [];
  const npvManufacturers = npvInitialData?.npv?.getManufacturers ?? [];
  const npvMakes = npvMakeData?.npv?.getMakes ?? [];
  const npvModels = npvModelData?.npv?.getModels ?? [];

  const selectedMake = npvMakes.find(make => make.id === npvMakeId);

  const onSubmit = data => {
    const {
      npvMakeId,
      npvManufacturerId,
      npvTypeId,
      selectedYear,
      tag1Id,
      tag2Id,
      ...rest
    } = data;

    if (vehicle) {
      updateNpvVehicle({
        variables: {
          id: vehicle.id,
          data: {
            tag1Id: tag1Id === 0 ? null : tag1Id,
            tag2Id: tag2Id === 0 ? null : tag2Id,
            ...rest,
          },
        },
      });
    } else {
      createNpvVehicle({
        variables: {
          data: {
            tag1Id: tag1Id === 0 ? null : tag1Id,
            tag2Id: tag2Id === 0 ? null : tag2Id,
            ...rest,
          },
        },
      });
    }
  };

  // Refetch if manufacturer changes
  useEffect(() => {
    if (npvManufacturerId) {
      refetchMakes();
    }
    if (npvMakeId && npvTypeId) {
      refetchModels();
    }
  }, [
    selectedYear,
    npvManufacturerId,
    npvMakeId,
    npvTypeId,
    refetchMakes,
    refetchModels,
  ]);

  // Loading
  const drillDownsLoading =
    npvInitialLoading || npvMakesLoading || npvModelsLoading;

  return (
    <FormProvider {...formMethods}>
      <LoadingBackdrop open={isLoading || drillDownsLoading} />
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Box margin={1}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <TextFieldControl
                autoFocus
                fullWidth
                label="Stock #"
                muiVariant="outlined"
                name="stockNumber"
                rules={{ required: 'Stock Number is required!' }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <RadioControl
                label="Stock Type"
                name="stockType"
                as={
                  <RadioGroup row>
                    <FormControlLabel
                      value="NEW"
                      control={<Radio color="secondary" />}
                      label="New"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="USED"
                      control={<Radio color="secondary" />}
                      label="Used"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                }
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <LazySelect
                label="Type"
                name="npvTypeId"
                options={npvTypes}
                onChange={() => resetField('npvMakeId')}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <YearSelect name="selectedYear" />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <LazySelect
                label="Manufacturer"
                name="npvManufacturerId"
                options={npvManufacturers}
                onChange={() => resetField('npvMakeId')}
                required
              />
            </Grid>

            <Grid container item xs={12} sm={6} md={4}>
              <Grid item xs={12} style={{ marginBottom: '8px' }}>
                <LazySelect
                  disabled={npvMakes.length === 0 && !npvManufacturerId}
                  label="Make"
                  name="npvMakeId"
                  options={npvMakes}
                  onChange={() => {
                    resetField('modelId');
                  }}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <CreateMake
                  manufacturerId={npvManufacturerId}
                  year={selectedYear}
                  formMethods={formMethods}
                  onCompleted={() => refetchMakes()}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={6} md={4}>
              <Grid item xs={12} style={{ marginBottom: '8px' }}>
                <LazySelect
                  disabled={!npvMakeId || !npvTypeId}
                  label="Model"
                  name="modelId"
                  options={npvModels}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <CreateModel
                  makeId={npvMakeId}
                  npvTypeId={npvTypeId}
                  formMethods={formMethods}
                  onCompleted={() => refetchModels()}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextFieldControl
                autoFocus
                fullWidth
                label="VIN"
                muiVariant="outlined"
                name="vin"
                rules={{ required: 'Vin is required!' }}
              />
            </Grid>

            {npvMakeId &&
              (selectedMake.brochureUrl ? (
                <Grid item xs={12}>
                  <a
                    href={selectedMake.brochureUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    View factory brochure
                  </a>
                  &nbsp;or upload a new one
                  <BrochureUpload makeId={npvMakeId} />
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <BrochureUpload makeId={npvMakeId} />
                </Grid>
              ))}

            <Grid item xs={12} sm={6} md={4}>
              <TextFieldControl
                autoFocus
                fullWidth
                label="Odometer"
                muiVariant="outlined"
                name="odometer"
                type="number"
                rules={{
                  validate: value =>
                    odometerUnitValue === 'NA' ||
                    parseInt(value) > 0 ||
                    'Odometer should be a positive number',
                  required:
                    odometerUnitValue !== 'NA'
                      ? 'Odometer is required!'
                      : false,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <RadioControl
                label="Odometer Units"
                name="odometerUnits"
                as={
                  <RadioGroup row>
                    <FormControlLabel
                      value="KM"
                      control={<Radio color="secondary" />}
                      label="Kilometers"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="MI"
                      control={<Radio color="secondary" />}
                      label="Miles"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="NA"
                      control={<Radio color="secondary" />}
                      label="N/A"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                }
              />
            </Grid>
          </Grid>

          <br />
          <Container maxWidth="xl">
            <TabContext value={tabIndex}>
              <TabList
                onChange={(e, value) => setTabIndex(value)}
                style={{ padding: '15px 15px 15px 0' }}
                variant="scrollable"
                indicatorColor="secondary"
              >
                <Tab label="Description" value={0} />
                <Tab label="Private Notes" value={1} />
              </TabList>
              <TabPanel value={0}>
                <LazyEditor name="description" />
              </TabPanel>
              <TabPanel value={1}>
                <LazyEditor name="privateNotes" />
              </TabPanel>
            </TabContext>
          </Container>
          <TagsContainer />
        </Box>
        <StepButtons back={back} step={step} />
      </form>
    </FormProvider>
  );
};

export default UnitDetails;
