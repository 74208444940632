import { cloneDeep } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useRef, useState } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import RAMInspCheckboxes from '../RAMInspCheckboxes';

const useStyles = makeStyles({
  legendCB: {
    margin: 0,
    '& .MuiTypography-body1': {
      fontSize: '0.875rem',
      textAlign: 'center',
      fontWeight: 'bold',
    },
    '& .MuiSvgIcon-root': {
      width: '18px',
      height: '18px',
    },
  },
  reconditioningFields: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  otherCBs: {
    textAlign: 'center',
  },
  numInput: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  adornment: {
    '& .MuiTypography-body1': {
      fontSize: '0.75rem',
    },
  },
});

const RAMInspectionForm = props => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  // Go Richmond Chrysler Inspection form fields
  // Engine
  const engine = {
    ram1: 'Oil change',
    ram2: 'Filter change',
    ram3: 'Alternator belt',
    ram4: 'Power steering belt',
    ram5: 'Air conditioning belt',
    ram6: 'Valve cover, RH/LH',
    ram7: 'Cylinder head (intake)',
    ram8: 'Manifold (exterior)',
    ram9: 'Rear seal area',
    ram10: 'Oil pan/plug',
    ram11: 'Front seal area',
    ram12: 'Oil pressure sender',
    ram13: 'PCV filter/valve/hose',
    ram14: 'Engine mounts',
    ram15: 'Engine noise',
    ram16: 'Valve train noise',
    ram17: 'Oil burning (visual)',
    ram18: 'Blow-by',
    ram19: 'Cylinder power balance',
    ram20: 'Ignition system',
  };

  // Cooling System
  const coolingSystem = {
    ram21: 'Coolant',
    ram22: 'Pressure test',
    ram23: 'Radiator',
    ram24: 'Radiator cap',
    ram25: 'Water pump',
    ram26: 'Upper hose',
    ram27: 'Lower hose',
    ram28: 'Heater hose',
    ram29: 'By-pass hose',
    ram30: 'Fan/clutch/shroud',
    ram31: 'Freeze plug',
    ram32: 'Expansion tank',
  };
  // Driveline
  const driveline = {
    ram33: 'Transmission fluid level',
    ram34: 'Clutch/linkage',
    ram35: 'Shift indicator',
    ram36: 'Shift linkage',
    ram37: 'Transmission fluid pan',
    ram38: 'Transmission front seal area',
    ram39: 'Rear extension housing',
    ram40: 'Rear extension seal',
    ram41: 'Speedometer drive',
    ram42: 'Transmission mount',
    ram43: 'Cooler lines',
    ram44: 'CV joint/boots',
    ram45: 'U-joints, front/middle/rear',
    ram46: 'Slip yoke/joint',
    ram47: 'Axle bearing',
    ram48: 'Axle seal',
    ram49: 'Differential seal/gasket',
    ram50: 'Pinion seal/gasket',
    ram51: 'Transfer case seal',
    ram52: 'Transfer case gasket',
  };
  //Fuel System
  const fuelSystem = {
    ram53: 'Evaporator canister',
    ram54: 'EGR valve',
    ram55: 'Air injector pump',
    ram56: 'Carburetor',
    ram57: 'Fuel injection',
    ram58: 'Accelerator pedal',
    ram59: 'Linkage/controls',
    ram60: 'Air cleaner/filter',
    ram61: 'Pre-heat/fresh air ducts',
    ram62: 'Fuel lines',
    ram63: 'Fuel pump/tank',
  };
  // Exhaust System
  const exhaustSystem = {
    ram64: 'Exhaust manifold',
    ram65: 'Exhaust pipes',
    ram66: 'Muffler',
    ram67: 'Resonator',
    ram68: 'Catalytic converter',
    ram69: 'Tail pipes',
    ram70: 'Hangers/clamps',
    ram71: 'Heat riser',
  };
  // Brakes
  const brakes = {
    ram72: 'Master cylinder',
    ram73: 'Power assist',
    ram74: 'Lines/hoses, front/rear',
    ram75: 'Disc/drum front',
    ram76: 'Disc/drum rear',
    ram77: 'Linings remaining, front (approx.)',
    ram78: 'Linings remaining, back (approx.)',
    ram79: 'Parking brake',
    ram80: 'Calipers/wheel cylinders, front',
    ram81: 'Calipers/wheel cylinders, rear',
    ram82: 'Antilock brakes',
    ram83: 'Fluid level/condition',
  };

  // Wheels & Tires
  const wheels = {
    ram84: 'Left front tire tread remaining (approx.)',
    ram85: 'Right front tire tread remaining (approx.)',
    ram86: 'Left rear tire tread remaining (approx.)',
    ram87: 'Right rear tire tread remaining (approx.)',
    ram88: 'Spare',
    ram89: 'Wheel rims/covers',
    ram90: 'Studs/nuts',
  };

  // Steering & Suspension
  const steering = {
    ram91: 'Alignment (visual)',
    ram92: 'Control arms/track rod',
    ram93: 'Bushings upper/lower',
    ram94: 'Ball joints lower, RH/LH',
    ram95: 'Ball joints upper, RH/LH',
    ram96: 'King pin bushings',
    ram97: 'Steering box/sector shaft',
    ram98: 'Steering column/shaft/coupling',
    ram99: 'Steering lash',
    ram100: 'Tie rod ends, outer/inner',
    ram101: 'Drag/center link',
    ram102: 'Idler arm/Pitman arm',
    ram103: 'Springs/shackles/torsion bar',
    ram104: 'Steering damper',
    ram105: 'McPherson strut, RH/LH',
    ram106: 'Shock absorbers/bushings',
    ram107: 'Strut rod/bushings, RH/LH',
    ram108: 'Stabilizer/bushings, RH/LH',
    ram109: 'Power steering pump level',
    ram110: 'Power steering hoses',
    ram111: 'Power steering operation',
    ram112: 'Rack & pinion steering',
    ram113: 'Bellows/bushing/bracket',
    ram114: 'Wheel bearing, RH/LH',
    ram115: 'Rear springs/shackles',
    ram116: 'Rear ball joint/control arm',
    ram117: 'Bushings, RH/LH',
    ram118: 'Tie rod ends, inner/outer',
    ram119: 'Stabilizer bushings, RH/LH',
    ram120: 'McPherson strut, RH/LH',
    ram121: 'Axle housing',
    ram122: 'Wheel bearing',
  };
  // Electrical System
  const electrical = {
    ram123: 'Horn',
    ram124: 'Headlights',
    ram125: 'Taillights',
    ram126: 'Brake lights',
    ram127: 'Parking lights',
    ram128: 'Marker lights',
    ram129: 'Emergency flasher',
    ram130: 'Turn signals',
    ram131: 'License plate light',
    ram132: 'Neutral switch',
    ram133: 'Wiper/washer/blade/arm',
    ram134: 'Heater/defroster/rear defogger',
    ram135: 'Starter operation',
    ram136: 'Batter/levels/hold down/terminals',
    ram137: 'Charging voltage',
    ram138: 'Air conditioning',
    ram139: 'Radio/tape/CD operation',
  };
  // Body
  const body = {
    ram140: 'Exterior body',
    ram141: 'Paint',
    ram142: 'Underbody (visual only)',
    ram143: 'Frame (visual only)',
    ram144: 'Truck/tailgate',
    ram145: 'Mirrors, RH/LH/inside',
    ram146: 'Bumpers, front/rear',
    ram147: 'Windshield/windows, front/rear',
    ram148: 'Window winders',
    ram149: 'Hood latch',
    ram150: 'Seats',
    ram151: 'Seat belts',
    ram152: 'Trunk remote latch',
    ram153: 'Fuel door remote latch',
  };
  // Misc
  const misc = {
    ram154: '',
    ram155: '',
    ram156: '',
  };

  // Reconditioning
  const items = {
    item_1: '',
    item_2: '',
    item_3: '',
    item_4: '',
    item_5: '',
    item_6: '',
  };

  const ros = {
    ro_1: '',
    ro_2: '',
    ro_3: '',
    ro_4: '',
    ro_5: '',
    ro_6: '',
  };

  let engineObj = cloneDeep(engine);
  let coolingSystemObj = cloneDeep(coolingSystem);
  let drivelineObj = cloneDeep(driveline);
  let fuelSystemObj = cloneDeep(fuelSystem);
  let exhaustSystemObj = cloneDeep(exhaustSystem);
  let brakesObj = cloneDeep(brakes);
  let wheelsObj = cloneDeep(wheels);
  let steeringObj = cloneDeep(steering);
  let electricalObj = cloneDeep(electrical);
  let bodyObj = cloneDeep(body);
  let miscObj = cloneDeep(misc);
  let itemsObj = cloneDeep(items);
  let rosObj = cloneDeep(ros);

  Object.entries(engineObj).map(([name]) => (engineObj[name] = 'OK'));
  Object.entries(coolingSystemObj).map(
    ([name]) => (coolingSystemObj[name] = 'OK'),
  );
  Object.entries(drivelineObj).map(([name]) => (drivelineObj[name] = 'OK'));
  Object.entries(fuelSystemObj).map(([name]) => (fuelSystemObj[name] = 'OK'));
  Object.entries(exhaustSystemObj).map(
    ([name]) => (exhaustSystemObj[name] = 'OK'),
  );
  Object.entries(brakesObj).map(([name]) => (brakesObj[name] = 'OK'));
  Object.entries(wheelsObj).map(([name]) => (wheelsObj[name] = 'OK'));
  Object.entries(steeringObj).map(([name]) => (steeringObj[name] = 'OK'));
  Object.entries(electricalObj).map(([name]) => (electricalObj[name] = 'OK'));
  Object.entries(bodyObj).map(([name]) => (bodyObj[name] = 'OK'));
  Object.entries(miscObj).map(([name]) => (miscObj[name] = 'OK'));
  Object.entries(itemsObj).map(([name]) => (itemsObj[name] = ''));
  Object.entries(rosObj).map(([name]) => (rosObj[name] = 'OK'));

  const [engineValues, setEngineValues] = useState(engineObj);
  const [coolingSystemValues, setCoolingSystemValues] =
    useState(coolingSystemObj);
  const [drivelineValues, setDrivelineValues] = useState(drivelineObj);
  const [fuelSystemValues, setFuelSystemValues] = useState(fuelSystemObj);
  const [exhaustSystemValues, setExhaustSystemValues] =
    useState(exhaustSystemObj);
  const [brakesValues, setBrakesValues] = useState(brakesObj);
  const [wheelsValues, setWheelsValues] = useState(wheelsObj);
  const [steeringValues, setSteeringValues] = useState(steeringObj);
  const [electricalValues, setElectricalValues] = useState(electricalObj);
  const [bodyValues, setBodyValues] = useState(bodyObj);
  const [miscValues, setMiscValues] = useState(miscObj);

  let itemsValues = itemsObj;
  let rosValues = rosObj;

  const handleEngine = e => {
    const { name, value, checked } = e.target;
    setEngineValues(prevEngineValues => ({
      ...prevEngineValues,
      [name]: checked ? value : '',
    }));
  };

  const handleCoolingSystem = e => {
    const { name, value, checked } = e.target;
    setCoolingSystemValues(prevCoolingSystemValues => ({
      ...prevCoolingSystemValues,
      [name]: checked ? value : '',
    }));
  };

  const handleDriveline = e => {
    const { name, value, checked } = e.target;
    setDrivelineValues(prevDrivelineValues => ({
      ...prevDrivelineValues,
      [name]: checked ? value : '',
    }));
  };

  const handleFuelSystem = e => {
    const { name, value, checked } = e.target;
    setFuelSystemValues(prevFuelSystemValues => ({
      ...prevFuelSystemValues,
      [name]: checked ? value : '',
    }));
  };

  const handleExhaustSystem = e => {
    const { name, value, checked } = e.target;
    setExhaustSystemValues(prevExhaustSystemValues => ({
      ...prevExhaustSystemValues,
      [name]: checked ? value : '',
    }));
  };

  const handleBrakes = e => {
    const { name, value, checked } = e.target;
    setBrakesValues(prevBrakesValues => ({
      ...prevBrakesValues,
      [name]: checked ? value : '',
    }));
  };

  const handleWheels = e => {
    const { name, value, checked } = e.target;
    setWheelsValues(prevWheelsValues => ({
      ...prevWheelsValues,
      [name]: checked ? value : '',
    }));
  };

  const handleSteering = e => {
    const { name, value, checked } = e.target;
    setSteeringValues(prevSteeringValues => ({
      ...prevSteeringValues,
      [name]: checked ? value : '',
    }));
  };

  const handleElectrical = e => {
    const { name, value, checked } = e.target;
    setElectricalValues(prevElectricalValues => ({
      ...prevElectricalValues,
      [name]: checked ? value : '',
    }));
  };

  const handleBody = e => {
    const { name, value, checked } = e.target;
    setBodyValues(prevBodyValues => ({
      ...prevBodyValues,
      [name]: checked ? value : '',
    }));
  };

  const handleMisc = e => {
    const { name, value, checked } = e.target;
    setMiscValues(prevMiscValues => ({
      ...prevMiscValues,
      [name]: checked ? value : '',
    }));
  };

  const [liensCleared, setLiensCleared] = useState(false);
  const handleLiens = () => {
    setLiensCleared(liensCleared => !liensCleared);
  };

  const [icbcCheck, setICBCCheck] = useState(false);
  const handleICBC = () => {
    setICBCCheck(icbcCheck => !icbcCheck);
  };

  const [oopCheck, setOOPCheck] = useState(false);
  const handleOOP = () => {
    setOOPCheck(oopCheck => !oopCheck);
  };

  const [consent, setConsent] = useState(false);
  const handleConsent = () => {
    setConsent(consent => !consent);
  };

  const [confirm, setConfirm] = useState(false);
  const handleConfirm = () => {
    setConfirm(confirm => !confirm);
  };

  const wholeForm = useRef(null);

  const handleSubmit = e => {
    e.preventDefault();

    if (!consent) {
      enqueueSnackbar(`Please check 'I agree' and then submit the inspection!`);
      return;
    }

    Object.entries(miscObj).map(
      ([name]) =>
        (miscValues[name + '_misc'] = wholeForm.current[name + '_misc'].value),
    );

    Object.entries(itemsObj).map(
      ([name]) => (itemsValues[name] = wholeForm.current[name].value),
    );

    Object.entries(rosObj).map(
      ([name]) => (rosValues[name] = wholeForm.current[name].value),
    );

    const values = {
      ...engineValues,
      ...coolingSystemValues,
      ...drivelineValues,
      ...fuelSystemValues,
      ...exhaustSystemValues,
      ...brakesValues,
      ...wheelsValues,
      ...steeringValues,
      ...electricalValues,
      ...bodyValues,
      ...miscValues,
      ...itemsValues,
      ...rosValues,
    };

    const form_data = {
      ram: values,
      amvic_number: wholeForm.current['amvic_number'].value,
      front_linings: wholeForm.current['front_linings'].value,
      back_linings: wholeForm.current['back_linings'].value,
      lf_tread: wholeForm.current['lf_tread'].value,
      rf_tread: wholeForm.current['rf_tread'].value,
      lr_tread: wholeForm.current['lr_tread'].value,
      rr_tread: wholeForm.current['rr_tread'].value,
      liens_cleared: wholeForm.current['liens_cleared'].value,
      icbc_check: wholeForm.current['icbc_check'].value,
      oop_check: wholeForm.current['oop_check'].value,
    };
    props.formData(form_data);
    props.formClose();
  };

  const GreenButton = withStyles({
    root: {
      color: '#fff',
      backgroundColor: '#198754',
      '&:hover': {
        backgroundColor: '#157347',
      },
    },
  })(Button);

  const wheelsTextFields = ['ram84', 'ram85', 'ram86', 'ram87'];

  const CBLegend = () => {
    return (
      <Grid
        container
        item
        spacing={1}
        xs={12}
        justifyContent="space-between"
        style={{ marginBottom: '10px' }}
      >
        <Grid item xs={6} sm={3} className={classes.otherCBs}>
          <FormControlLabel
            control={<Checkbox checked={true} style={{ color: '#43a047' }} />}
            label="Satisfactory"
            labelPlacement="bottom"
            className={classes.legendCB}
          />
        </Grid>
        <Grid item xs={6} sm={3} className={classes.otherCBs}>
          <FormControlLabel
            control={<Checkbox checked={true} style={{ color: '#2196F3' }} />}
            label="Normal Wear"
            labelPlacement="bottom"
            className={classes.legendCB}
          />
        </Grid>
        <Grid item xs={6} sm={3} className={classes.otherCBs}>
          <FormControlLabel
            control={<Checkbox checked={true} style={{ color: '#FF8C00' }} />}
            label="Adjustment/Service"
            labelPlacement="bottom"
            className={classes.legendCB}
          />
        </Grid>
        <Grid item xs={6} sm={3} className={classes.otherCBs}>
          <FormControlLabel
            control={<Checkbox checked={true} style={{ color: '#FF0000' }} />}
            label="Needs Repair"
            labelPlacement="bottom"
            className={classes.legendCB}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <form onSubmit={handleSubmit} ref={wholeForm}>
      {/* ENGINE */}
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>ENGINE</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center" spacing={1}>
            <CBLegend />
            {Object.entries(engine).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={4}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={8}>
                    <RAMInspCheckboxes
                      valueGroup={engineValues}
                      handleGroup={handleEngine}
                      name={name}
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      {/* COOLING SYSTEM */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>COOLING SYSTEM</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center" spacing={1}>
            <CBLegend />
            {Object.entries(coolingSystem).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={4}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={8}>
                    <RAMInspCheckboxes
                      valueGroup={coolingSystemValues}
                      handleGroup={handleCoolingSystem}
                      name={name}
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      {/* DRIVELINE */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>DRIVELINE</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center" spacing={1}>
            <CBLegend />
            {Object.entries(driveline).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={4}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={8}>
                    <RAMInspCheckboxes
                      valueGroup={drivelineValues}
                      handleGroup={handleDriveline}
                      name={name}
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      {/* FUEL SYSTEM */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>FUEL SYSTEM</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center" spacing={1}>
            <CBLegend />
            {Object.entries(fuelSystem).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={4}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={8}>
                    <RAMInspCheckboxes
                      valueGroup={fuelSystemValues}
                      handleGroup={handleFuelSystem}
                      name={name}
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      {/* EXHAUST SYSTEM */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>EXHAUST SYSTEM</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center" spacing={1}>
            <CBLegend />
            {Object.entries(exhaustSystem).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={4}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={8}>
                    <RAMInspCheckboxes
                      valueGroup={exhaustSystemValues}
                      handleGroup={handleExhaustSystem}
                      name={name}
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      {/* BRAKES */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>BRAKES</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center" spacing={1}>
            <CBLegend />
            {Object.entries(brakes).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={name === 'ram77' || name === 'ram78' ? 2 : 4}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  {(name === 'ram77' || name === 'ram78') && (
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        type="number"
                        name={
                          name === 'ram77' ? 'front_linings' : 'back_linings'
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              className={classes.adornment}
                            >
                              mm
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{ min: 0 }}
                        className={classes.numInput}
                        style={{ maxWidth: '80px' }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={8}>
                    <RAMInspCheckboxes
                      valueGroup={brakesValues}
                      handleGroup={handleBrakes}
                      name={name}
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      {/* WHEELS & TIRES */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>WHEELS & TIRES</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center" spacing={1}>
            <CBLegend />
            {Object.entries(wheels).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={wheelsTextFields.includes(name) ? 3 : 5}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  {wheelsTextFields.includes(name) && (
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        name={
                          name === 'ram84'
                            ? 'lf_tread'
                            : name === 'ram85'
                            ? 'rf_tread'
                            : name === 'ram86'
                            ? 'lr_tread'
                            : 'rr_tread'
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              className={classes.adornment}
                            >
                              /32"
                            </InputAdornment>
                          ),
                        }}
                        type="number"
                        inputProps={{ min: 0 }}
                        className={classes.numInput}
                        style={{ minWidth: '80px' }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={7}>
                    <RAMInspCheckboxes
                      valueGroup={wheelsValues}
                      handleGroup={handleWheels}
                      name={name}
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      {/* STEERING & SUSPENSION */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>STEERING & SUSPENSION</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center" spacing={1}>
            <CBLegend />
            {Object.entries(steering).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={4}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={8}>
                    <RAMInspCheckboxes
                      valueGroup={steeringValues}
                      handleGroup={handleSteering}
                      name={name}
                    />
                  </Grid>
                  {name === 'ram114' && (
                    <Grid item xs={12}>
                      <Typography>
                        <b>REAR</b>
                      </Typography>
                    </Grid>
                  )}
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      {/* ELECTRICAL */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>ELECTRICAL</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center" spacing={1}>
            <CBLegend />
            {Object.entries(electrical).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={4}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={8}>
                    <RAMInspCheckboxes
                      valueGroup={electricalValues}
                      handleGroup={handleElectrical}
                      name={name}
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      {/* BODY */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>BODY</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center" spacing={1}>
            <CBLegend />
            {Object.entries(body).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={4}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={8}>
                    <RAMInspCheckboxes
                      valueGroup={bodyValues}
                      handleGroup={handleBody}
                      name={name}
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      {/* MISC */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>MISCELLANEOUS</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center" spacing={1}>
            <CBLegend />
            {Object.entries(misc).map(([name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={4}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    <TextField label="Misc." name={name + '_misc'} />
                  </Grid>
                  <Grid item xs={8}>
                    <RAMInspCheckboxes
                      valueGroup={miscValues}
                      handleGroup={handleMisc}
                      name={name}
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      {/* RECONDITIONING */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>RECONDITIONING</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center" spacing={1}>
            {Object.entries(items).map((name, index) => {
              return (
                <React.Fragment key={name[0]}>
                  <Grid
                    item
                    xs={6}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    <Box className={classes.reconditioningFields}>
                      <Typography>{`${index + 1})`}</Typography>
                      <TextField
                        fullWidth
                        label="Item #"
                        name={name[0]}
                        style={{ width: '45%' }}
                      />
                      {ros.hasOwnProperty(`ro_${index + 1}`) && (
                        <TextField
                          fullWidth
                          label="RO #"
                          name={`ro_${index + 1}`}
                          style={{ width: '45%' }}
                        />
                      )}
                    </Box>
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>OTHER</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center" spacing={1}>
            <Grid
              item
              xs={4}
              className={classes.otherCBs}
              style={{ paddingRight: '1.5rem' }}
            >
              <Typography>
                <b>Liens Cleared?</b>
              </Typography>
              <FormControlLabel
                control={<Checkbox />}
                label="Yes"
                name={'liens_cleared'}
                value={liensCleared}
                onChange={handleLiens}
              />
            </Grid>
            <Grid
              item
              xs={4}
              className={classes.otherCBs}
              style={{ paddingRight: '1.5rem' }}
            >
              <Typography>
                <b>I.C.B.C Damage Check?</b>
              </Typography>
              <FormControlLabel
                control={<Checkbox />}
                label="Yes"
                name={'icbc_check'}
                value={icbcCheck}
                onChange={handleICBC}
              />
            </Grid>
            <Grid
              item
              xs={4}
              className={classes.otherCBs}
              style={{ paddingRight: '1.5rem' }}
            >
              <Typography>
                <b>Out-Of-Province Check?</b>
              </Typography>
              <FormControlLabel
                control={<Checkbox />}
                label="Yes"
                name={'oop_check'}
                value={oopCheck}
                onChange={handleOOP}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      {/* SIGN OFF */}
      <Grid
        container
        spacing={1}
        alignItems="center"
        style={{ margin: '10px' }}
      >
        <Grid item xs={12} sm={9}>
          <Typography style={{ fontWeight: 'bold' }}>
            I verify the contents of this document are true and correct
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControlLabel
            control={<Checkbox />}
            label="I agree"
            onChange={handleConsent}
            value={consent}
          />
          <br />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Technician Name"
            value={props.user.display_name}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Tech #"
            value={props.user.goUserProfile.profile.sales_licence}
            fullWidth
            name={'amvic_number'}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Date"
            value={new Date().toLocaleString()}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button
            type="button"
            variant="contained"
            style={{ marginRight: '15px' }}
            onClick={handleConfirm}
            color="default"
          >
            Cancel
          </Button>
          <Dialog
            open={confirm}
            onClose={handleConfirm}
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Current inspection will be closed and unsaved data will be gone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleConfirm}>Cancel</Button>
              <Button onClick={props.formClose} color="secondary">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          <GreenButton type="submit" variant="contained">
            Save
          </GreenButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default RAMInspectionForm;
