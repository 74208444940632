import gql from 'graphql-tag';
import React from 'react';

import { useMediaQuery } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import List from '@mui/material/List';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import VehicleListItem from './VehicleListItem';
import VehicleDetailsModal from './VehicleModal';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '50px',
    height: '100%',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  blackDivider: {
    backgroundColor: 'black',
    margin: theme.spacing(1, 0, 1, 0),
  },
}));

const VehicleHeader = ({ customer, refetchCustomer }) => {
  const classes = useStyles();
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Typography className={classes.title} gutterBottom>
            Vehicles ({customer.past_vehicles.length + customer.vehicles.length}
            )
          </Typography>
        </Grid>
        <Grid item>
          <VehicleDetailsModal
            customer={customer}
            defaultValues={null}
            refetchCustomer={refetchCustomer}
          />
        </Grid>
      </Grid>
    </>
  );
};

const VehicleList = ({ customer, refetchCustomer }) => {
  const classes = useStyles();
  const desktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  return (
    <List
      style={{
        width: '100%',
        position: 'relative',
        overflow: 'auto',
        paddingTop: desktop ? 10 : 0,
        maxHeight: 800,
      }}
    >
      {customer.vehicles.length > 0 && (
        <>
          <InputLabel>Current</InputLabel>
          {customer.vehicles.map(vehicle => (
            <VehicleListItem
              vehicle={vehicle}
              key={vehicle._id}
              customer={customer}
              vehicleStatus="current"
              refetchCustomer={refetchCustomer}
            />
          ))}
        </>
      )}
      {customer.past_vehicles.length > 0 && customer.vehicles.length > 0 && (
        <Divider className={classes.blackDivider} />
      )}
      {customer.past_vehicles.length > 0 && (
        <>
          <InputLabel>Previous</InputLabel>
          {customer.past_vehicles.map(vehicle => (
            <VehicleListItem
              vehicle={vehicle}
              key={vehicle._id}
              customer={customer}
              vehicleStatus="previous"
              refetchCustomer={refetchCustomer}
            />
          ))}
        </>
      )}
      {customer.vehicles.length === 0 &&
        customer.past_vehicles.length === 0 &&
        'There are no vehicles for this customer.'}
    </List>
  );
};

const VehiclesCard = ({ customer, refetchCustomer }) => {
  const classes = useStyles();
  const desktop = useMediaQuery(theme => theme.breakpoints.up('sm'));

  return (
    <>
      {desktop ? (
        <Card className={classes.root}>
          <CardContent>
            <VehicleHeader
              customer={customer}
              refetchCustomer={refetchCustomer}
            />
            <VehicleList
              customer={customer}
              refetchCustomer={refetchCustomer}
            />
          </CardContent>
        </Card>
      ) : (
        <Accordion defaultExpanded={true}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <VehicleHeader
              customer={customer}
              refetchCustomer={refetchCustomer}
            />
          </AccordionSummary>
          <AccordionDetails style={{ paddingTop: 0 }}>
            <VehicleList
              customer={customer}
              refetchCustomer={refetchCustomer}
            />
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};

VehiclesCard.fragments = {
  customer: gql`
    fragment VehiclesCardFragment on Customer {
      vehicles {
        _id
      }
      past_vehicles {
        _id
      }
      ...VehicleDetailsModalFragment
      ...VehicleListItemFragment
    }
    ${VehicleDetailsModal.fragments.customer}
    ${VehicleListItem.fragments.customer}
  `,
};

export default VehiclesCard;
