import React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const LeadInterestedVehicleCard = ({ lead, classes }) => {
  const formatKms = odometer => {
    if (odometer >= 2000) {
      odometer = Math.floor(odometer / 1000) + ' kms';
    } else if (odometer >= 1000) {
      odometer = Math.floor(odometer / 1000) + ' km';
    }
    return odometer;
  };

  return (
    <Card style={{ height: '100%' }}>
      <CardContent>
        <Typography style={{ padding: '0 0 .5rem 1rem', fontWeight: '700' }}>
          Interested Vehicle Details
        </Typography>
        <TableContainer>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <colgroup>
              <col style={{ width: '50%' }} />
              <col style={{ width: '50%' }} />
            </colgroup>
            <TableBody>
              <TableRow>
                <TableCell>Vehicle</TableCell>
                <TableCell>
                  <b>
                    {lead.form_data?.interested_vehicle_year ||
                    lead.form_data?.interested_vehicle_make ||
                    lead.form_data?.interested_vehicle_model
                      ? lead.form_data?.interested_vehicle_year +
                        ' ' +
                        lead.form_data?.interested_vehicle_make +
                        ' ' +
                        lead.form_data?.interested_vehicle_model
                      : '-'}
                  </b>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Vehicle Trim</TableCell>
                <TableCell>
                  <b>{lead.form_data?.interested_vehicle_trim || '-'}</b>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Stock Type</TableCell>
                <TableCell>
                  <b>
                    {lead.form_data?.interested_vehicle_vehicle_type || '-'}
                  </b>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Stock #</TableCell>
                <TableCell>
                  <b>{lead.form_data?.interested_vehicle_stock || '-'}</b>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Price</TableCell>
                <TableCell>
                  <b>{lead.form_data?.interested_vehicle_price || '-'}</b>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>VIN</TableCell>
                <TableCell>
                  <b>{lead.form_data?.interested_vehicle_vin || '-'}</b>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Odometer</TableCell>
                <TableCell>
                  <b>
                    {lead.form_data?.interested_vehicle_odometer
                      ? formatKms(lead.form_data?.interested_vehicle_odometer)
                      : '-'}
                  </b>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Body Type</TableCell>
                <TableCell>
                  <b>{lead.form_data?.interested_vehicle_body_type || '-'}</b>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default LeadInterestedVehicleCard;
