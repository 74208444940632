import React, { useState } from 'react';

import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

import {
  isSelectionSortingBy,
  makeClickSortHandler,
} from 'modules/npv_inventory/utils';

import {
  COST_SORT,
  PHOTO_COUNT_SORT,
  PRICE_SORT,
  STOCK_NUMBER_SORT,
  SPECIAL_PRICE_SORT,
  DAYS_SORT,
  UPDATED_SORT,
  YEAR_SORT,
  MAKE_SORT,
  MODEL_SORT,
  MANUFACTURER_SORT,
  LEADS_SORT,
  OPPS_SORT,
} from 'modules/npv_inventory/const';
import SortPopover from 'modules/npv_inventory/components/inventory/SortPopover';

const VEHICLE_SORT_OPTIONS = [
  { ...YEAR_SORT, direction: 'asc' },
  { ...YEAR_SORT, direction: 'desc' },
  { ...MAKE_SORT, direction: 'asc' },
  { ...MAKE_SORT, direction: 'desc' },
  { ...MODEL_SORT, direction: 'asc' },
  { ...MODEL_SORT, direction: 'desc' },
  { ...MANUFACTURER_SORT, direction: 'asc' },
  { ...MANUFACTURER_SORT, direction: 'desc' },
];

const InventoryTableHeader = ({
  onSelectAllClick,
  sortSelection,
  setSortSelection,
  numSelected,
  rowCount,
}) => {
  const [popoverAnchor, setPopoverAnchor] = useState();

  const isSortingBy = isSelectionSortingBy(sortSelection);
  const clickSortHandler = makeClickSortHandler(setSortSelection);

  const sortProps = sort => ({
    active: isSortingBy(sort),
    direction: sortSelection?.direction || 'asc',
    onClick: clickSortHandler(sort),
  });
  return (
    <TableHead>
      <SortPopover
        anchorEl={popoverAnchor}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={() => setPopoverAnchor(null)}
        open={Boolean(popoverAnchor)}
        setSortSelection={setSortSelection}
        sortOptions={VEHICLE_SORT_OPTIONS}
        sortSelection={sortSelection}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'Select All Vehicles' }}
            color="secondary"
          />
        </TableCell>
        <TableCell>Actions</TableCell>
        <TableCell>
          <TableSortLabel {...sortProps(STOCK_NUMBER_SORT)}>
            Stock&nbsp;#
          </TableSortLabel>
        </TableCell>
        <TableCell>
          <TableSortLabel
            onClick={e => setPopoverAnchor(e.currentTarget)}
            direction={sortSelection?.direction || 'asc'}
            active={VEHICLE_SORT_OPTIONS.some(isSortingBy)}
          >
            Vehicle
          </TableSortLabel>
        </TableCell>
        <TableCell>
          <TableSortLabel {...sortProps(COST_SORT)}>Cost</TableSortLabel>
        </TableCell>
        <TableCell>
          <TableSortLabel {...sortProps(PRICE_SORT)}>Price</TableSortLabel>
        </TableCell>
        <TableCell>
          <TableSortLabel {...sortProps(SPECIAL_PRICE_SORT)}>
            Special Price
          </TableSortLabel>
        </TableCell>
        <TableCell>
          <TableSortLabel {...sortProps(DAYS_SORT)}>Days</TableSortLabel>
        </TableCell>
        <TableCell>
          <TableSortLabel {...sortProps(LEADS_SORT)}>
            Leads Created
          </TableSortLabel>
        </TableCell>
        <TableCell>
          <TableSortLabel {...sortProps(OPPS_SORT)}>
            Opps Created
          </TableSortLabel>
        </TableCell>
        <TableCell>
          <TableSortLabel {...sortProps(PHOTO_COUNT_SORT)}>
            Photos
          </TableSortLabel>
        </TableCell>
        <TableCell>
          <TableSortLabel {...sortProps(UPDATED_SORT)}>Updated</TableSortLabel>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default InventoryTableHeader;
