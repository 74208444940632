import React, { useState } from 'react';

import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import { useTheme } from '@mui/material';
import { useMutation } from '@apollo/react-hooks';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

import { useDealerContext } from 'components/MaterialUI/DealerContext';
import TextFieldControl from 'components/MaterialUI/TextFieldControl';

const ADD_NOTE = gql`
  mutation addNote($input: NoteInput) {
    createNote(input: $input) {
      _id
    }
  }
`;

const UPDATE_TASK = gql`
  mutation UpdateTask($id: ID!, $input: UpdateTaskInput!) {
    updateTask(id: $id, input: $input) {
      _id
      due_date
      status
    }
  }
`;

const TasksActionModal = ({
  customer,
  handleAddTask,
  handleClose,
  refetch,
  taskId,
  status,
}) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { dealerId } = useDealerContext();
  const { control, handleSubmit } = useForm({});

  const [addNew, setAddNew] = useState(false);

  const [addNote] = useMutation(ADD_NOTE);

  const [updateTask] = useMutation(UPDATE_TASK, {
    onCompleted: () => {
      handleClose();
      refetch();
      enqueueSnackbar('Task updated', { variant: 'success' });
      if (addNew) {
        handleAddTask();
      }
    },
    onError: err => {
      enqueueSnackbar(`Could not update task error: ${err}`, {
        variant: 'error',
      });
    },
  });

  const onSubmit = data => {
    updateTask({
      variables: {
        id: taskId,
        input: {
          status: status,
        },
      },
    }).then(() => {
      const noteData = {
        body: data.note,
        visibility: 'store_only',
        customer_id: customer._id,
        dealer_id: dealerId,
      };
      if (noteData.body) {
        addNote({
          variables: {
            input: noteData,
          },
        });
      }
    });
  };

  const handleCompleteAddNew = () => {
    setAddNew(true);
    handleSubmit(onSubmit)();
  };

  return (
    <>
      <DialogTitle>{status === 2 ? 'Cancel' : 'Complete'} Task</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={3}
            style={{ marginBottom: '1rem' }}
          >
            <Grid item>
              <TextFieldControl
                label="Notes"
                fullWidth
                name="note"
                control={control}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            style={theme.actions.confirm}
            variant="contained"
            color="primary"
            type="submit"
          >
            {status === 2 ? 'Cancel task' : 'Complete'}
          </Button>
          {status === 1 && (
            <Button
              style={theme.actions.confirm}
              variant="contained"
              color="primary"
              onClick={() => handleCompleteAddNew()}
            >
              Complete and add new
            </Button>
          )}
          <Button onClick={handleClose} color="primary" variant="outlined">
            Close
          </Button>
        </DialogActions>
      </form>
    </>
  );
};

TasksActionModal.fragments = {
  customer: gql`
    fragment TasksActionModalFragment on Customer {
      _id
    }
  `,
};

export default TasksActionModal;
