import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { ApolloProvider as ApolloProviderNew } from '@apollo/client';
import { ApolloProvider } from '@apollo/react-hooks'; /* deprecated - import from @apollo/client instead */
import { ApolloClient } from 'apollo-boost'; /* deprecated - import from @apollo/client instead */
import { defaultDataIdFromObject, InMemoryCache } from 'apollo-cache-inmemory';
import { split } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';
import { getMainDefinition } from 'apollo-utilities';
import { createClient } from 'graphql-ws';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { URL as appURL, isProduction, SENTRY_DSN } from './constants';
import {
  //register,
  unregister,
} from './registerServiceWorker';
import { getUrlParameter } from 'utils';
import { verifyToken } from 'modules/app/utils';
import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';

import { createRoot } from 'react-dom/client';

const supportsHistory = 'pushState' in window.history;

const jwt = getUrlParameter('jwt');
if (verifyToken(jwt)) {
  localStorage.setItem('market-jwt-token', JSON.stringify(jwt));
}

const history = createBrowserHistory();

if (isProduction) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    tracesSampleRate: 0.1,
    tracePropagationTargets: ['localhost', 'https://market.goauto.io/'],
  });
}

// Apollo Client
const httpLink = createHttpLink({
  uri: appURL.marketGraph,
});

// Websockets
const webSocketLink = new GraphQLWsLink(
  createClient({
    url: appURL.marketGraphWS,
    connectionParams: () => ({
      authorization: `Bearer ${JSON.parse(
        localStorage.getItem('market-jwt-token'),
      )}`,
    }),
  }),
);

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local state
  const token = JSON.parse(localStorage.getItem('market-jwt-token'));
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : null,
    },
  };
});

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  webSocketLink,
  authLink.concat(httpLink),
);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    // TODO: There's a better way of doing this in Apollo Client 3.0
    // https://www.apollographql.com/docs/react/migrating/apollo-client-3-migration/
    dataIdFromObject: object => {
      switch (object.__typename) {
        case 'Call':
          return `${object.__typename}:${object.sid}`;
        case 'GoCardAccountV2':
          return `${object.__typename}:${object.cardId}`;
        case 'GoCardTransactionV2':
          return `${object.__typename}:${object.transactionId}`;
        case 'GoCardLocation':
          return `${object.__typename}:${object.locationId}`;
        case 'VautoAppraisal':
          return `${object.__typename}:${object.vautoId}`;
        case 'DeskingSettings':
          return `${object.__typename}:${object.dealer_id}`;
        case 'OrganizationDeskingSettings':
          return `${object.__typename}:${object.organization_id}`;
        case 'ListingType':
          return `${object.__typename}:${object.listing_id}`;
        case 'GlobalSuppression':
          return `${object.__typename}:${object.number}`;
        case 'AuctionVehicleCarfax':
          return `${object.__typename}:${object.vehicleId}`;
        default:
          return defaultDataIdFromObject(object);
      }
    },
  }),
});

/* eslint-disable react/jsx-filename-extension */
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <ApolloProvider client={client}>
    {/* Added so that we can import things from @apollo/client and NOT get an invariant violation.
    TODO: remove old provider and rename this, then change ALL imports to import from @apollo/client
    https://stackoverflow.com/questions/58771868/invariant-violation-when-using-react-apollo-hooks-alongside-query-components
     */}
    <ApolloProviderNew client={client}>
      <Router history={history} forceRefresh={!supportsHistory}>
        <App />
      </Router>
    </ApolloProviderNew>
  </ApolloProvider>,
);
// register(); // enables service worker
unregister(); // disables service worker
