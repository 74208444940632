import React, { useState } from 'react';

/* external */
import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { dateToFormatString } from 'utils';

/* Material UI */
import { useMediaQuery } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { DateTimePicker } from '@mui/x-date-pickers';

/* internal */
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import { Role } from 'constants.js';
import AddTaskModal from './AddTaskModal';
import TasksAction from './TasksAction';
import TasksActionModal from './TasksActionModal';
import TasksBulkAction from './TasksBulkAction';

const USERS_FOR_DEALER = gql`
  query UsersForDealer($dealerIds: [Int]!) {
    users: users(
      dealer_ids: $dealerIds
      status: active
      roles: [
        "${Role.SALES_REP}"
        "${Role.SALES_MANAGER}"
        "${Role.FINANCE_MANAGER}"
        "${Role.INTERNET_SALES_REP}"
      ]
    ) {
      display_name
      username
    }
  }
`;

const REASSIGN_TASK = gql`
  mutation ReassignTask($id: ID!, $input: ReassignTaskInput!) {
    reassignTask(id: $id, input: $input) {
      _id
      assignee_username
      assignee {
        username
        display_name
      }
    }
  }
`;

const UPDATE_TASK = gql`
  mutation UpdateTask($id: ID!, $input: UpdateTaskInput!) {
    updateTask(id: $id, input: $input) {
      _id
      due_date
      status
    }
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '300px',
    paddingBottom: 0,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  tableRow: {
    paddingBottom: 0,
    paddingTop: 0,
    border: 'none',
    fontWeight: 'bold',
  },
  tableCell: {
    paddingBottom: 0,
    paddingTop: 0,
    border: 'none',
  },
  tableValue: {
    paddingBottom: 0,
    paddingTop: 0,
    border: 'none',
  },
}));

// TODO: move to const.js
const TaskStatus = {
  TODO: 0,
  COMPLETE: 1, // Done
  CANCELLED: 2,
};

const TaskStatusColor = {
  RED: '#D15B47',
  YELLOW: '#FFEB3B',
  GREEN: '#82AF6F',
  GREY: '#666',
  BLUE: '#0044cc',
};
////////

const formatDate = d => dateToFormatString(d, 'MMM DD, YYYY[\n]h:mm a');

// TODO: eliminate refetch if possible
const Tasks = ({ customer, refetch, isHidden }) => {
  const desktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const { dealerId } = useDealerContext();

  const [reassignTaskModalOpen, setReassignTaskModalOpen] = useState(false);
  const [rescheduleTaskModalOpen, setRescheduleTaskModalOpen] = useState(false);
  const [taskId, setTaskId] = useState(null);
  const [username, setUsername] = useState('');
  const [addTaskModal, setAddTaskModal] = useState(false);
  const [tasksActionModal, setTasksActionModal] = useState(false);
  const [status, setStatus] = useState(null);

  const [selected, setSelected] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleAnchorClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleAnchorClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (e, _id) => {
    if (e.target.checked)
      setSelected(prev =>
        customer.tasks.map(v => v._id).filter(x => [...prev, _id].includes(x)),
      );
    else setSelected(prev => prev.filter(x => x !== _id));
  };

  const handleSelectAllClick = e => {
    setSelected(e.target.checked ? customer.tasks.map(n => n._id) : []);
  };

  const handleUpdateStatus = (id, status) => {
    setTaskId(id);
    setStatus(status);
    setTasksActionModal(true);
  };

  const [reassignTask] = useMutation(REASSIGN_TASK, {
    onCompleted: () => {
      refetch();
      enqueueSnackbar('Task updated', { variant: 'success' });
    },
    onError: err => {
      enqueueSnackbar(`Could not update task error: ${err}`, {
        variant: 'error',
      });
    },
  });

  // get user data for reassign salesperson
  const { data: userData } = useQuery(USERS_FOR_DEALER, {
    variables: { dealerIds: [dealerId] },
  });
  const userList = userData?.users || [];

  const handleReassignTaskModalClose = () => setReassignTaskModalOpen(false);

  const onSubmitReassign = () => {
    reassignTask({
      variables: {
        id: taskId,
        input: {
          assignee: username,
        },
      },
    });

    handleReassignTaskModalClose();
  };

  const handleReassign = id => {
    setTaskId(id);
    setReassignTaskModalOpen(true);
  };

  const handleChange = event => {
    setUsername(event.target.value);
  };

  const [rescheduleTask] = useMutation(UPDATE_TASK, {
    onCompleted: () => {
      refetch();
      enqueueSnackbar('Task updated', { variant: 'success' });
    },
    onError: err => {
      enqueueSnackbar(`Could not update task error: ${err}`, {
        variant: 'error',
      });
    },
  });

  const handleRescheduleTaskModalClose = () =>
    setRescheduleTaskModalOpen(false);
  const { control, handleSubmit } = useForm();

  const onSubmit = value => {
    rescheduleTask({
      variables: {
        id: taskId,
        input: {
          due_date: value.due_date,
          status: 0,
        },
      },
    });
    setRescheduleTaskModalOpen(false);
  };

  const handleReschedule = id => {
    setTaskId(id);
    setRescheduleTaskModalOpen(true);
  };

  const statusColor = (status, overdue) => {
    const highLightColor =
      (status === TaskStatus.CANCELLED && TaskStatusColor.GREY) ||
      (overdue && TaskStatusColor.RED) ||
      (status === TaskStatus.TODO && TaskStatusColor.YELLOW) ||
      (status === TaskStatus.COMPLETE && TaskStatusColor.GREEN) ||
      TaskStatusColor.GREY;
    return highLightColor;
  };

  const classes = useStyles();
  return (
    <>
      <Dialog
        open={reassignTaskModalOpen}
        onClose={handleReassignTaskModalClose}
      >
        <DialogTitle>Reassign Task currently assigned to: </DialogTitle>
        <DialogContent>
          <InputLabel>Username</InputLabel>
          <Select
            id="username"
            value={username}
            onChange={handleChange}
            style={{ width: 300 }}
          >
            {userList.map(i => (
              <MenuItem key={i.username} value={i.username}>
                {i.display_name}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleReassignTaskModalClose}
            color="primary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={onSubmitReassign}
            color="primary"
            variant="contained"
            style={theme.actions.confirm}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={rescheduleTaskModalOpen}
        onClose={handleRescheduleTaskModalClose}
      >
        <DialogTitle>Reschedule task</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Controller
              render={({ field: { ref, ...field } }) => (
                <DateTimePicker
                  {...field}
                  autoOk
                  variant="inline"
                  format="yyyy-MM-dd hh:mm a"
                />
              )}
              defaultValue={null}
              control={control}
              name={'due_date'}
              style={{ width: '250px' }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleRescheduleTaskModalClose}
              color="primary"
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              style={theme.actions.confirm}
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        open={addTaskModal}
        onClose={() => setAddTaskModal(false)}
        fullWidth
        maxWidth="md"
      >
        <AddTaskModal
          handleClose={() => setAddTaskModal(false)}
          refetch={refetch}
          customer={customer}
        />
      </Dialog>
      <Dialog
        open={tasksActionModal}
        onClose={() => setTasksActionModal(false)}
        fullWidth
      >
        <TasksActionModal
          handleClose={() => setTasksActionModal(false)}
          handleAddTask={() => setAddTaskModal(true)}
          refetch={refetch}
          customer={customer}
          taskId={taskId}
          status={status}
        />
      </Dialog>
      {desktop ? (
        <Card className={classes.root}>
          <CardContent>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <Typography className={classes.title} gutterBottom>
                  Tasks
                </Typography>
              </Grid>
              <Grid item>
                {!isHidden && (
                  <>
                    <IconButton
                      size="small"
                      onClick={() => {
                        setAddTaskModal(true);
                      }}
                    >
                      <AddIcon style={theme.actions.add} />
                    </IconButton>
                    <Tooltip title="Select tasks to use Bulk Actions">
                      <IconButton
                        color="primary"
                        onClick={handleAnchorClick}
                        size="small"
                      >
                        <MoreVertIcon style={theme.actions.filter} />
                      </IconButton>
                    </Tooltip>
                    <TasksBulkAction
                      refetch={refetch}
                      anchorEl={anchorEl}
                      handleAnchorClose={handleAnchorClose}
                      selected={selected}
                      setSelected={setSelected}
                    />
                    <Tooltip title="Select All Tasks" placement="right">
                      <Checkbox
                        indeterminate={
                          selected.length > 0 &&
                          selected.length < customer.tasks.length
                        }
                        checked={
                          customer.tasks.length > 0 &&
                          selected.length === customer.tasks.length
                        }
                        onChange={handleSelectAllClick}
                      />
                    </Tooltip>
                  </>
                )}
              </Grid>
            </Grid>
            <List>
              {customer.tasks.length === 0 && (
                <Typography style={{ fontSize: 14 }}>
                  There are no tasks for this customer.
                </Typography>
              )}
              {customer?.tasks
                .sort((a, b) => -a.created.localeCompare(b.created))
                .map(task => (
                  <ListItem
                    key={task._id}
                    style={{
                      borderLeftWidth: '5px',
                      borderLeftColor: statusColor(
                        task.status,
                        task.due_date > moment(),
                      ),
                      borderLeftStyle: 'solid',
                      borderBottom: '1px solid #d3d3d3',
                      paddingBottom: '1rem',
                    }}
                  >
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="flex-start"
                    >
                      <Grid item xs={1}>
                        <Checkbox
                          checked={selected.includes(task._id)}
                          onClick={event => handleClick(event, task._id)}
                          selected={selected.includes(task._id)}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Grid
                          container
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          <Grid item>
                            <Icon className="fas fa-calendar-alt" />
                          </Grid>
                          <Grid
                            item
                            style={{ whiteSpace: 'pre', paddingLeft: '.5rem' }}
                          >
                            {formatDate(task.due_date)}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={9}>
                        <Grid
                          container
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Grid item>
                            <Typography
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                fontSize: '1.25rem',
                                fontWeight: '700',
                                paddingBottom: '.75rem',
                              }}
                            >
                              {task.type === 'opportunity' ? (
                                <Icon className="fas fa-thumbs-up" />
                              ) : task.type === 'email' ? (
                                <Icon
                                  className="fas fa-mail-bulk"
                                  style={{ width: '1.15em' }}
                                />
                              ) : task.type === 'call' ? (
                                <Icon className="fas fa-phone-square-alt" />
                              ) : (
                                <Icon className="fas fa-question-circle" />
                              )}
                              &nbsp; {task.description} @{' '}
                              {task.dealer.dealer_name}
                            </Typography>
                            <b>Assigned to:</b>{' '}
                            {` ${task.assignee?.display_name ?? 'N/A'}`}
                            <b style={{ paddingLeft: '1rem' }}>
                              Added on:
                            </b>{' '}
                            {moment(task.created).format('MMM DD, YYYY')}
                          </Grid>
                          <Grid item>
                            {!isHidden && (
                              <TasksAction
                                task={task}
                                handleUpdateStatus={handleUpdateStatus}
                                handleReschedule={handleReschedule}
                                handleReassign={handleReassign}
                              />
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </ListItem>
                ))}
            </List>
          </CardContent>
        </Card>
      ) : (
        <Accordion defaultExpanded={true}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <Typography className={classes.title} gutterBottom>
                  Tasks
                </Typography>
              </Grid>
              <Grid item>
                {!isHidden && (
                  <IconButton
                    size="small"
                    onClick={() => {
                      setAddTaskModal(true);
                    }}
                  >
                    <AddIcon style={theme.actions.add} />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails style={{ paddingTop: 0 }}>
            {customer.tasks.length === 0 && (
              <Typography style={{ fontSize: 14 }}>
                There are no tasks for this customer.
              </Typography>
            )}
            <List style={{ paddingTop: 0 }}>
              {customer.tasks
                .sort((a, b) => -a.created.localeCompare(b.created))
                .map(task => (
                  <ListItem
                    key={task._id}
                    style={{
                      borderLeftWidth: '5px',
                      borderLeftColor: statusColor(
                        task.status,
                        task.due_date > moment(),
                      ),
                      borderLeftStyle: 'solid',
                      paddingBottom: '1rem',
                    }}
                  >
                    <Grid container direction="row">
                      <Grid item xs={1}>
                        {task.type === 'opportunity' ? (
                          <Icon className="fas fa-thumbs-up" />
                        ) : task.type === 'email' ? (
                          <Icon
                            className="fas fa-mail-bulk"
                            style={{ width: '1.15em' }}
                          />
                        ) : task.type === 'call' ? (
                          <Icon className="fas fa-phone-square-alt" />
                        ) : (
                          <Icon className="fas fa-question-circle" />
                        )}
                      </Grid>
                      <Grid item xs={11}>
                        <Grid
                          container
                          direction="column"
                          style={{ paddingLeft: '.5rem' }}
                        >
                          <Grid
                            item
                            style={{
                              fontWeight: '700',
                              fontSize: '1rem',
                              marginBottom: '.25rem',
                            }}
                          >
                            {formatDate(task.due_date)} @{' '}
                            {task.dealer.dealer_name}
                          </Grid>
                          <Grid
                            item
                            style={{
                              fontWeight: '700',
                              fontSize: '1rem',
                              marginBottom: '.25rem',
                              textTransform: 'capitalize',
                            }}
                          >
                            {task.description}
                          </Grid>
                          <Grid item style={{ marginBottom: '.25rem' }}>
                            <Grid
                              container
                              direction="row"
                              justifyContent="space-between"
                            >
                              <Grid item>
                                Assigned to:{' '}
                                {` ${task.assignee?.display_name ?? 'N/A'}`}
                              </Grid>
                              <Grid item>
                                Added on:{' '}
                                {moment(task.created).format('MMM DD, YYYY')}
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item>
                            {!isHidden && (
                              <TasksAction
                                task={task}
                                handleUpdateStatus={handleUpdateStatus}
                                handleReschedule={handleReschedule}
                                handleReassign={handleReassign}
                              />
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </ListItem>
                ))}
            </List>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};

Tasks.fragments = {
  customer: gql`
    fragment TasksFragment on Customer {
      _id
      tasks {
        _id
        assignee_username
        assignee {
          username
          display_name
        }
        created
        dealer {
          dealer_name
        }
        description
        due_date
        status
        type
      }
      ...TasksActionFragment
      ...TasksActionModalFragment
    }
    ${TasksAction.fragments.customer}
    ${TasksActionModal.fragments.customer}
  `,
};

export default Tasks;
