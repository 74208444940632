import '@fortawesome/fontawesome-pro/css/all.min.css';
import '@fortawesome/fontawesome-pro/css/v4-shims.min.css';
import { CloudinaryContext } from 'cloudinary-react';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import DealerContext from 'components/MaterialUI/DealerContext';
import UserContext from 'components/MaterialUI/UserContext';

import rv_adventure_club from 'modules/rv_adventure_club';

import Debug from './components/Debug';
import { cloudinaryConfig, pathwayDefaults, pathways } from './constants';
import admin from './modules/admin';
import app from './modules/app';
import appointments from './modules/appointments';
import appraisals from './modules/appraisals';
import auctions from './modules/auctions';
import customers from './modules/customers';
import dashboard from './modules/dashboard';
import deallog from './modules/deallog';
import inventory from './modules/inventory';
import leads from './modules/leads';
import notes from './modules/notes';
import npv_inventory from 'modules/npv_inventory';
import opportunities from './modules/opportunities';
import parts from './modules/parts';
import rotracker from './modules/rotracker';
import sms from './modules/sms';
import tasks from './modules/tasks';
import used_vehicles from './modules/used_vehicles';
import './styles.css';
import theme from './theme';
import ZendeskWidget from 'modules/app/components/Zendesk';

const {
  ErrorPage,
  MarketLogin,
  Login,
  Logout,
  LandingPage,
  PrivateRoute,
  NotFound404,
  MaintenancePage,
} = app.components;
const { Admin } = admin.components;
const { Appraisals } = appraisals.components;
const { SMS } = sms.components;
const { DealLog } = deallog.components;
const { Opportunities } = opportunities.components;
const { Auctions } = auctions.components;
const { Leads, LeadClaim } = leads.components;
const { Tasks } = tasks.components;
const { ROTracker } = rotracker.components;
const { Inventory } = inventory.components;
const { Customers } = customers.components;
const { Notes } = notes.components;
const { NpvInventory } = npv_inventory.components;
const { UsedVehicles } = used_vehicles.components;
const { MainDashboard } = dashboard.components;
const { Appointments } = appointments.components;
const { Parts } = parts.components;
const { RVAdventureClub } = rv_adventure_club.components;

const maintenance_mode = process.env.REACT_APP_MAINTENANCE;

const returnDocumentTitle = (MAP, defaultValue, pathway) => {
  const key = Object.keys(MAP).filter(k => pathway.includes(k))[0];
  return MAP[key] || defaultValue;
};

const changeTabHeader = pathway => {
  for (const key in pathways) {
    if (pathway.includes(key)) {
      document.title = returnDocumentTitle(
        pathways[key],
        pathwayDefaults[key],
        pathway,
      );
      break;
    } else {
      document.title = 'Market Platform';
    }
  }
};

const App = () => {
  const location = useLocation();
  changeTabHeader(location.pathname);
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider maxSnack={3}>
          <UserContext>
            <ZendeskWidget />
            <DealerContext sessionKey="globalDealerId">
              <CloudinaryContext cloudName={cloudinaryConfig.cloud_name}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <Switch>
                    {maintenance_mode === 'true' && (
                      <Route
                        path="*"
                        component={MaintenancePage}
                        status={503}
                      />
                    )}
                    <Redirect exact from="/" to="/home" />
                    <Redirect
                      exact
                      from="/marketinventory"
                      to="/inventory/dashboard"
                    />
                    <Route path="/password-login" component={Login} />
                    <Route path="/login" component={MarketLogin} />
                    <Route path="/logout" component={Logout} />
                    <Route path="/403" component={ErrorPage} />
                    <Route path="/404" component={NotFound404} />
                    <Route path="/leads/claim" component={LeadClaim} />
                    <Route path="/debug" component={Debug} />
                    <PrivateRoute path="/home" component={LandingPage} />
                    <PrivateRoute path="/dashboard" component={MainDashboard} />
                    <PrivateRoute path="/sms" component={SMS} />
                    <PrivateRoute path="/deallog" component={DealLog} />
                    <PrivateRoute path="/customers" component={Customers} />
                    <PrivateRoute path="/notes" component={Notes} />
                    <PrivateRoute
                      path="/npv-inventory"
                      component={NpvInventory}
                    />
                    <PrivateRoute
                      path="/opportunities"
                      component={Opportunities}
                    />
                    <PrivateRoute path="/auctions" component={Auctions} />
                    <PrivateRoute path="/leads" component={Leads} />
                    <PrivateRoute path="/parts" component={Parts} />
                    <PrivateRoute path="/rotracker" component={ROTracker} />
                    <PrivateRoute path="/inventory" component={Inventory} />
                    <PrivateRoute
                      path="/used-vehicles"
                      component={UsedVehicles}
                    />
                    <PrivateRoute path="/tasks" component={Tasks} />
                    <PrivateRoute
                      path="/appointments"
                      component={Appointments}
                    />
                    <PrivateRoute path="/admin" component={Admin} />
                    <PrivateRoute path="/appraisals" component={Appraisals} />
                    <PrivateRoute
                      path="/rv-adventure-club"
                      component={RVAdventureClub}
                    />
                    <PrivateRoute component={NotFound404} />
                  </Switch>
                </LocalizationProvider>
              </CloudinaryContext>
            </DealerContext>
          </UserContext>
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
