import goLogo from 'assets/go_scan-me.svg';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { ErrorMessage } from '@hookform/error-message';
import gql from 'graphql-tag';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState, createRef } from 'react';
import Pdf from 'react-to-pdf';
import { QRCode } from 'react-qrcode-logo';
import { FormProvider, useForm, useWatch } from 'react-hook-form';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tab from '@mui/material/Tab';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Alert from '@mui/material/Alert';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import { useCodeTablesContext } from 'components/MaterialUI/CodeTablesContext';
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import DealerPicker from 'components/MaterialUI/DealerPicker';
import { useUserContext } from 'components/MaterialUI/UserContext';

import {
  defaultTZ,
  getFullDateTime,
  numberWithCommas,
  stringify,
  titleCase,
  usePersistedState,
  withSuffix,
} from 'utils';

import { parseErrors } from 'modules/inventory/utils';

import { URL } from 'constants.js';
import { usePermissionsContext } from '../contexts/PermissionsContext';
import CBBDetails from './cbb/CBBDetails';
import ReconDetails from './recon/ReconDetails';
import EngineDetails from './vehicle_info/EngineDetails';
import PricingDetails from './vehicle_info/PricingDetails';
import VehicleDescription from './vehicle_info/VehicleDescription';
import VehicleDetails from './vehicle_info/VehicleDetails';
import VehicleAttachments from './VehicleAttachments';
import VehicleCarFaxReport from './VehicleCarFaxReport';
import VehicleEquipment from './VehicleEquipment';
import VehicleFormListPrice from './VehicleFormListPrice';
import VehicleHistory from './VehicleHistory';
import vehicleInputFields, { reconFields } from './VehicleInputFields';
import VehicleInspections from './VehicleInspections';
import VehicleOptions from './VehicleOptions';
import VehiclePhotos from './VehiclePhotos';
import VehicleUVIModal from './VehicleUVIModal';
import { StockStatus } from './constants';

const oneOrNull = someArray => (someArray.length === 1 ? someArray[0] : null);

const OPPORTUNITY_QUERY = gql`
  query OpportunitiesQuery(
    $vehicle_vin: String!
    $dealer_id: Int!
    $stock_number: String!
  ) {
    inventory {
      getVehicleOpportunityHistory(
        vehicle_vin: $vehicle_vin
        dealer_id: $dealer_id
        stock_number: $stock_number
      ) {
        vehicle_vin
        opportunity_created_at
        opportunity_id
        opportunity {
          _id
          status
          lost_reason
        }
        customer_id
        opportunity_updated_at
      }
    }
  }
`;

const LEAD_QUERY = gql`
  query LeadsQuery(
    $vehicle_vin: String!
    $dealer_id: Int!
    $stock_number: String!
  ) {
    inventory {
      getVehicleLeadHistory(
        vehicle_vin: $vehicle_vin
        dealer_id: $dealer_id
        stock_number: $stock_number
      ) {
        vehicle_vin
        lead_created_at
        lead_id
      }
    }
  }
`;

export const DECODE = gql`
  query decodeVehicle($vin: String, $filters: VehicleDecodeFilters) {
    inventory {
      decodeVehicle(vin: $vin, filters: $filters) {
        acodes
        body_type_ids
        cab_type_ids
        code_tables_stale
        doors
        drilldowns_stale
        drive_type_ids
        engine_compressor_ids
        engine_config_ids
        engine_cylinders
        engine_litres
        exterior_colours {
          desc
          ids
        }
        fuel_type_ids
        interior_colours {
          desc
          ids
        }
        make_ids
        manufacturer_codes
        model_ids
        passengers
        style_ids
        styles {
          acode
          body_type_id
          cab_type_id
          doors
          drive_type_id
          engines {
            engine_config_id
            engine_cylinders
            engine_litres
            engine_compressor_id
            fuel_type_id
          }
          make_id
          make_name
          manufacturer_code
          model_id
          model_name
          name
          options {
            group
            header
            option_code
            option_value
          }
          passengers
          stock_image {
            url
          }
          stock_photos {
            results {
              cloudinary_public_id
            }
          }
          style_id
          transmissions {
            id
            name
          }
          trim_variation
          trim
          upholstery_id
          upholstery_name
          year
        }
        transmission_ids
        trims
        trim_variations
        upholstery_ids
        years
      }
    }
  }
`;

const CUSTOMER_INFO = gql`
  query customers($ids: [ID]) {
    customers(ids: $ids) {
      _id
      first_name
      last_name
      primary_phone
      primary_email
    }
  }
`;

const LEAD_CUSTOMER_INFO = gql`
  query leads($filters: LeadFilters) {
    leads(filters: $filters, with_facets: false) {
      results {
        _id
        customer {
          _id
          first_name
          last_name
          primary_email
          primary_phone
        }
      }
    }
  }
`;

const DEALER_OVERLAYS = gql`
  query getDealer($dealerId: Int!) {
    inventory {
      getDealer(id: $dealerId) {
        overlays {
          id
          cloudinary_overlay_id
          exporter_name
          for_new
          for_used
        }
      }
    }
  }
`;

let customersMapping = {};

const VehicleFormGrid = ({
  variant = null,
  direction1 = 'row',
  lg1 = false,
  md1 = false,
  xs1 = false,
  lg3 = false,
  md3 = false,
  xs3 = false,
  children,
}) => {
  if (variant === 1) {
    md1 = 8;
    xs1 = 12;
    md3 = 12;
    xs3 = 12;
  } else if (variant === 2) {
    lg1 = 12;
    md1 = 12;
    xs1 = 12;
    md3 = 8;
    xs3 = 12;
  } else if (variant === 3) {
    direction1 = 'column';
    md1 = 12;
    xs1 = 12;
    md3 = 12;
    xs3 = 12;
  }

  return (
    <Grid item direction={direction1} lg={lg1} md={md1} xs={xs1}>
      <Grid container spacing={1}>
        <Grid item lg={lg3} md={md3} xs={xs3}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};

const VehicleForm = ({ onSubmit, vehicle, updateVehicleErrors, setDirty }) => {
  const { refetch: refetchCodeTables } = useCodeTablesContext();
  const { update: canUpdate } = usePermissionsContext();
  const { dealerId, setDealerId } = useDealerContext();
  const { me } = useUserContext();
  const { data: overlaysData } = useQuery(DEALER_OVERLAYS, {
    variables: {
      dealerId,
    },
  });
  const overlays = overlaysData?.inventory?.getDealer?.overlays || [];
  const { enqueueSnackbar } = useSnackbar();
  const [dealerLogo, setDealerLogo] = useState('');

  useEffect(() => {
    if (
      dealerId &&
      vehicle?.dealer_id &&
      dealerId !== vehicle?.dealer_id &&
      me.dealers.map(x => x.dealer_id).includes(vehicle?.dealer_id)
    ) {
      setDealerId(vehicle?.dealer_id);
      enqueueSnackbar(
        `Set current dealer to: ${
          me.dealers.find(x => x.dealer_id === vehicle?.dealer_id)?.dealer_name
        } `,
        { variant: 'warning' },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicle.dealer_id, me.dealers]);

  const { options, recon, ...vehicleFields } = vehicle;

  const methods = useForm({
    defaultValues: {
      equipment: options
        .filter(x => x.option_code === null)
        .map(({ __typename, ...rest }) => rest),
      options: options
        .filter(x => x.option_code !== null)
        .map(({ __typename, ...rest }) => rest),
      ...Object.fromEntries(
        Object.entries(vehicleFields).filter(([key]) =>
          vehicleInputFields.includes(key),
        ),
      ),
      recon:
        (vehicle.stock_status_id === StockStatus.RECON &&
          recon &&
          Object.fromEntries(
            Object.entries(recon).filter(([key]) => reconFields.includes(key)),
          )) ||
        undefined,
    },
    shouldUnregister: false,
  });
  const {
    clearErrors,
    formState: { errors },
    formState,
    handleSubmit,
    register,
    setError,
    setValue,
    control,
  } = methods;
  const [tabIndex, setTabIndex] = usePersistedState('tabIndex', 0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [decodeVehicle, decodeVehicleQuery] = useLazyQuery(DECODE, {
    fetchPolicy: 'network-only',
  });

  const stockStatusId = useWatch({
    control,
    name: 'stock_status_id',
    defaultValue: vehicle.stock_status_id,
  });

  const [customersArray, setCustomerArray] = useState([]);
  const [getOpportunityCustomers] = useLazyQuery(CUSTOMER_INFO, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      setCustomerArray((data && data.customers) || []);
      // If we have no leads, then we should create an opportunity only CSV.
      if (leadIds.length === 0) setCreatingCSV(true);
    },
  });
  const [getLeadCustomers] = useLazyQuery(LEAD_CUSTOMER_INFO, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      const customers = data?.leads.results || [];
      let leadCustomerArray = [];
      for (let i = 0; i < customers.length; i++) {
        leadCustomerArray.push(data.leads.results[i].customer);
        customersMapping[data.leads.results[i]._id] =
          data.leads.results[i].customer._id;
      }
      setCustomerArray([...leadCustomerArray, ...customersArray]);
      // If we have no opportunities, then we create a lead only CSV.
      // If we have opportunities AND leads, then we should trigger it here
      // so that we dont get an export from the opportunities AND from leads
      // queries.
      if (
        opportunityCustomerIds.length === 0 ||
        (opportunityCustomerIds.length > 0 && leadIds.length > 0)
      )
        setCreatingCSV(true);
    },
  });
  const [showUVI, setShowUVI] = useState(false);
  const [showCarFaxReport, setShowCarFaxReport] = useState(false);
  const [creatingCSV, setCreatingCSV] = useState(false);

  register('options');
  register('equipment');
  register('is_detailed_pricing');
  register('finance_rates');
  register('lease_rates');

  const oppQuery = useQuery(OPPORTUNITY_QUERY, {
    variables: {
      vehicle_vin: vehicle.vin,
      dealer_id: dealerId,
      stock_number: vehicle.stock_number,
    },
    skip: !vehicle.vin || !vehicle.stock_number,
  });
  const leadQuery = useQuery(LEAD_QUERY, {
    variables: {
      vehicle_vin: vehicle.vin,
      dealer_id: dealerId,
      stock_number: vehicle.stock_number,
    },
    skip: !vehicle.vin || !vehicle.stock_number,
  });
  const opportunities = oppQuery?.data?.inventory?.getVehicleOpportunityHistory;
  const leads = leadQuery?.data?.inventory?.getVehicleLeadHistory;

  let opportunityCustomerIds = [];
  for (let i = 0; i < opportunities?.length; i++) {
    opportunityCustomerIds.push(opportunities[i].customer_id);
  }

  let leadIds = [];
  for (let i = 0; i < leads?.length; i++) {
    leadIds.push(leads[i].lead_id);
  }

  const handleLeadsOppsExport = () => {
    getOpportunityCustomers({
      variables: {
        ids: opportunityCustomerIds,
      },
    });

    getLeadCustomers({
      variables: {
        filters: { ids: leadIds },
      },
    });
  };

  const csvFields = [
    '_id',
    'first_name',
    'last_name',
    'primary_phone',
    'primary_email',
    'opportunity_url',
    'lead_url',
    'lead_created_at',
    'lost_reason',
  ];

  const csvCustom = {
    opportunity_url: {
      render: ({ _id }) => {
        const opp_result = opportunities.filter(opp => opp.customer_id === _id);
        if (opp_result?.length > 0) {
          return `${URL.mobile}/opportunities/${opp_result[0].opportunity_id}/${opp_result[0].customer_id}/details`;
        } else {
          return null;
        }
      },
    },
    lead_url: {
      render: ({ _id }) => {
        const lead_result = Object.keys(customersMapping).find(
          key => customersMapping[key] === _id,
        );
        if (lead_result) {
          return `${URL.mobile}/leads/${lead_result}`;
        } else {
          return null;
        }
      },
    },
    lead_created_at: {
      render: ({ _id }) => {
        const lead_id = Object.keys(customersMapping).find(
          key => customersMapping[key] === _id,
        );
        const lead_result = leads.filter(lead => lead.lead_id === lead_id);
        delete customersMapping[lead_id];
        if (lead_result?.length > 0) {
          return lead_result[0].lead_created_at
            ? moment(lead_result[0].lead_created_at)
                .tz(defaultTZ)
                .format('YYYY-MM-DD HH:mm:ss')
            : null;
        }
      },
    },
    lost_reason: {
      render: ({ _id }) => {
        const opp_result = opportunities?.filter(
          opp => opp.customer_id === _id,
        );
        if (opp_result?.length > 0) {
          return opp_result[0].lost_reason;
        }
      },
    },
  };

  useEffect(() => {
    const createCSV = async () => {
      if (customersArray.length > 0) {
        const rows = [
          ['Vehicle'],
          ['VIN', `${vehicle.vin}`],
          ['Make', `${vehicle.make_name}`],
          ['Model', `${vehicle.model_name}`],
          ['Year', `${vehicle.year}`],
          ['Trim', `${vehicle.trim}`],
          [' '],
          [
            'ID',
            'First Name',
            'Last Name',
            'Primary Phone',
            'Primary Email',
            'Link to Opportunity',
            'Link to Lead',
            'Created',
            'Lost Reason',
          ],
        ].concat(
          customersArray.map(v =>
            csvFields.map(field => csvCustom[field]?.render?.(v) || v[field]),
          ),
        );

        const csvContent =
          'data:text/csv;base64,' +
          btoa(unescape(encodeURIComponent(stringify(rows))));
        const csvFileName = `customers_leads_opps_export_${moment().format(
          'YYYYMMDD_HHmm',
        )}.csv`;

        const element = document.createElement('a');
        element.href = csvContent;
        element.download = csvFileName;
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      }

      setCreatingCSV(false);
    };

    if (creatingCSV) createCSV();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creatingCSV]);

  useEffect(() => {
    if (updateVehicleErrors) {
      try {
        const updateErrors = parseErrors(updateVehicleErrors);
        for (const [k, v] of Object.entries(updateErrors))
          setError(k, { message: v.join(', ') });
      } catch (err) {
        return (
          <Alert severity="warning">
            {JSON.stringify(updateVehicleErrors)}
          </Alert>
        );
      }
    }
  }, [updateVehicleErrors, setError]);

  useEffect(() => {
    const results = decodeVehicleQuery.data?.inventory?.decodeVehicle;
    if (results) {
      const updateFormWithResults = ({
        acodes,
        body_type_ids,
        cab_type_ids,
        doors,
        drive_type_ids,
        engine_compressor_ids,
        engine_config_ids,
        engine_cylinders,
        engine_litres,
        fuel_type_ids,
        make_ids,
        model_ids,
        passengers,
        style_ids,
        styles,
        transmission_ids,
        trims,
        trim_variations,
        upholstery_ids,
        years,
      }) => {
        // Set values for anything where there's only one option.
        const shouldDirty = true;
        setValue('acode', oneOrNull(acodes), { shouldDirty });
        setValue('body_type_id', oneOrNull(body_type_ids), { shouldDirty });
        setValue('cab_type_id', oneOrNull(cab_type_ids), { shouldDirty });
        setValue('doors', oneOrNull(doors), { shouldDirty });
        setValue('drive_type_id', oneOrNull(drive_type_ids), { shouldDirty });
        setValue('engine_compressor_id', oneOrNull(engine_compressor_ids), {
          shouldDirty,
        });
        setValue('engine_config_id', oneOrNull(engine_config_ids), {
          shouldDirty,
        });
        setValue('engine_cylinders', oneOrNull(engine_cylinders), {
          shouldDirty,
        });
        setValue('engine_litres', oneOrNull(engine_litres), { shouldDirty });
        setValue('fuel_type_id', oneOrNull(fuel_type_ids), { shouldDirty });
        setValue('make_id', oneOrNull(make_ids), { shouldDirty });
        setValue('model_id', oneOrNull(model_ids), { shouldDirty });
        setValue('passengers', oneOrNull(passengers), { shouldDirty });
        setValue('published_trim', oneOrNull(trims), { shouldDirty });
        setValue('style_id', oneOrNull(style_ids), { shouldDirty });
        setValue('transmission_id', oneOrNull(transmission_ids), {
          shouldDirty,
        });
        setValue('trim', oneOrNull(trims), { shouldDirty });
        setValue('trim_variation', oneOrNull(trim_variations), { shouldDirty });
        setValue('upholstery_id', oneOrNull(upholstery_ids), { shouldDirty });
        setValue('year', oneOrNull(years), { shouldDirty });

        // if there's one and only one style, we can set equipment based on that.
        if (styles.length === 1) {
          setValue(
            'equipment',
            styles[0].options.filter(x => isEmpty(x.option_code)),
            { shouldDirty },
          );
        }
      };
      if (results.code_tables_stale)
        refetchCodeTables().then(() => {
          updateFormWithResults(results);
        });
      else updateFormWithResults(results);
    }
  }, [decodeVehicleQuery, refetchCodeTables, setValue]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  // TODO: revisit this in later versions of react-hook-form (shouldn't need
  // to check dirtyFields).  Current version 6.5.1
  const dirty =
    formState.isDirty || Object.keys(formState.dirtyFields).length > 0;

  const ref = createRef();

  // Dealers with access to download QR Code.
  // To add a new dealer the logo would need to be converted from svg to png in S3 Bucket goauto-pitch-sheet-media
  const dealerWithQr = [5956, 5965, 5957, 270]; //T&T Honda, Audi Royal Oak, Airdrie Honda & Honda Red Deer

  // URL For QR Code campaign
  const encodedVDPURI = encodeURI(
    `${vehicle.vdp_url}?source=${vehicle.dealer_name}&medium=qrCodeHanger&campaign=${vehicle.item_key}`,
  );

  useEffect(() => {
    if (!dealerLogo && dealerId && dealerWithQr.includes(dealerId))
      fetch(
        `https://s3-us-west-2.amazonaws.com/goauto-pitch-sheet-media/logo-${dealerId}.png?${performance.now()}`,
        { mode: 'cors' },
      )
        .then(r => r.blob())
        .then(blob => window.URL.createObjectURL(blob))
        .then(url => setDealerLogo(url));
  }, [dealerLogo, setDealerLogo, dealerId, dealerWithQr]);

  return (
    <FormProvider {...methods}>
      <Box marginLeft={1} marginTop={1} style={{ paddingLeft: '20px' }}>
        <DealerPicker disabled />
      </Box>
      {Object.keys(errors || {}).map(field => (
        <Alert severity="error">
          {titleCase(field.replace('_id'))}: <ErrorMessage name={field} />
        </Alert>
      ))}
      <Box marginLeft={1} marginTop={1}>
        <div style={{ paddingLeft: '20px' }}>
          <span>
            <Grid container>
              <Grid item>
                <Hidden smDown>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                      paddingRight: '1rem',
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 'bold',
                        fontSize: '20px',
                        paddingRight: '1rem',
                      }}
                    >
                      {' '}
                      {vehicle.displayName}{' '}
                    </span>{' '}
                    {vehicle.trim_variation}{' '}
                  </div>
                </Hidden>
                <Hidden smUp>
                  <span
                    style={{
                      fontWeight: 'bold',
                      fontSize: '20px',
                    }}
                  >
                    {' '}
                    <div style={{ width: '200px' }}>{vehicle.displayName}</div>
                  </span>
                </Hidden>
              </Grid>
              <Grid
                item
                style={{
                  display: 'flex',
                  marginTop: 'auto',
                  marginBottom: 'auto',
                }}
              >
                {vehicle.has_uvi ? (
                  <Tooltip title={'Used Vehicle Inspection Uploaded'}>
                    <i
                      className={`fas fa-file`}
                      style={{
                        color: '#74B72E',
                        padding: '0 1rem 0 0.5rem',
                        fontSize: '20px',
                      }}
                    />
                  </Tooltip>
                ) : (
                  ''
                )}
              </Grid>
              <Grid item>
                <span>
                  <Chip
                    size="small"
                    label={titleCase(vehicle.stock_status_name)}
                  ></Chip>
                  <IconButton onClick={handleClick} size="large">
                    <MoreVertIcon color="primary" />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    style={{ width: '400px' }}
                  >
                    <MenuItem onClick={() => setShowUVI(true)}>
                      Upload UVI
                    </MenuItem>
                    <MenuItem onClick={() => setShowCarFaxReport(true)}>
                      View CarFax Report
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleLeadsOppsExport()}
                      disabled={
                        opportunityCustomerIds.length === 0 &&
                        leadIds.length === 0
                      }
                    >
                      Export Opportunities and Lead Data
                    </MenuItem>
                    {dealerWithQr.includes(dealerId) && (
                      <Pdf targetRef={ref} filename={`${vehicle.item_key}.pdf`}>
                        {({ toPdf }) => (
                          <MenuItem
                            onClick={() => {
                              toPdf();
                              handleClose();
                            }}
                            color="primary"
                            variant="outlined"
                          >
                            Download QR
                          </MenuItem>
                        )}
                      </Pdf>
                    )}
                    {/* <MenuItem onClick={handleClose}>Send to Appraisals</MenuItem>
              <MenuItem onClick={handleClose}>Send to Auctions</MenuItem>
              <MenuItem onClick={handleClose}>Create Pitch Sheet</MenuItem>
              <MenuItem onClick={handleClose}>RDR Punch</MenuItem> */}
                  </Menu>

                  <Dialog
                    open={showUVI}
                    onClose={() => {
                      setShowUVI(false);
                    }}
                  >
                    <VehicleUVIModal
                      handleClose={() => {
                        handleClose();
                        setShowUVI(false);
                      }}
                      vehicle={vehicle}
                    />
                  </Dialog>
                  <Dialog
                    open={showCarFaxReport}
                    onClose={() => {
                      setShowCarFaxReport(false);
                    }}
                  >
                    <VehicleCarFaxReport
                      handleClose={() => {
                        handleClose();
                        setShowCarFaxReport(false);
                      }}
                      vehicle={vehicle}
                    />
                  </Dialog>
                </span>
              </Grid>
            </Grid>
            <div
              ref={ref}
              style={{
                position: 'absolute',
                left: '-1000px',
                top: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '800px',
              }}
            >
              <div
                style={{
                  marginTop: '16px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box
                  component="img"
                  style={{ width: '150px', margin: '16px auto' }}
                  alt="logo"
                  src={dealerLogo}
                />
                <QRCode
                  value={encodedVDPURI}
                  size={300}
                  logoImage={goLogo}
                  logoHeight={80}
                  logoWidth={80}
                  logoPadding={5}
                  qrStyle="square"
                  id={'QR'}
                />
              </div>
            </div>
          </span>
          <Hidden smUp>{vehicle.trim_variation} </Hidden>
          <div style={{ fontSize: '18px' }}>
            <VehicleFormListPrice vehicle={vehicle} />{' '}
            {vehicle.is_on_special ? (
              <Tooltip title={'Vehicle is On Special'}>
                <i className={`fas fa-tag`} style={{ color: '#74B72E' }} />
              </Tooltip>
            ) : (
              ''
            )}
          </div>
          <div style={{ fontSize: '0.875rem' }}>
            {vehicle.stock_number} / {vehicle.vin} /{' '}
            {numberWithCommas(vehicle.odometer)} km
          </div>
          <div style={{ fontSize: '0.875rem' }}>
            Created:{' '}
            {getFullDateTime(
              withSuffix(vehicle.date_created, 'Z'),
              me?.goUserProfile?.settings?.timezone,
            )}
          </div>
        </div>
      </Box>
      <form
        onSubmit={() => {
          clearErrors(); // Need to manually clear errors, since we've manually
          // used setError from server. handleSubmit will not fire otherwise.
          handleSubmit(onSubmit)();
        }}
      >
        <TabContext value={tabIndex}>
          <TabList
            onChange={handleChange}
            variant="scrollable"
            indicatorColor="secondary"
            textColor="primary"
          >
            <Tab label="Vehicle Info" value={0} />
            <Tab label="Pricing" value={1} />
            {/* Disabled this as of 2024-03-31 since we don't have access to this tool anymore.  Leaving code in
            for now in case we want to restore it in the near future.*/}
            <Tab
              disabled={true}
              style={{ display: 'none' }}
              label="Used Pricing Tool"
              value={2}
            />
            <Tab label="CBB Pricing" value={3} />
            <Tab label="Photos" value={4} />
            <Tab label="Installed Options" value={5} />
            <Tab label="Equipment" value={6} />
            <Tab label="Attachments" value={7} />
            <Tab label="Vehicle History" value={8} />
            <Tab label="Inspections" value={9} />
          </TabList>
          <TabPanel value={0}>
            <Box m={1}>
              <Grid container spacing={3}>
                <VehicleFormGrid variant={1}>
                  <VehicleDetails
                    decodeVehicleQuery={decodeVehicleQuery}
                    decodeVehicle={decodeVehicle}
                    vehicle={vehicle}
                  />
                </VehicleFormGrid>
                <VehicleFormGrid variant={2}>
                  <EngineDetails styleId={vehicle.style_id} />
                </VehicleFormGrid>
                {stockStatusId === StockStatus.RECON && (
                  <VehicleFormGrid variant={2}>
                    <ReconDetails vehicle={vehicle} />
                  </VehicleFormGrid>
                )}
                <VehicleFormGrid variant={2}>
                  <VehicleDescription vehicle={vehicle} />
                </VehicleFormGrid>
              </Grid>
            </Box>
          </TabPanel>
          <TabPanel value={1}>
            <Box m={1}>
              <Grid container spacing={3}>
                <VehicleFormGrid variant={3}>
                  <PricingDetails vehicle={vehicle} />
                </VehicleFormGrid>
              </Grid>
            </Box>
          </TabPanel>
          {/* See above note for why this is disabled. */}
          {/* <TabPanel value={2}>
            <Box m={1}>
              <Grid container spacing={3}>
                <VehicleFormGrid variant={3}>
                  <UsedPricingTool open={tabIndex === 2} vehicle={vehicle} />
                </VehicleFormGrid>
              </Grid>
            </Box>
          </TabPanel> */}
          <TabPanel value={3}>
            <Box m={1}>
              <Grid container spacing={3}>
                <VehicleFormGrid variant={2}>
                  <CBBDetails vehicle={vehicle} />
                </VehicleFormGrid>
              </Grid>
            </Box>
          </TabPanel>
          <TabPanel value={4}>
            <VehicleFormGrid variant={2}>
              <VehiclePhotos vehicle={vehicle} overlays={overlays} />
            </VehicleFormGrid>
          </TabPanel>
          <TabPanel value={5}>
            <VehicleFormGrid variant={2}>
              <VehicleOptions vehicle={vehicle} />
            </VehicleFormGrid>
          </TabPanel>
          <TabPanel value={6}>
            <VehicleFormGrid variant={2}>
              <VehicleEquipment vehicle={vehicle} />
            </VehicleFormGrid>
          </TabPanel>
          <TabPanel value={7}>
            <VehicleFormGrid variant={2}>
              <VehicleAttachments vehicle={vehicle} />
            </VehicleFormGrid>
          </TabPanel>
          <TabPanel value={8}>
            <VehicleFormGrid variant={2}>
              <VehicleHistory
                vehicle={vehicle}
                leads={leads}
                opportunities={opportunities}
              />
            </VehicleFormGrid>
          </TabPanel>
          <TabPanel value={9}>
            <VehicleFormGrid variant={2}>
              <VehicleInspections vehicle={vehicle} />
            </VehicleFormGrid>
          </TabPanel>
        </TabContext>
        {canUpdate && (
          <Hidden smDown>
            <div
              style={{
                display: 'flex',
                justifyContent: 'right',
                width: '100%',
              }}
            >
              <AppBar
                position="fixed"
                style={{
                  top: 'auto',
                  bottom: 0,
                  zIndex: 1000,
                  backgroundColor: '#eae9e9',
                }}
              >
                <Toolbar
                  style={{ display: 'flex', justifyContent: 'space-evenly' }}
                >
                  <Typography
                    style={{
                      bottom: '15px',
                      zIndex: 1000,
                      color: 'black',
                      textAlign: 'right',
                    }}
                    variant="h6"
                  >
                    {!dirty
                      ? 'No changes to save.'
                      : 'Remember to save your changes!'}
                  </Typography>
                  <Button
                    disabled={!dirty}
                    variant="contained"
                    type="submit"
                    style={{
                      zIndex: 1000,
                      width: '100px',
                      backgroundColor: `${!dirty ? '#bfbebe' : '#74B72E'}`,
                    }}
                  >
                    Save
                  </Button>
                </Toolbar>
              </AppBar>
            </div>
          </Hidden>
        )}
        {canUpdate && (
          <Hidden smUp>
            <Fab
              disabled={!dirty}
              color="primary"
              style={{
                bottom: '10px',
                right: '10px',
                position: 'fixed',
                zIndex: 1000,
                backgroundColor: `${!dirty ? 'grey' : '#5bc236'}`,
              }}
              type="submit"
            >
              SAVE
            </Fab>
          </Hidden>
        )}
      </form>
    </FormProvider>
  );
};

VehicleForm.fragments = {
  vehicle: gql`
    fragment VehicleFormVehicle on GreaseInventoryVehicle {
      id
      is_detailed_pricing
      is_on_special
      list_price
      vdp_url
      item_key
    }
  `,
};
export default VehicleForm;
