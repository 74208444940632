import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import _ from 'lodash';
import 'chart.js';
import gql from 'graphql-tag';
import MaterialTable from 'material-table';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';

// MUI
import {
  Box,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  InputAdornment,
  IconButton,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import CancelIcon from '@mui/icons-material/Cancel';
import InfoIcon from '@mui/icons-material/Info';

// Internal
import {
  LoadingBackdrop,
  RadioControl,
  TextFieldControl,
} from 'components/MaterialUI';
import { formatPrice } from 'utils';
import { hideCost } from 'modules/inventory/utils';
import { useUserContext } from 'components/MaterialUI/UserContext';
import { currencyFormatter, getFullDateTime, withSuffix } from 'utils';

import PanelTitle from './PanelTitle';

const HISTORY_QUERY = gql`
  query UnitHistoryQuery($dateFrom: String, $dateTo: String, $id: Int!) {
    npv {
      getVehicleHistoryGraph(dateFrom: $dateFrom, dateTo: $dateTo, id: $id) {
        dataSets {
          data
          name
        }
        dates
        id
      }
      getVehicleHistoryTable(dateFrom: $dateFrom, dateTo: $dateTo, id: $id) {
        changeType
        description
        difference
        field
        index
        modifiedAt
        modifiedBy
        newValue
        oldValue
        urn
      }
    }
  }
`;

const UnitHistory = ({ vehicle }) => {
  const { currentUser, me } = useUserContext();
  const [toDateTime, setToDateTime] = useState(moment());
  const [fromDateTime, setFromDateTime] = useState(
    moment().subtract(90, 'days'),
  );
  const [alignment, setAlignment] = React.useState('all');
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  const [getHistory, historyQuery] = useLazyQuery(HISTORY_QUERY);
  const timezone =
    me?.goUserProfile?.settings?.timezone ||
    Intl.DateTimeFormat().resolvedOptions().timeZone;
  const mobile = useMediaQuery(theme => theme.breakpoints.down('md'));

  useEffect(() => {
    getHistory({
      variables: {
        id: vehicle.id,
        dateFrom: fromDateTime.format('YYYY-MM-DD'),
        dateTo: toDateTime.format('YYYY-MM-DD'),
      },
    });
  }, [toDateTime, fromDateTime]);

  const historyData = historyQuery?.data?.npv?.getVehicleHistoryGraph;
  const historyTableData = historyQuery?.data?.npv?.getVehicleHistoryTable;
  const loading = historyQuery?.loading;
  const labels = historyData?.dates;

  const defaultValues = {
    fill: false,
    lineTension: 0.1,
    borderCapStyle: 'butt',
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',
    pointBackgroundColor: '#fff',
    pointBorderWidth: 3,
    pointHoverRadius: 5,
    pointHoverBorderColor: 'rgba(220,220,220,1)',
    pointHoverBorderWidth: 2,
    pointRadius: 4,
    pointHitRadius: 10,
    cubicInterpolationMode: 'monotone',
  };

  const data = {
    labels: labels,
    datasets: [
      {
        ...defaultValues,
        label: 'Special Price',
        backgroundColor: 'rgba(0,128,0,0.4)',
        borderColor: 'rgba(0,128,0,1)',
        pointBorderColor: 'rgba(0,128,0,1)',
        pointHoverBackgroundColor: 'rgba(0,128,0,1)',
        data: historyData?.dataSets?.find(x => x.name === 'specialPrice').data,
        type: 'line',
        yAxisID: 'A',
      },
      {
        ...defaultValues,
        label: 'Regular Price',
        backgroundColor: 'rgba(255,0,0,0.4)',
        borderColor: 'rgba(255,0,0,1)',
        pointBorderColor: 'rgba(255,0,0,1)',
        pointHoverBackgroundColor: 'rgba(255,0,0,1)',
        data: historyData?.dataSets?.find(x => x.name === 'regularPrice').data,
        type: 'line',
        yAxisID: 'A',
      },
      {
        ...defaultValues,
        label: 'MSRP',
        backgroundColor: 'rgba(0,0,255,0.4)',
        borderColor: 'rgba(0,0,255,1)',
        pointBorderColor: 'rgba(0,0,255,1)',
        pointHoverBackgroundColor: 'rgba(0,0,255,1)',
        data: historyData?.dataSets?.find(x => x.name === 'msrp').data,
        type: 'line',
        yAxisID: 'A',
        hidden: true,
      },
      {
        type: 'bar',
        label: 'Opportunities',
        backgroundColor: 'rgba(135, 211, 124, 1)',
        data: historyData?.dataSets?.find(x => x.name === 'opportunitiesCount')
          .data,
        yAxisID: 'B',
      },
      {
        type: 'bar',
        label: 'Leads',
        backgroundColor: 'rgb(75, 192, 192)',
        data: historyData?.dataSets?.find(x => x.name === 'leadsCount').data,
        yAxisID: 'B',
      },
    ],
  };
  if (!hideCost(currentUser.role)) {
    data.datasets.push({
      ...defaultValues,
      type: 'line',
      label: 'Cost',
      backgroundColor: 'rgba(255,200,0,0.4)',
      borderColor: 'rgba(255,200,0,1)',
      pointBorderColor: 'rgba(255,200,0,1)',
      pointHoverBackgroundColor: 'rgba(255,200,0,1)',
      data: historyData?.dataSets?.find(x => x.name === 'cost').data,
      yAxisID: 'A',
    });
  }

  const generateUrl = rowData => {
    let response = { toolTipDescription: null, url: null };
    if (rowData.changeType === 'lead') {
      const recordId = rowData.urn.split(':').pop();
      response['toolTipDescription'] = 'Go To Lead';
      response['url'] = `https://market.goauto.io/leads/${recordId}`;
    } else if (rowData.changeType === 'opportunity') {
      const recordId = rowData.urn.split(':').pop();
      response['toolTipDescription'] = 'Go To Opportunity';
      response[
        'url'
      ] = `https://market.goauto.io/opportunities/${recordId}/details`;
    }

    return response;
  };

  const oppLeadColumns = [
    {
      width: '1%',
      cellStyle: { whiteSpace: 'nowrap' },
      title: 'Action',
      field: 'action',
      filtering: false,
      render: rowData => {
        const response = generateUrl(rowData);
        return (
          <>
            {response.url && (
              <Tooltip title={response.toolTipDescription}>
                <IconButton
                  onClick={() => window.open(response.url, '_blank')}
                  size="large"
                >
                  <InfoIcon style={{ color: '#2196f3', fontSize: '22px' }} />
                </IconButton>
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      title: 'Date',
      field: 'modifiedAt',
      filtering: false,
      render: rowData =>
        getFullDateTime(withSuffix(rowData.modifiedAt, 'Z'), timezone),
      defaultSort: 'desc',
    },
    {
      title: 'Description',
      field: 'description',
      filtering: false,
    },
  ];

  const columns = [
    ...oppLeadColumns,
    { title: 'User', field: 'modifiedBy', filtering: false },
    {
      title: 'Old Value',
      field: 'oldValue',
      filtering: false,
      render: rowData =>
        rowData.changeType === 'price'
          ? '$' + currencyFormatter.format(rowData.oldValue).toString()
          : null,
    },
    {
      title: 'New Value',
      field: 'newValue',
      filtering: false,
      render: rowData =>
        rowData.changeType === 'price'
          ? '$' + currencyFormatter.format(rowData.newValue).toString()
          : null,
    },
    {
      title: 'Difference',
      field: 'difference',
      filtering: false,
      render: rowData =>
        rowData.changeType === 'price'
          ? '$' + currencyFormatter.format(rowData.difference).toString()
          : null,
    },
  ];

  const tableOptions = {
    pageSize: 10,
    pageSizeOptions: [10, 25, 50, 100],
    filtering: false,
  };

  return (
    <Paper>
      <LoadingBackdrop open={loading}>Loading...</LoadingBackdrop>
      <Box>
        <PanelTitle title="Unit History" vehicle={vehicle} />
      </Box>
      <Grid item xs={6} style={{ textAlign: 'right' }}>
        <Box>
          <DateTimePicker
            onChange={value => setFromDateTime(value)}
            value={fromDateTime}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={e => {
                      e.stopPropagation();
                      clearFromArrivedDate();
                    }}
                    size="large"
                  >
                    <CancelIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            label={'Date From'}
            format="YYYY-MM-DD HH:mm A"
          />
        </Box>
        <DateTimePicker
          onChange={value => setToDateTime(value)}
          value={toDateTime}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={e => {
                    e.stopPropagation();
                    clearToArrivedDate();
                  }}
                  size="large"
                >
                  <CancelIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          label={'Date To'}
          format="YYYY-MM-DD HH:mm A"
        />
      </Grid>
      {labels?.length > 0 && (
        <Bar
          data={data}
          options={{
            scales: {
              xAxes: [
                {
                  type: 'time',
                  time: {
                    unit: 'day',
                    displayFormats: {
                      day: 'MMM DD YYYY',
                    },
                  },
                  barPercentage: 1,
                },
              ],
              yAxes: [
                {
                  id: 'A',
                  type: 'linear',
                  position: 'left',
                  ticks: {
                    callback: function (value, index, values) {
                      return formatPrice(value);
                    },
                  },
                },
                {
                  id: 'B',
                  type: 'linear',
                  position: 'right',
                  ticks: {
                    max: 5,
                    min: 0,
                    stepSize: 1,
                  },
                },
              ],
            },
            tooltips: {
              callbacks: {
                label: function (t, d) {
                  const label = d.datasets[t.datasetIndex].label;
                  if (t.datasetIndex < 4) {
                    return label + ': ' + formatPrice(t.yLabel);
                  } else {
                    return label + ': ' + t.yLabel;
                  }
                },
              },
            },
          }}
          width={100}
          height={mobile ? 100 : 50}
        />
      )}
      {labels?.length === 0 && (
        <>There is no history in this date range for this unit.</>
      )}
      {historyTableData?.length > 0 && (
        <>
          <ToggleButtonGroup
            value={alignment}
            exclusive
            onChange={handleAlignment}
            style={{ padding: '1rem 0 1rem 0' }}
          >
            <ToggleButton value="all">All</ToggleButton>
            <ToggleButton value="pricing">Pricing</ToggleButton>
            <ToggleButton value="opps">Opportunities</ToggleButton>
            <ToggleButton value="leads">Leads</ToggleButton>
          </ToggleButtonGroup>
          <div style={{ paddingBottom: '2rem' }}>
            {alignment === 'all' && (
              <MaterialTable
                title={'All History Changes'}
                data={historyTableData}
                columns={columns}
                options={tableOptions}
              />
            )}
            {alignment === 'opps' && (
              <MaterialTable
                title={'Opportunity Changes'}
                data={historyTableData.filter(
                  x => x.changeType === 'opportunity',
                )}
                columns={oppLeadColumns}
                options={tableOptions}
              />
            )}
            {alignment === 'pricing' && (
              <MaterialTable
                title={'Pricing Changes'}
                data={historyTableData.filter(x => x.changeType === 'price')}
                columns={columns}
                options={tableOptions}
              />
            )}
            {alignment === 'leads' && (
              <MaterialTable
                title={'Lead Changes'}
                data={historyTableData.filter(x => x.changeType === 'lead')}
                columns={oppLeadColumns}
                options={tableOptions}
              />
            )}
          </div>
        </>
      )}
    </Paper>
  );
};

export default UnitHistory;
