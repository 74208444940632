import { useSnackbar } from 'notistack';
import React, { useRef, useState } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { DatePicker } from '@mui/x-date-pickers';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import RAMInspCheckboxes from '../RAMInspCheckboxes';
import FormAccordion from './FormHelpers/FormAccordion';

const useStyles = makeStyles({
  legendCB: {
    margin: 0,
    '& .MuiTypography-body1': {
      fontSize: '0.875rem',
      textAlign: 'center',
      fontWeight: 'bold',
    },
    '& .MuiSvgIcon-root': {
      width: '18px',
      height: '18px',
    },
  },
  reconditioningFields: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  otherCBs: {
    textAlign: 'center',
  },
  numInput: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  adornment: {
    '& .MuiTypography-body1': {
      fontSize: '0.75rem',
    },
  },
});

// Richmond Auto Mall Bronze Inspection form fields

const coolingSystem = {
  ram1: 'Radiator & Cooling System',
  ram2: 'Water Pump',
  ram3: 'Fan',
  ram4: 'Hoses',
};

const body = {
  ram5: 'Mirrors',
  ram6: 'Windshield/Windows',
  ram7: 'Windshield Wipers',
  ram8: 'Seat Belts',
};

const fuelSystem = {
  ram9: 'Fuel Injection',
  ram10: 'Air Filter',
  ram11: 'Fuel Lines',
  ram12: 'Fuel Pump',
  ram13: 'Fuel Tank',
};

const brakes = {
  ram14: 'Lines/Hoses',
  ram15: 'Front (approx.)',
  ram16: 'Rear (approx.)',
  ram17: 'Fluid Level/Condition',
  ram18: 'Fluid',
};

const wheelsTires = {
  ram19: 'Left Front Tire Tread Remaining (approx.)',
  ram20: 'Right Front Tire Tread Remaining (approx.)',
  ram21: 'Left Rear Tire Tread Remaining (approx.)',
  ram22: 'Right Rear Tire Tread Remaining (approx.)',
  ram23: 'Wheel Rims/Covers',
};

const driveline = {
  ram24: 'Transmission',
  ram25: 'CV Joints',
};

const steeringSuspension = {
  ram26: 'Alignment (Visual)',
  ram27: 'Control Arm',
  ram28: 'Tie Rod Ends',
  ram29: 'Power Steering & Lines',
  ram30: 'Wheel Bearings',
  ram31: 'Springs',
  ram32: 'Strut - Front',
  ram33: 'Strut - Rear',
};

const electricalSystem = {
  ram34: 'Horn',
  ram35: 'Headlights',
  ram36: 'Interior Lights',
  ram37: 'Turn Signals - Front',
  ram38: 'Turn Signals - Rear',
  ram39: 'Reverse Lights',
  ram40: 'Wipers',
  ram41: 'Heater - Interior',
  ram42: 'Air Conditioning',
  ram43: 'Radio',
};

const misc = {
  ram44: 'Belts',
  ram45: 'Cruise Control',
  ram46: 'Oil Level & Condition',
  ram47: 'Drive Shafts',
};

const liens = {
  liens_cleared: 'Liens Cleared',
};

const icbc = {
  icbc_check: 'ICBC Damage Check',
};

const oop = {
  oop_check: 'Out-of-Province Check',
};

const CheckboxGroup = ({ valueGroup, handleGroup, name }) => {
  return (
    <RAMInspCheckboxes
      valueGroup={valueGroup}
      handleGroup={handleGroup}
      name={name}
      blue={false}
      orange={false}
      white={false}
      greenValue="S"
      redValue="N"
    />
  );
};

const OtherCheckboxes = ({ valueGroup, handleGroup, name }) => {
  return (
    <RAMInspCheckboxes
      valueGroup={valueGroup}
      handleGroup={handleGroup}
      name={name}
      blue={false}
      orange={false}
      white={false}
      greenValue="Yes"
      redValue="No"
      greenLabel="Yes"
      redLabel="No"
    />
  );
};

const makeDefaults = object =>
  Object.fromEntries(Object.entries(object).map(([k, v]) => [k, '']));

// *************************************
// FORM STARTS HERE ********************
// *************************************
const RAMBronzeInspectionForm = props => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [coolingSystemValues, setCoolingSystemValues] = useState(
    makeDefaults(coolingSystem),
  );
  const [bodyValues, setBodyValues] = useState(makeDefaults(body));

  const [fuelSystemValues, setFuelSystemValues] = useState(
    makeDefaults(fuelSystem),
  );
  const [brakesValues, setBrakesValues] = useState(makeDefaults(brakes));
  const [wheelsValues, setWheelsValues] = useState(makeDefaults(wheelsTires));
  const [drivelineValues, setDrivelineValues] = useState(
    makeDefaults(driveline),
  );
  const [steeringValues, setSteeringValues] = useState(
    makeDefaults(steeringSuspension),
  );
  const [electricalValues, setElectricalValues] = useState(
    makeDefaults(electricalSystem),
  );
  const [miscValues, setMiscValues] = useState(makeDefaults(misc));
  const [lienValues, setLienValues] = useState(makeDefaults(liens));
  const [oopValues, setOopValues] = useState(makeDefaults(oop));
  const [icbcValues, setICBCValues] = useState(makeDefaults(icbc));

  const [aircaredDate, setAircaredDate] = useState(null);

  const checkHandler = setValues => e => {
    const { name, value, checked } = e.target;
    setValues(prevValues => ({ ...prevValues, [name]: checked ? value : '' }));
  };

  const handleBrakes = e => {
    const { name, value, checked } = e.target;
    setBrakesValues(prevBrakesValues => ({
      ...prevBrakesValues,
      [name]: checked ? value : '',
    }));
  };

  const handleWheels = e => {
    const { name, value, checked } = e.target;
    setWheelsValues(prevWheelsValues => ({
      ...prevWheelsValues,
      [name]: checked ? value : '',
    }));
  };

  const handleLiens = e => {
    const { name, value, checked } = e.target;
    setLienValues(prevLiensValues => ({
      ...prevLiensValues,
      [name]: checked ? value : '',
    }));
  };

  const handleICBC = e => {
    const { name, value, checked } = e.target;
    setICBCValues(prevICBCValues => ({
      ...prevICBCValues,
      [name]: checked ? value : '',
    }));
  };

  const handleOOP = e => {
    const { name, value, checked } = e.target;
    setOopValues(prevOopValues => ({
      ...prevOopValues,
      [name]: checked ? value : '',
    }));
  };

  const [consent, setConsent] = useState(false);
  const handleConsent = () => {
    setConsent(consent => !consent);
  };

  const [confirm, setConfirm] = useState(false);
  const handleConfirm = () => {
    setConfirm(confirm => !confirm);
  };

  const wholeForm = useRef(null);

  const handleSubmit = e => {
    e.preventDefault();

    if (!consent) {
      enqueueSnackbar(`Please check 'I agree' and then submit the inspection!`);
      return;
    }

    const values = {
      ...coolingSystemValues,
      ...bodyValues,
      ...fuelSystemValues,
      ...brakesValues,
      ...wheelsValues,
      ...drivelineValues,
      ...steeringValues,
      ...electricalValues,
      ...miscValues,
    };

    const form_data = {
      ram: values,
      amvic_number: wholeForm.current['amvic_number'].value,
      front_brakes: wholeForm.current['front_brakes'].value,
      back_brakes: wholeForm.current['back_brakes'].value,
      lf_tread: wholeForm.current['lf_tread'].value,
      rf_tread: wholeForm.current['rf_tread'].value,
      lr_tread: wholeForm.current['lr_tread'].value,
      rr_tread: wholeForm.current['rr_tread'].value,
      fluid_levels: wholeForm.current['fluid_levels'].value,
      liens_cleared: lienValues,
      icbc_check: icbcValues,
      oop_check: oopValues,
      aircared_date: aircaredDate,
    };

    props.formData(form_data);
    props.formClose();
  };

  const GreenButton = withStyles({
    root: {
      color: '#fff',
      backgroundColor: '#198754',
      '&:hover': {
        backgroundColor: '#157347',
      },
    },
  })(Button);

  const wheelsTextFields = ['ram19', 'ram20', 'ram21', 'ram22'];

  return (
    <form onSubmit={handleSubmit} ref={wholeForm}>
      {/* COOLING SYSTEM */}
      <FormAccordion
        title="COOLING SYSTEM"
        object={coolingSystem}
        valueGroup={coolingSystemValues}
        handleGroup={checkHandler(setCoolingSystemValues)}
        CheckboxGroup={CheckboxGroup}
      />
      {/* BODY */}
      <FormAccordion
        title="BODY"
        object={body}
        valueGroup={bodyValues}
        handleGroup={checkHandler(setBodyValues)}
        CheckboxGroup={CheckboxGroup}
      />
      {/* FUEL SYSTEM */}
      <FormAccordion
        title="FUEL SYSTEM"
        object={fuelSystem}
        valueGroup={fuelSystemValues}
        handleGroup={checkHandler(setFuelSystemValues)}
        CheckboxGroup={CheckboxGroup}
      />
      {/* BRAKES */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>BRAKES</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center" spacing={1}>
            {Object.entries(brakes).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={name === 'ram15' || name === 'ram16' ? 3 : 5}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  {(name === 'ram15' || name === 'ram16') && (
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        type="number"
                        name={name === 'ram15' ? 'front_brakes' : 'back_brakes'}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              className={classes.adornment}
                            >
                              mm
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{ min: 0 }}
                        className={classes.numInput}
                        style={{ maxWidth: '80px' }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={7}>
                    <CheckboxGroup
                      valueGroup={brakesValues}
                      handleGroup={handleBrakes}
                      name={name}
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      {/* WHEELS & TIRES */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>WHEELS & TIRES</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center" spacing={1}>
            {Object.entries(wheelsTires).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={wheelsTextFields.includes(name) ? 3 : 5}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  {wheelsTextFields.includes(name) && (
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        name={
                          name === 'ram19'
                            ? 'lf_tread'
                            : name === 'ram20'
                            ? 'rf_tread'
                            : name === 'ram21'
                            ? 'lr_tread'
                            : 'rr_tread'
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              className={classes.adornment}
                            >
                              /32"
                            </InputAdornment>
                          ),
                        }}
                        type="number"
                        inputProps={{ min: 0 }}
                        className={classes.numInput}
                        style={{ minWidth: '80px' }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={7}>
                    <CheckboxGroup
                      valueGroup={wheelsValues}
                      handleGroup={handleWheels}
                      name={name}
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      {/* DRIVELINE */}
      <FormAccordion
        title="DRIVELINE"
        object={driveline}
        valueGroup={drivelineValues}
        handleGroup={checkHandler(setDrivelineValues)}
        CheckboxGroup={CheckboxGroup}
      />
      {/* STEERING & SUSPENSION */}
      <FormAccordion
        title="STEERING & SUSPENSION"
        object={steeringSuspension}
        valueGroup={steeringValues}
        handleGroup={checkHandler(setSteeringValues)}
        CheckboxGroup={CheckboxGroup}
      />
      {/* ELECTRICAL */}
      <FormAccordion
        title="ELECTRICAL"
        object={electricalSystem}
        valueGroup={electricalValues}
        handleGroup={checkHandler(setElectricalValues)}
        CheckboxGroup={CheckboxGroup}
      />
      {/* MISC */}
      <FormAccordion
        title="MISCELLANEOUS"
        object={misc}
        valueGroup={miscValues}
        handleGroup={checkHandler(setMiscValues)}
        CheckboxGroup={CheckboxGroup}
      />
      {/* OTHER */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>OTHER</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs={12} sm={6}>
              All fluid levels checked - added as required. Lubrication - oil
              and filter change?
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth name="fluid_levels" />
            </Grid>
            {/* LIENS */}
            {Object.entries(liens).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={4}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={8}>
                    <OtherCheckboxes
                      valueGroup={lienValues}
                      handleGroup={handleLiens}
                      name={name}
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
            {/* ICBC */}
            {Object.entries(icbc).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={4}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={8}>
                    <OtherCheckboxes
                      valueGroup={icbcValues}
                      handleGroup={handleICBC}
                      name={name}
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
            {/* Out of Province (OOP) */}
            {Object.entries(oop).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={4}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={8}>
                    <OtherCheckboxes
                      valueGroup={oopValues}
                      handleGroup={handleOOP}
                      name={name}
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
            <Grid item xs={4}>
              Aircared (date)
            </Grid>
            <Grid item xs={8}>
              <DatePicker
                disableToolbar
                autoOk
                clearable
                format="yyyy/MM/DD"
                margin="normal"
                id="aircared-date-picker"
                onChange={date => setAircaredDate(date)}
                value={aircaredDate}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      {/* SIGN OFF */}
      <Grid
        container
        spacing={1}
        alignItems="center"
        style={{ margin: '10px' }}
      >
        <Grid item xs={12} sm={9}>
          <Typography style={{ fontWeight: 'bold' }}>
            I verify the contents of this document are true and correct
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControlLabel
            control={<Checkbox />}
            label="I agree"
            onChange={handleConsent}
            value={consent}
          />
          <br />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Technician Name"
            value={props.user.display_name}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Tech #"
            value={props.user.goUserProfile.profile.sales_licence}
            fullWidth
            name={'amvic_number'}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Date"
            value={new Date().toLocaleString()}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button
            type="button"
            variant="contained"
            style={{ marginRight: '15px' }}
            onClick={handleConfirm}
            color="default"
          >
            Cancel
          </Button>
          <Dialog
            open={confirm}
            onClose={handleConfirm}
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Current inspection will be closed and unsaved data will be gone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleConfirm}>Cancel</Button>
              <Button onClick={props.formClose} color="secondary">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          <GreenButton type="submit" variant="contained">
            Save
          </GreenButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default RAMBronzeInspectionForm;
