import { useMutation } from '@apollo/react-hooks';
import moment from 'moment-timezone';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Autocomplete from '@mui/material/Autocomplete';
import { DateTimePicker } from '@mui/x-date-pickers';

const UPDATE_APPOINTMENT = gql`
  mutation updateDeliveryAppointment(
    $_id: ID!
    $input: UpdateAppointmentInput!
  ) {
    updateAppointment(_id: $_id, input: $input) {
      _id
      start_date
      opportunity_id
      status
      type_id
      subject
      type_name
      status_name
      created
      created_by_username
      attendee_usernames
    }
  }
`;

const CREATE_DELIVERY_APPOINTMENT = gql`
  mutation createDeliveryAppointment($input: AppointmentInput!) {
    createAppointment(input: $input) {
      _id
      start_date
      opportunity_id
      status
      type_id
      subject
      type_name
      status_name
      created
      created_by_username
      attendee_usernames
    }
  }
`;

const SET_DELIVERY_APPOINTMENT_ATTENDEES = gql`
  mutation setDeliveryAttendees($_id: ID!, $usernames: [String]!) {
    setAppointmentAttendees(_id: $_id, usernames: $usernames) {
      _id
      start_date
      opportunity_id
      status
      type_id
      subject
      type_name
      status_name
      created
      created_by_username
      attendee_usernames
    }
  }
`;

const useStyles = makeStyles(theme => ({
  div: {
    padding: '0px 1rem 1rem',
    display: 'flex',
    flexDirection: 'row',
  },
  textField: {
    maxWidth: '60%',
    width: '100%',
    display: 'inline-grid',
    margin: '8px 0px',
  },
  dialogTitle: {
    padding: '16px 38px',
  },
  closeButton: {
    fontWeight: '600',
    fontSize: 16,
    color: '#24a0ed',
  },
}));

const DealLogDelivery = ({
  opportunity,
  handleClose,
  delivery,
  setDelivery,
  user,
  setUser,
  userData,
}) => {
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const { enqueueSnackbar } = useSnackbar();
  const deal_number = opportunity.dms_deal?.deal_number || 'No deal found';
  const classes = useStyles();
  const desktop = useMediaQuery(theme => theme.breakpoints.up('sm'));

  const [updateDelivery] = useMutation(UPDATE_APPOINTMENT, {
    onCompleted: data => {
      if (data) {
        setDelivery(data.updateAppointment);
        enqueueSnackbar(`Successfully updated delivery time`, {
          variant: 'success',
        });
      }
    },
    onError: e =>
      enqueueSnackbar(`Error in updating delivery time`, {
        variant: 'error',
      }),
  });

  const [updateAttendees] = useMutation(SET_DELIVERY_APPOINTMENT_ATTENDEES, {
    onCompleted: data => {
      if (data) {
        setUser(
          userData.users.find(
            user =>
              user.username ===
              data.setAppointmentAttendees?.attendee_usernames[0],
          ),
        );
        enqueueSnackbar(`Successfully updated delivery attendees`, {
          variant: 'success',
        });
      }
    },
    onError: e =>
      enqueueSnackbar(`Error in updating delivery attendees`, {
        variant: 'error',
      }),
  });

  const [createDelivery] = useMutation(CREATE_DELIVERY_APPOINTMENT, {
    onCompleted: data => {
      setDelivery(data.createAppointment);
      setUser(
        userData.users.find(
          user =>
            user.username === data.createAppointment?.attendee_usernames[0],
        ),
      );
      enqueueSnackbar(`Successfully created delivery`, {
        variant: 'success',
      });
    },
    onError: e =>
      enqueueSnackbar(`Error in creating delivery`, {
        variant: 'error',
      }),
  });

  const { control, handleSubmit, formState } = useForm({
    defaultValues: { delivery, created_by: null },
  });

  const { dirtyFields } = formState;

  const onSubmit = formValue => {
    if (delivery) {
      if (dirtyFields?.created_by) {
        updateAttendees({
          variables: {
            _id: delivery._id,
            usernames: [formValue?.created_by?.username],
          },
        });
      }
      if (dirtyFields?.start_date) {
        updateDelivery({
          variables: {
            _id: delivery._id,
            input: {
              start_date: formValue.start_date,
              attendee_usernames: [formValue?.created_by?.username],
            },
          },
        });
      }
    } else {
      createDelivery({
        variables: {
          input: {
            attendee_usernames: [formValue?.created_by?.username],
            customer_id: opportunity.customer_id,
            dealer_id: opportunity.dealer_id,
            opportunity_id: opportunity._id,
            start_date: formValue.start_date,
            subject: 'Delivery',
            type_id: 4,
          },
        },
      });
    }

    handleClose();
  };

  return (
    <>
      <DialogTitle className={classes.dialogTitle}>
        <h2>
          Schedule Delivery for Deal #{deal_number} -{' '}
          {opportunity.customer_name}
        </h2>
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <div className={isDesktop ? classes.div : ''}>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              spacing={2}
            >
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Controller
                  render={({ field: { ref, onChange, ...field } }) => (
                    <Autocomplete
                      options={userData?.users}
                      forcePopupIcon={true}
                      getOptionLabel={option => {
                        return String(option.display_name);
                      }}
                      renderOption={(props, option) => (
                        <li {...props}>
                          <span value={option.username}>
                            {option.display_name}
                          </span>
                        </li>
                      )}
                      renderInput={params => (
                        <TextField
                          {...params}
                          style={{ width: desktop ? 300 : 250 }}
                          label="Username"
                          required
                        />
                      )}
                      onChange={(e, data) => onChange(data)}
                      {...field}
                    />
                  )}
                  onChange={([, data]) => data}
                  name="created_by"
                  defaultValue={
                    user ||
                    userData.users.find(
                      user => user.username === delivery?.attendee_usernames[0],
                    )
                  }
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Controller
                  render={({ field: { ref, ...field } }) => (
                    <DateTimePicker
                      {...field}
                      autoOk
                      minDate={moment()}
                      variant="inline"
                      format="YYYY-MM-DD hh:mm A"
                      required
                    />
                  )}
                  defaultValue={
                    delivery?.start_date && moment(delivery?.start_date)
                  }
                  control={control}
                  name="start_date"
                  placeholder="Date"
                  style={{ width: desktop ? 300 : 250 }}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className={classes.closeButton}>
            Cancel
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            style={{ backgroundColor: '#74B72E' }}
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </>
  );
};

export default DealLogDelivery;
