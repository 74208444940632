import React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { formatOdometer } from 'utils';

const LeadCustomerVehicleCard = ({ lead, classes }) => {
  return (
    <Card style={{ height: '100%' }}>
      <CardContent>
        <Typography style={{ padding: '0 0 .5rem 1rem', fontWeight: '700' }}>
          <i className="fas fa-car-alt" /> Customer Vehicle Details
        </Typography>
        <TableContainer>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <colgroup>
              <col style={{ width: '50%' }} />
              <col style={{ width: '50%' }} />
            </colgroup>
            <TableBody>
              <TableRow>
                <TableCell>Vehicle</TableCell>
                <TableCell>
                  <b>
                    {lead.form_data?.customer_vehicle_year ||
                    lead.form_data?.customer_vehicle_make ||
                    lead.form_data?.customer_vehicle_model
                      ? lead.form_data?.customer_vehicle_year +
                        ' ' +
                        lead.form_data?.customer_vehicle_make +
                        ' ' +
                        lead.form_data?.customer_vehicle_model
                      : '-'}
                  </b>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Vehicle Trim</TableCell>
                <TableCell>
                  <b>{lead.form_data?.customer_vehicle_trim || '-'}</b>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Amount Owning</TableCell>
                <TableCell>
                  <b>{lead.form_data?.customer_vehicle_amount_owing || '-'}</b>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Book Value</TableCell>
                <TableCell>
                  <b>{lead.form_data?.customer_vehicle_book_value || '-'}</b>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Years Owned</TableCell>
                <TableCell>
                  <b>{lead.form_data?.customer_vehicle_years_owned || '-'}</b>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Odometer</TableCell>
                <TableCell>
                  <b>
                    {lead.form_data?.customer_vehicle_odometer !== null
                      ? formatOdometer(
                          lead.form_data?.customer_vehicle_odometer,
                        )
                      : '-'}
                  </b>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Payment Frequency</TableCell>
                <TableCell>
                  <b>
                    {lead.form_data?.customer_vehicle_payment_frequency || '-'}
                  </b>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Payment Amount</TableCell>
                <TableCell>
                  <b>
                    {lead.form_data?.customer_vehicle_payment_amount || '-'}
                  </b>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Payment Type</TableCell>
                <TableCell>
                  <b>{lead.form_data?.customer_vehicle_payment_type || '-'}</b>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default LeadCustomerVehicleCard;
