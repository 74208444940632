export const PAYMENT_TYPE = {
  FINANCE: 'finance',
  LEASE: 'lease',
  CASH: 'cash',
};

export const PAYMENT_TYPES = [
  PAYMENT_TYPE.FINANCE,
  PAYMENT_TYPE.LEASE,
  PAYMENT_TYPE.CASH,
];

export const FREQUENCY_ABBREVIATIONS = {
  12: '/mo',
  24: '(sm)',
  26: '(bw)',
  52: '/wk',
};

export const FREQUENCY_NAMES = {
  12: 'Monthly',
  24: 'Semi-monthly',
  26: 'Bi-weekly',
  52: 'Weekly',
};

export const FREQUENCY_OPTIONS = [
  [12, 'Monthly'],
  [24, 'Semi-monthly'],
  [26, 'Bi-weekly'],
  [52, 'Weekly'],
];

export const TERM_OPTIONS = [
  [12, '12'],
  [18, '18'],
  [24, '24'],
  [30, '30'],
  [36, '36'],
  [39, '39'],
  [40, '40'],
  [42, '42'],
  [48, '48'],
  [52, '52'],
  [54, '54'],
  [60, '60'],
  [64, '64'],
  [66, '66'],
  [72, '72'],
  [78, '78'],
  [84, '84'],
  [96, '96'],
];

export const B_WAIVER_DEALERS = [
  133, 331, 270, 2987, 71, 5957, 5956, 272, 5965, 70,
]; // JLRC, JLRE, Honda RD, Porsche Edmonton, Team Ford, Airdrie Honda, T&T Honda, MGM Ford Lincoln, Audi Royal Oak, Southtown Chrysler

export const NON_EXPORT_DEALERS = [133, 331, 5965]; // Jaguar Land Rover Calgary & Edmonton, Audi Royal Oak

export const NO_DISCOUNTS_DEALERS = [5950]; // Go Auto Kelowna - no discounts

export const DELIVERY_CHECKLIST_DEALERS = [5950, 5953, 133, 272]; // Go Auto Kelowna, Go Auto Sherwood Park, JLRC, MGM

export const DELIVERY_DUE_BILL_DEALERS = [5960]; // Go Richmond Chrysler

export const FUTURE_TRADE_DISCLOSURE_DEALERS = [133, 5965]; // Jaguar Land Rover Calgary, Audi Royal Oak

export const MGMFL_DEPOSIT_AGREEMENT_DEALERS = [272]; // MGM Ford Lincoln

export const DEPOSIT_AGREEMENT_DEALERS = [133, 5965]; // Jaguar Land Rover Calgary, Audi Royal Oak

export const PROMISES_SHEET_DEALERS = [331]; // Jaguar Land Rover Edmonton

export const PUBLISHING_STATUS = {
  NOT_STARTED: 'not-started',
  STARTED: 'waiting-docusign-start',
  STARTED_INITIALED: 'inspections-signed',
  ACCEPTED: 'accepted',
  ENVELOPE_CREATED: 'docs-created',
  WAITING_FOR_DOCUSIGN_INITIAL_CONFIRM: 'waiting-docusign-initial',
  INITIALED: 'initialed',
  MANAGER_APPROVED: 'manager-approved',
  WAITING_FOR_DOCUSIGN_SIGNING_CONFIRM: 'waiting-docusign-sign',
  SIGNED: 'signed',
  WAITING_FOR_SECOND_SIGNATURE: 'waiting-for-second-signature',
  MANAGER_SIGNED: 'manager-signed',
};

export const PUBLISHING_STATUS_TEXT = {
  [PUBLISHING_STATUS.NOT_STARTED]: 'Waiting for customer to accept/decline',
  [PUBLISHING_STATUS.STARTED]:
    'Customer has begun the signing process. Waiting for inspections to be signed',
  [PUBLISHING_STATUS.STARTED_INITIALED]:
    'Customer has signed inspections. Waiting for Customer to select a payment option',
  [PUBLISHING_STATUS.ACCEPTED]:
    'Customer accepted.  Waiting for initial on payment option.',
  [PUBLISHING_STATUS.ENVELOPE_CREATED]: 'Documents created',
  [PUBLISHING_STATUS.WAITING_FOR_DOCUSIGN_INITIAL_CONFIRM]:
    'Waiting for Docusign initial confirmation',
  [PUBLISHING_STATUS.INITIALED]:
    'Customer initialed payment option.  Waiting for sales manager approval',
  [PUBLISHING_STATUS.MANAGER_APPROVED]:
    'Waiting for customer to sign documents',
  [PUBLISHING_STATUS.WAITING_FOR_DOCUSIGN_SIGNING_CONFIRM]:
    'Waiting for Docusign signing confirmation',
  [PUBLISHING_STATUS.SIGNED]: 'Waiting for sales manager final signing',
  [PUBLISHING_STATUS.WAITING_FOR_SECOND_SIGNATURE]:
    'Waiting for second customer to sign documents',
  [PUBLISHING_STATUS.MANAGER_SIGNED]: 'Signing completed',
};

export const INSPECTION_TYPES = {
  amvic: 'AMVIC',
  fcpp: 'FCPP',
};

export const INSPECTION_TYPE_OPTIONS = Object.entries(INSPECTION_TYPES).map(
  ([k, v]) => ({ name: v, value: k }),
);
