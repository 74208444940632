import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';

import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { DatePicker } from '@mui/x-date-pickers';

import CheckboxControl from 'components/MaterialUI/CheckboxControl';
import ConfirmDialog from 'components/MaterialUI/ConfirmDialog';
import SelectControl from 'components/MaterialUI/SelectControl2';
import TextFieldControl from 'components/MaterialUI/TextFieldControl';
import { useUserContext } from 'components/MaterialUI/UserContext';

import { formatPhoneNumber } from 'utils';

import {
  INSURANCE_PROVIDERS,
  PREFERRED_LANGUAGE,
  PROVINCES,
} from '../constant';
import AddressAutocomplete from './AddressAutocomplete';
import Identities from './Identities';
import { Regexps, Role } from 'constants.js';

const UPDATE_CUSTOMER = gql`
  mutation updateCustomerDetails($id: ID!, $input: UpdateCustomerInput!) {
    updateCustomer(_id: $id, input: $input) {
      _id
      address
      cell_phone
      company_name
      goauto_employee
      city
      emails {
        email
      }
      first_name
      fullname
      nickname
      preferred_language
      home_phone
      last_name
      postal_code
      province
      salutation
      work_phone
      phone
      date_of_birth
      secondary_address
      secondary_city
      secondary_province
      secondary_postal_code
    }
  }
`;

const ATTACH_CUSTOMER_INSURANCE = gql`
  mutation attachCustomerInsurance(
    $customer_id: ID!
    $input: InsurancePolicyInput!
  ) {
    attachCustomerInsurance(customer_id: $customer_id, input: $input) {
      _id
      insurance {
        _id
        expiry_date
        name
      }
    }
  }
`;

const UPDATE_CUSTOMER_INSURANCE = gql`
  mutation updateCustomerInsurance(
    $customer_id: ID!
    $insurance_id: ID!
    $input: InsurancePolicyInput!
  ) {
    updateCustomerInsurance(
      customer_id: $customer_id
      insurance_id: $insurance_id
      input: $input
    ) {
      _id
      insurance {
        _id
        expiry_date
        name
      }
    }
  }
`;

const DETACH_CUSTOMER_INSURANCE = gql`
  mutation detachCustomerInsurance($customer_id: ID!, $insurance_id: ID!) {
    detachCustomerInsurance(
      customer_id: $customer_id
      insurance_id: $insurance_id
    ) {
      _id
      insurance {
        _id
        expiry_date
        name
      }
    }
  }
`;

const REMOVE_IDENTITY = gql`
  mutation removeIdentity($identity: CustomerIdentityInput) {
    removeCustomerIdentity(identity: $identity) {
      _id
      ...IdentitiesFragment
    }
  }
  ${Identities.fragments.customer}
`;

const ADD_IDENTITY = gql`
  mutation addIdentity($identity: CustomerIdentityInput) {
    addCustomerIdentity(identity: $identity) {
      _id
      ...IdentitiesFragment
    }
  }
  ${Identities.fragments.customer}
`;

const UPDATE_GOCARD_ACCOUNTS = gql`
  mutation updateGoCardAccounts(
    $filters: [QueryFilter]
    $input: UpdateBulkAccountsInput!
  ) {
    gocard {
      updateBulkAccounts(filters: $filters, input: $input) {
        cardId
        isWholesaler
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({ textField: { width: '250px' } }));

const EditCustomerDetails = ({ customer, extraCompletionHandler, refetch }) => {
  const classes = useStyles();
  const [emails, setEmails] = useState(
    (customer?.emails || []).map(({ __typename, ...keepAttrs }) => keepAttrs),
  );
  const [emailText, setEmailText] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [open, setOpen] = useState(false);
  const [verifiedAddress, setVerifiedAddress] = useState(
    customer.verified_address || false,
  );
  const [altVerifiedAddress, setAltVerifiedAddress] = useState(
    customer.secondary_verified_address || false,
  );

  const [updateIdentityEmailDialog, setUpdateIdentityEmailDialog] =
    useState(false);
  let updateIdentityEmailConfirmation = false;

  const [updateIdentitySMSDialog, setUpdateIdentitySMSDialog] = useState(false);
  let updateIdentitySMSConfirmation = false;

  const [updateCustomer] = useMutation(UPDATE_CUSTOMER);
  const [attachInsurance] = useMutation(ATTACH_CUSTOMER_INSURANCE);
  const [updateInsurance] = useMutation(UPDATE_CUSTOMER_INSURANCE);
  const [detachInsurance] = useMutation(DETACH_CUSTOMER_INSURANCE);
  const [updateAccounts] = useMutation(UPDATE_GOCARD_ACCOUNTS);
  const desktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useUserContext() || {};
  const isHidden = ![Role.ORGANIZATION_ADMIN, Role.GENERAL_MANAGER].includes(
    currentUser.role,
  );

  // not sure if needed?
  const defaultValues = Object.assign({}, customer, {
    nickname: customer.nickname,
    preferred_language: PREFERRED_LANGUAGE[0].value,
    is_wholesaler: customer.gocards?.some(
      gocard => gocard.accountV2?.isWholesaler,
    ),
    // address: '',
  });

  const { control, handleSubmit, formState, setValue, register } = useForm({
    defaultValues,
  });
  const { dirtyFields } = formState;
  const shouldDirty = true; //convenience
  const address = useWatch({ control, name: 'address' });
  const secondary_address = useWatch({ control, name: 'secondary_address' });
  register('emails');

  const [removeIdentity] = useMutation(REMOVE_IDENTITY, {
    onCompleted: data => {
      enqueueSnackbar('Identity Removed', {
        variant: 'success',
      });
    },
    onError: e =>
      enqueueSnackbar(`Unable to remove identity for this profile.`, {
        variant: 'error',
      }),
  });

  const [addIdentity] = useMutation(ADD_IDENTITY, {
    onCompleted: data => {
      enqueueSnackbar('Identity added', {
        variant: 'success',
      });
      refetch();
    },
    onError: e =>
      enqueueSnackbar(`Unable to add identity to this profile.`, {
        variant: 'error',
      }),
  });

  const updateIdentities = (new_value, field) => {
    const emails = customer?.emails || [];
    const hasSMSIdentity = customer.identities.some(
      identity => identity.identity_type === 'sms',
    );
    customer.identities.forEach(identity => {
      let update = null;
      if (field?.includes('phone') && identity.identity_type === 'sms') {
        if (
          formatPhoneNumber(identity.identity_value) !==
          formatPhoneNumber(new_value)
        ) {
          update = 'sms';
        }
      } else if (
        field === 'emails' &&
        identity.identity_type === 'email' &&
        !isEmpty(emails) &&
        identity.identity_value !== new_value[0].email
      ) {
        update = 'email';
      }
      if (update) {
        removeIdentity({
          variables: {
            identity: {
              customer_id: customer._id,
              identity_type: update,
              identity_value: identity.identity_value,
            },
          },
        });
        // we only need to add identity when it's SMS,
        // because the updateCustomerMutation will add the email identity in CRM
        if (update === 'sms' && !isEmpty(new_value)) {
          addIdentity({
            variables: {
              identity: {
                customer_id: customer._id,
                identity_type: 'sms',
                identity_value: formatPhoneNumber(new_value),
              },
            },
          });
        }
      }
    });
    // If there is no SMS identity on a customer we need to create one.
    if (!hasSMSIdentity && field?.includes('phone') && !isEmpty(new_value)) {
      addIdentity({
        variables: {
          identity: {
            customer_id: customer._id,
            identity_type: 'sms',
            identity_value: formatPhoneNumber(new_value),
          },
        },
      });
    }
  };

  const handleEmailText = event => {
    setEmailText(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (extraCompletionHandler) extraCompletionHandler();
  };

  const handleKeyDown = (event, callback) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      callback(onSubmit);
    }
  };

  const formatter = (input, key) => {
    if (key.includes('date')) {
      if (typeof input === 'string' || input instanceof String) {
        return moment(input).utc().format('YYYY-MM-DDThh:mm:ssZ');
      } else {
        try {
          return input.utc().format('YYYY-MM-DDThh:mm:ssZ');
        } catch (err) {
          return null;
        }
      }
    } else if (key.includes('emails')) {
      // return the input with the email values trimmed of leading/trailing whitespaces and tabs
      return input.map(email => {
        return { email: email.email?.trim() };
      });
    } else {
      return input;
    }
  };

  // Once canada post auto complete returns the address we need to parse it
  // and return the correct values to the correct fields
  const resolveAddress = canadaPostAddress => {
    const splitAddress = canadaPostAddress?.split(', ');
    if (splitAddress && splitAddress.length === 4) {
      const [_address, city, province, postalCode] = splitAddress;
      return {
        address: _address,
        city,
        province,
        postalCode,
      };
    } else return null;
  };

  const updateGoCardPayload = isWholesaler => {
    const filters = [
      {
        field: 'cardId',
        model: 'GoCard',
        op: 'in',
        value: customer.gocards.map(x => x.accountV2.cardId),
      },
    ];
    updateAccounts({
      variables: {
        filters,
        input: {
          isWholesaler,
        },
      },
    });
  };

  useEffect(() => {
    const resolved = resolveAddress(address);
    if (resolved) {
      setValue('address', resolved.address);
      setValue('city', resolved.city, { shouldDirty });
      setValue('postal_code', resolved.postalCode, { shouldDirty });
      setValue('province', resolved.province, { shouldDirty });
      setVerifiedAddress(true);
    }

    if (address === '') {
      // TODO Fix this so we don't need the ignore
      setValue('city', '');
      setValue('postal_code', '');
      setValue('province', '');
      setVerifiedAddress(false);
    }
  }, [address, setValue, shouldDirty]);

  useEffect(() => {
    const resolved = resolveAddress(secondary_address);
    if (resolved) {
      setValue('secondary_address', resolved.address);
      setValue('secondary_city', resolved.city, { shouldDirty });
      setValue('secondary_postal_code', resolved.postalCode, {
        shouldDirty: true,
      });
      setValue('secondary_province', resolved.province, { shouldDirty });
      setAltVerifiedAddress(true);
    }
    if (secondary_address === '') {
      setValue('secondary_city', '');
      setValue('secondary_postal_code', '');
      setValue('secondary_province', '');
      setAltVerifiedAddress(false);
    }
  }, [secondary_address, setValue, shouldDirty]);

  const onSubmit = formValue => {
    const updateCustomerPayload = {
      verified_address: verifiedAddress,
      secondary_verified_address: altVerifiedAddress,
    };
    const updateInsurancePayload = {};
    if (isEmpty(dirtyFields)) {
      handleClose();
      return;
    }

    Object.keys(dirtyFields).forEach(field => {
      if (field === 'insurance') {
        Object.keys(dirtyFields['insurance'][0]).forEach(field => {
          updateInsurancePayload[field] = formatter(
            formValue['insurance'][0][field],
            field,
          );
        });
      } else {
        if (
          (field === 'emails' ||
            field === 'phone' ||
            field === 'cell_phone' ||
            field === 'home_phone') &&
          (updateIdentityEmailConfirmation || updateIdentitySMSConfirmation)
        ) {
          updateIdentities(formValue[field], field);
        }
        if (field === 'is_wholesaler') {
          updateGoCardPayload(formValue[field]);
        } else {
          updateCustomerPayload[field] = formatter(formValue[field], field);
        }
      }
    });

    const updateInsuranceDetails = () => {
      if (isEmpty(updateInsurancePayload)) {
        return Promise.resolve();
      }
      if (!customer?.insurance?.length) {
        return attachInsurance({
          variables: {
            customer_id: customer._id,
            input: updateInsurancePayload,
          },
        });
      } else if (
        Object.keys(dirtyFields['insurance'][0]).every(
          key => !formValue['insurance'][0][key],
        ) &&
        customer.insurance.length
      ) {
        return detachInsurance({
          variables: {
            customer_id: customer._id,
            insurance_id: customer.insurance[0]._id,
          },
        });
      } else {
        return updateInsurance({
          variables: {
            customer_id: customer._id,
            insurance_id: customer.insurance[0]._id,
            input: updateInsurancePayload,
          },
        });
      }
    };

    const updateCustomerInformation = () => {
      if (isEmpty(updateCustomerPayload)) {
        return Promise.resolve();
      }
      return updateCustomer({
        variables: {
          id: customer._id,
          input: updateCustomerPayload,
        },
      });
    };

    Promise.all([updateCustomerInformation(), updateInsuranceDetails()])
      .then(values => {
        // eslint-disable-next-line no-unused-vars
        let updatedCustomer;
        values.forEach(obj => {
          if (obj) {
            [
              'detachCustomerInsurance',
              'updateCustomerInsurance',
              'attachCustomerInsurance',
              'updateCustomer',
            ].forEach(updateField => {
              updatedCustomer = { ...customer, ...obj.data[updateField] };
            });
          }
        });
        enqueueSnackbar(`Successfully updated ${customer.fullname}`, {
          variant: 'success',
        });
        if (refetch) refetch();
      })
      .catch(e => {
        enqueueSnackbar(`Error in updating ${customer.fullname}`, {
          variant: 'error',
        });
      })
      .finally(handleClose);
  };

  const updateEmail = () => {
    if (Regexps.EmailFormat.test(emailText)) {
      let tempEmail = {
        consent: 'Opted Out',
        email: emailText,
        primary: false,
      };
      setValue('emails', [tempEmail, ...emails], {
        shouldDirty,
      });
      setEmails(prev => [tempEmail, ...prev]);
      setEmailText('');
    } else {
      setEmailError(true);
    }
  };

  const deleteEmail = email => {
    let tempEmail = emails.filter(x => x.email !== email.email);
    setValue('emails', tempEmail, { shouldDirty });
    setEmails(tempEmail);
  };

  // Base props for TextFields
  const textField = {
    control,
    className: classes.textField,
    InputLabelProps: { shrink: true },
    // TODO: Remove default label to shrink true?
    // Material Design says should be full size at first, then shrink.
  };

  // Rules for phone TextField
  const phoneRules = {
    rules: {
      pattern: {
        value: Regexps.PhoneFormat.ALL,
        message: 'Invalid phone number',
      },
    },
  };

  return (
    <div>
      <CreateIcon
        variant="outlined"
        color="primary"
        onClick={handleClickOpen}
      />
      <ConfirmDialog
        abortText="No"
        confirmText="Yes"
        isOpen={Boolean(updateIdentityEmailDialog)}
        onClose={() => {
          setUpdateIdentityEmailDialog(false);
        }}
        onConfirm={() => {
          updateIdentityEmailConfirmation = true;
          handleSubmit(onSubmit)();
          setUpdateIdentityEmailDialog(false);
        }}
        text={`Would you like to update email in identities?`}
        style={{ zIndex: 15000 }}
      />
      <ConfirmDialog
        abortText="No"
        confirmText="Yes"
        isOpen={Boolean(updateIdentitySMSDialog)}
        onClose={() => {
          setUpdateIdentitySMSDialog(false);
        }}
        onConfirm={() => {
          updateIdentitySMSConfirmation = true;
          handleSubmit(onSubmit)();
          setUpdateIdentitySMSDialog(false);
        }}
        text={`Would you like to update SMS in identities?`}
        style={{ zIndex: 15000 }}
      />
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md">
        <DialogTitle id="form-dialog-title">Edit Customer Details</DialogTitle>
        <form
          onSubmit={{}}
          onKeyDown={e => {
            handleKeyDown(e, handleSubmit);
          }}
        >
          <DialogContent style={{ overflow: 'hidden' }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextFieldControl
                  {...textField}
                  label="First Name"
                  name="first_name"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextFieldControl
                  {...textField}
                  label="Last Name"
                  name="last_name"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextFieldControl
                  {...textField}
                  label="Alternate Name"
                  name="nickname"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectControl
                  {...textField}
                  label="Preferred Language"
                  name="preferred_language"
                  nullDisplay=""
                  options={PREFERRED_LANGUAGE}
                  optionNameKey="text"
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldControl
                  {...textField}
                  label="Company Name"
                  name="company_name"
                />
              </Grid>
              {!isHidden && (
                <Grid item xs={12}>
                  <Box margin={0}>
                    <CheckboxControl
                      label="Go Auto Employee"
                      name="goauto_employee"
                      control={control}
                    />
                    <Box margin={0}>
                      <CheckboxControl
                        label="Wholesaler"
                        name="is_wholesaler"
                        control={control}
                      />
                    </Box>
                  </Box>
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <InputLabel shrink>Email</InputLabel>
                <TextField
                  style={{ width: '250px' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          disabled={isEmpty(emailText)}
                          onClick={updateEmail}
                          size="large"
                        >
                          <AddIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  value={emailText}
                  onChange={handleEmailText}
                  error={emailError}
                  helperText={emailError ? 'Please enter a valid email' : ''}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography>Additional Emails</Typography>
                {emails.map(email => (
                  <Box key={email.email}>
                    {email.email}{' '}
                    <IconButton onClick={() => deleteEmail(email)} size="large">
                      <DeleteIcon style={{ color: 'red' }} />
                    </IconButton>
                  </Box>
                ))}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextFieldControl
                  {...textField}
                  label="Phone"
                  name="phone"
                  {...phoneRules}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextFieldControl
                  {...textField}
                  label="Home Phone"
                  name="home_phone"
                  {...phoneRules}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextFieldControl
                  {...textField}
                  label="Cell Phone"
                  name="cell_phone"
                  {...phoneRules}
                />
              </Grid>
              <Grid item xs={12} style={{ marginRight: '10px' }}>
                <Grid container direction="row">
                  <Grid item xs={verifiedAddress ? 11 : 12}>
                    <InputLabel shrink>Address</InputLabel>
                    <Controller
                      render={({ field: { ref, value, ...field } }) => (
                        <AddressAutocomplete
                          defaultAddress={value}
                          {...field}
                        />
                      )}
                      onChange={([, data]) => data} // ???
                      name="address"
                      control={control}
                      style={{ width: '100%' }}
                    />
                  </Grid>
                  {verifiedAddress && (
                    <Grid
                      item
                      xs={1}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: 'auto',
                        marginBottom: 'auto',
                        paddingLeft: '1rem',
                      }}
                    >
                      <Box>
                        <Tooltip title="Verified Address">
                          <VerifiedUserIcon style={{ color: '#4CAF50' }} />
                        </Tooltip>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextFieldControl {...textField} label="City" name="city" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectControl
                  {...textField}
                  label="Province"
                  name="province"
                  options={PROVINCES}
                  optionNameKey="text"
                  nullDisplay=""
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldControl
                  {...textField}
                  label="Postal Code"
                  name="postal_code"
                />
              </Grid>
              <Grid item xs={12} style={{ marginRight: '10px' }}>
                <Grid container direction="row">
                  <Grid item xs={altVerifiedAddress ? 11 : 12}>
                    <InputLabel shrink>Alternative Address</InputLabel>
                    <Controller
                      render={({ field: { ref, value, ...field } }) => (
                        <AddressAutocomplete
                          defaultAddress={value}
                          {...field}
                        />
                      )}
                      onChange={([, data]) => data}
                      name="secondary_address"
                      control={control}
                      style={{ width: '100%' }}
                    />
                  </Grid>
                  {altVerifiedAddress && (
                    <Grid
                      item
                      xs={1}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: 'auto',
                        marginBottom: 'auto',
                        paddingLeft: '1rem',
                      }}
                    >
                      <Box>
                        <Tooltip title="Verified Address">
                          <VerifiedUserIcon style={{ color: '#4CAF50' }} />
                        </Tooltip>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextFieldControl
                  {...textField}
                  label="Alt. City"
                  name="secondary_city"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectControl
                  {...textField}
                  label="Alt. Province"
                  name="secondary_province"
                  options={PROVINCES}
                  optionNameKey="text"
                  nullDisplay="" // TODO: this results in an icky short menu item, but that's the way it was....
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldControl
                  {...textField}
                  label="Postal Code"
                  name="secondary_postal_code"
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel shrink>Birthday</InputLabel>
                <Controller
                  control={control}
                  name="date_of_birth"
                  placeholder="Birthday"
                  render={({ field: { ref, value, ...field } }) => (
                    <DatePicker
                      autoOk
                      format="YYYY-MM-DD"
                      value={moment(value)}
                      variant="inline"
                      {...field}
                    />
                  )}
                  style={{ width: '250px' }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextFieldControl
                  control={control}
                  InputLabelProps={{ shrink: true }}
                  label="Drivers License #"
                  name="drivers_license"
                  style={desktop ? null : { width: 250 }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <SelectControl
                  control={control}
                  InputLabelProps={{ shrink: true }}
                  style={{ width: desktop ? 150 : 250 }}
                  label="Province"
                  name="drivers_license_province"
                  options={PROVINCES}
                  optionNameKey="text"
                  nullDisplay=""
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                style={{ padding: '12px 22px 12px 12px' }}
              >
                <InputLabel shrink>Expiry</InputLabel>
                <Controller
                  control={control}
                  name="drivers_license_expiry_date"
                  placeholder="Expiry"
                  render={({ field: { ref, value, ...field } }) => (
                    <DatePicker
                      autoOk
                      format="YYYY-MM-DD"
                      minDate={moment()}
                      value={moment(value)}
                      variant="inline"
                      {...field}
                    />
                  )}
                  style={{ width: desktop ? 'auto' : '250px' }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectControl
                  {...textField}
                  label="Insurance Company"
                  name="insurance.0.name"
                  options={INSURANCE_PROVIDERS.map(x => ({
                    value: x,
                    name: x,
                  }))}
                  noNull
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel shrink>Expiry</InputLabel>
                <Controller
                  render={({ field: { ref, value, ...field } }) => (
                    <DatePicker
                      autoOk
                      format="YYYY-MM-DD"
                      minDate={moment()}
                      value={moment(value)}
                      variant="inline"
                      {...field}
                    />
                  )}
                  control={control}
                  name="insurance.0.expiry_date"
                  placeholder="Expiry"
                  style={{ width: desktop ? 'auto' : '250px' }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button
              type="button"
              color="primary"
              variant="contained"
              style={{ backgroundColor: '#4CAF50' }}
              onClick={() => {
                if (dirtyFields.emails) {
                  setUpdateIdentityEmailDialog(true);
                }
                if (dirtyFields.phone || dirtyFields.cell_phone) {
                  setUpdateIdentitySMSDialog(true);
                }
                handleSubmit(onSubmit)();
              }}
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};
// TODO GoCard staging is up to date with dev but uses prod endpoints (secondary address is not deployed yet)
// secondary address back in when DEPLOYED
EditCustomerDetails.fragments = {
  customer: gql`
    fragment EditCustomerDetailsFragment on Customer {
      _id
      address
      company_name
      goauto_employee
      first_name
      last_name
      nickname
      preferred_language
      postal_code
      salutation
      fullname
      city
      created
      province
      phone
      cell_phone
      home_phone
      work_phone
      date_of_birth
      drivers_license
      drivers_license_expiry_date
      drivers_license_province
      identities {
        identity_type
        identity_value
      }
      insurance {
        _id
        expiry_date
        name
      }
      emails {
        email
        primary
      }
    }
  `,
};

export default EditCustomerDetails;
