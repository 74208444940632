// External
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React from 'react';

// Material UI
import {
  Card,
  CardContent,
  Grid,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  useTheme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';

import { makeStyles } from '@mui/styles';

import ClearIcon from '@mui/icons-material/Clear';

// Internal
import ConfirmDialog from 'components/MaterialUI/ConfirmDialog';

import { cloudinaryCore, defaultTZ } from 'utils';

import DateDisplay from './DateDisplay';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '300px',
    paddingBottom: 0,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  tableRow: {
    paddingBottom: 0,
    paddingTop: 0,
    border: 'none',
    fontWeight: 'bold',
  },
  tableValue: {
    paddingBottom: 0,
    paddingTop: 0,
    border: 'none',
  },
}));

const DETACH_CUSTOMER_RECORD = gql`
  mutation detachCustomerRecords($_id: ID!, $records: [String]!) {
    detachCustomerRecords(_id: $_id, records: $records) {
      records
    }
  }
`;

const SalesHistory = ({ customer }) => {
  const desktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const medium = useMediaQuery(theme => theme.breakpoints.between('sm', 'lg'));

  const [records, setRecords] = React.useState(customer.records || []);
  const [record, setRecord] = React.useState();
  const [detachRecord] = useMutation(DETACH_CUSTOMER_RECORD, {
    onCompleted: data => {
      setRecords(data.detachCustomerRecords.records);
      enqueueSnackbar(`Successfully detached record`, {
        variant: 'success',
      });
    },
    onError: e =>
      enqueueSnackbar(`Error in detaching record`, {
        variant: 'error',
      }),
  });
  const { enqueueSnackbar } = useSnackbar();

  const paymentFrequencyDisplay = payment_frequency =>
    payment_frequency === 'B'
      ? 'bi-weekly'
      : payment_frequency === 'M'
      ? 'monthly'
      : payment_frequency === 'W'
      ? 'weekly'
      : '';

  const [showDetachConfirm, setShowDetachConfirm] = React.useState(false);
  const handleCloseDialog = () => {
    setShowDetachConfirm(false);
  };
  const handleOpenDialog = record => {
    setRecord(record);
    setShowDetachConfirm(true);
  };
  const handleClear = () => {
    detachRecord({
      variables: {
        _id: customer._id,
        records: [record],
      },
    });
  };

  const generateImageURL = cloudinaryURL => {
    const url = cloudinaryURL
      ? cloudinaryCore.url(cloudinaryURL, {
          width: 250,
          height: 200,
          crop: 'fit',
        })
      : null;

    return url;
  };

  const classes = useStyles();
  const theme = useTheme();
  return (
    <>
      <ConfirmDialog
        text="Are you sure you want to remove this from the customer?"
        onConfirm={handleClear}
        isOpen={showDetachConfirm}
        onClose={handleCloseDialog}
        confirmButtonProps={{
          style: theme.actions.confirm,
          variant: 'outlined',
        }}
        abortButtonProps={{
          variant: 'outlined',
        }}
      />
      <Card className={classes.root}>
        <CardContent>
          <Typography className={classes.title} gutterBottom>
            Sales History
          </Typography>
          {customer.sales_history.length === 0 &&
            'No sales history for this customer'}
          {customer.sales_history
            .filter(sales_history => records.includes(sales_history.record_urn))
            .sort((a, b) => -a.date_created.localeCompare(b.date_created))
            .map((sales_history, index) => {
              return (
                <Card
                  style={{ marginBottom: '15px', marginTop: '15px' }}
                  key={sales_history.id}
                >
                  <CardContent>
                    <Grid
                      container
                      direction="row"
                      justifyContent={'space-between'}
                      alignItems="flex-start"
                      spacing={2}
                    >
                      <Grid
                        item
                        style={{
                          paddingRight: 0,
                          paddingTop: desktop ? 8 : 16,
                        }}
                      >
                        <DateDisplay date={sales_history.date_created} />
                      </Grid>
                      <Hidden lgUp>
                        <Grid item xs={7}>
                          <ListItemText
                            primaryTypographyProps={{
                              style: { fontWeight: 'bold' },
                            }}
                            primary={`${sales_history.year} ${sales_history.make_name} ${sales_history.model_name}`}
                            secondary={`${
                              sales_history.dealer?.dealer_name ??
                              'Unknown Dealer'
                            }`}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <IconButton
                            size="small"
                            onClick={handleOpenDialog.bind(
                              this,
                              sales_history.record_urn,
                            )}
                          >
                            <ClearIcon />
                          </IconButton>
                        </Grid>
                        <Grid item xs={12}>
                          <ListItem style={{ paddingLeft: 0 }}>
                            <Table className={classes.table}>
                              <TableBody>
                                <TableRow>
                                  <TableCell
                                    className={classes.tableRow}
                                    align="left"
                                  >
                                    DMS Customer:
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableValue}
                                    align="left"
                                  >
                                    {sales_history.customer_number}
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableRow}
                                    align="left"
                                  >
                                    Payment Type:
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableValue}
                                    align="left"
                                  >
                                    {sales_history.payment_type}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    className={classes.tableRow}
                                    align="left"
                                  >
                                    DMS Deal #:
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableValue}
                                    align="left"
                                  >
                                    {sales_history.deal_number}
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableRow}
                                    align="left"
                                  >
                                    VIN:
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableValue}
                                    align="left"
                                  >
                                    {sales_history.vin}
                                  </TableCell>
                                </TableRow>
                                {(sales_history.payment_type === 'Finance' ||
                                  sales_history.payment_type === 'Lease') && (
                                  <>
                                    <TableRow>
                                      <TableCell
                                        className={classes.tableRow}
                                        align="left"
                                      >
                                        {`${
                                          sales_history.payment_type ===
                                          'Finance'
                                            ? 'Finance'
                                            : 'Lease'
                                        } Term:`}
                                      </TableCell>
                                      <TableCell
                                        className={classes.tableValue}
                                        align="left"
                                      >
                                        {`${sales_history.finance_term} months`}
                                      </TableCell>
                                      {sales_history.payment_type ===
                                        'Lease' && (
                                        <>
                                          <TableCell
                                            className={classes.tableRow}
                                            align="left"
                                          >
                                            Residual Value:
                                          </TableCell>
                                          <TableCell
                                            className={classes.tableValue}
                                            align="left"
                                          >
                                            {`$ ${
                                              sales_history.lease_end_value || 0
                                            }`}
                                          </TableCell>
                                        </>
                                      )}
                                    </TableRow>
                                    <TableRow>
                                      <TableCell
                                        className={classes.tableRow}
                                        align="left"
                                      >
                                        {`${
                                          sales_history.payment_type ===
                                          'Finance'
                                            ? 'Last Payment'
                                            : 'Lease End'
                                        }:`}
                                      </TableCell>
                                      <TableCell
                                        className={classes.tableValue}
                                        align="left"
                                      >
                                        {moment(sales_history.last_payment_date)
                                          .tz(defaultTZ)
                                          .format('MMMM Do YYYY')}
                                      </TableCell>
                                    </TableRow>

                                    <TableRow>
                                      <TableCell
                                        className={classes.tableRow}
                                        align="left"
                                      >
                                        {`${
                                          sales_history.payment_type ===
                                          'Finance'
                                            ? 'APR:'
                                            : 'Money Factor (APR):'
                                        }`}
                                      </TableCell>
                                      <TableCell
                                        className={classes.tableValue}
                                        align="left"
                                      >
                                        {`${sales_history.rate} %`}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell
                                        className={classes.tableRow}
                                        align="left"
                                      >
                                        Payment Amount:
                                      </TableCell>
                                      <TableCell
                                        className={classes.tableValue}
                                        align="left"
                                      >
                                        {`$ ${
                                          sales_history.payment_amount
                                        } ${paymentFrequencyDisplay(
                                          sales_history.payment_frequency,
                                        )}`}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      {sales_history.payment_type ===
                                        'Lease' && (
                                        <>
                                          <TableCell
                                            className={classes.tableRow}
                                            align="left"
                                          >
                                            Residual Value:
                                          </TableCell>
                                          <TableCell
                                            className={classes.tableValue}
                                            align="left"
                                          >
                                            {`$ ${
                                              sales_history.lease_end_value || 0
                                            }`}
                                          </TableCell>
                                        </>
                                      )}
                                    </TableRow>
                                  </>
                                )}
                              </TableBody>
                            </Table>
                          </ListItem>
                        </Grid>
                      </Hidden>
                      <Hidden lgDown>
                        <Grid item xs={11}>
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                          >
                            {/* {!medium && (
                              <Grid item>
                                <img
                                  alt={index}
                                  src={generateImageURL(
                                    sales_history.decoded_style.stock_photos
                                      ?.results[0]?.cloudinary_public_id,
                                  )}
                                  width="100%"
                                ></img>
                              </Grid>
                            )} */}
                            <Grid item xs={9}>
                              <List style={{ paddingTop: 0 }} dense={true}>
                                <ListItem className={classes.title}>
                                  {`${sales_history.year} ${
                                    sales_history.make_name
                                  } ${sales_history.model_name} @ ${
                                    sales_history.dealer?.dealer_name ??
                                    'Unknown Dealer'
                                  }`}
                                </ListItem>
                              </List>
                              <TableContainer style={{ width: '75%' }}>
                                <Table className={classes.table}>
                                  <TableBody>
                                    <TableRow>
                                      <TableCell
                                        className={classes.tableRow}
                                        align="left"
                                      >
                                        DMS Customer:
                                      </TableCell>
                                      <TableCell
                                        className={classes.tableValue}
                                        align="left"
                                      >
                                        {sales_history.customer_number}
                                      </TableCell>
                                      <TableCell
                                        className={classes.tableRow}
                                        align="left"
                                      >
                                        Payment Type:
                                      </TableCell>
                                      <TableCell
                                        className={classes.tableValue}
                                        align="left"
                                      >
                                        {sales_history.payment_type}
                                      </TableCell>
                                      {(sales_history.payment_type ===
                                        'Finance' ||
                                        sales_history.payment_type ===
                                          'Lease') && (
                                        <>
                                          <TableCell
                                            className={classes.tableRow}
                                            align="left"
                                          >
                                            {`${
                                              sales_history.payment_type ===
                                              'Finance'
                                                ? 'APR:'
                                                : 'Money Factor (APR):'
                                            }`}
                                          </TableCell>
                                          <TableCell
                                            className={classes.tableValue}
                                            align="left"
                                          >
                                            {`${sales_history.rate} %`}
                                          </TableCell>
                                        </>
                                      )}
                                    </TableRow>
                                    <TableRow>
                                      <TableCell
                                        className={classes.tableRow}
                                        align="left"
                                      >
                                        DMS Deal #:
                                      </TableCell>
                                      <TableCell
                                        className={classes.tableValue}
                                        align="left"
                                      >
                                        {sales_history.deal_number}
                                      </TableCell>
                                      {(sales_history.payment_type ===
                                        'Finance' ||
                                        sales_history.payment_type ===
                                          'Lease') && (
                                        <>
                                          <TableCell
                                            className={classes.tableRow}
                                            align="left"
                                          >
                                            Payment Amount:
                                          </TableCell>
                                          <TableCell
                                            className={classes.tableValue}
                                            align="left"
                                          >
                                            {`$ ${
                                              sales_history.payment_amount
                                            } ${paymentFrequencyDisplay(
                                              sales_history.payment_frequency,
                                            )}`}
                                          </TableCell>
                                        </>
                                      )}
                                    </TableRow>
                                    {(sales_history.payment_type ===
                                      'Finance' ||
                                      sales_history.payment_type ===
                                        'Lease') && (
                                      <>
                                        <TableRow>
                                          <TableCell
                                            className={classes.tableRow}
                                            align="left"
                                          >
                                            {`${
                                              sales_history.payment_type ===
                                              'Finance'
                                                ? 'Finance'
                                                : 'Lease'
                                            } Term:`}
                                          </TableCell>
                                          <TableCell
                                            className={classes.tableValue}
                                            align="left"
                                          >
                                            {`${sales_history.finance_term} months`}
                                          </TableCell>
                                          {sales_history.payment_type ===
                                            'Lease' && (
                                            <>
                                              <TableCell
                                                className={classes.tableRow}
                                                align="left"
                                              >
                                                Residual Value:
                                              </TableCell>
                                              <TableCell
                                                className={classes.tableValue}
                                                align="left"
                                              >
                                                {`$ ${
                                                  sales_history.lease_end_value ||
                                                  0
                                                }`}
                                              </TableCell>
                                            </>
                                          )}
                                          <TableCell
                                            className={classes.tableRow}
                                            align="left"
                                          >
                                            VIN:
                                          </TableCell>
                                          <TableCell
                                            className={classes.tableValue}
                                            align="left"
                                          >
                                            {sales_history.vin}
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell
                                            className={classes.tableRow}
                                            align="left"
                                          >
                                            {`${
                                              sales_history.payment_type ===
                                              'Finance'
                                                ? 'Last Payment'
                                                : 'Lease End'
                                            }:`}
                                          </TableCell>
                                          <TableCell
                                            className={classes.tableValue}
                                            align="left"
                                          >
                                            {moment(
                                              sales_history.last_payment_date,
                                            )
                                              .tz(defaultTZ)
                                              .format('MMMM Do YYYY')}
                                          </TableCell>
                                        </TableRow>
                                      </>
                                    )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Grid>
                            <Grid item>
                              <IconButton
                                size="small"
                                onClick={handleOpenDialog.bind(
                                  this,
                                  sales_history.record_urn,
                                )}
                              >
                                <ClearIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Hidden>
                    </Grid>
                  </CardContent>
                </Card>
              );
            })}
        </CardContent>
      </Card>
    </>
  );
};

SalesHistory.fragments = {
  customer: gql`
    fragment SalesHistoryFragment on Customer {
      records
      sales_history {
        id
        dealer {
          dealer_name
        }
        make_name
        model_name
        model_number
        vin
        year
        trim
        date_created
        finance_term
        sell_rate_apr
        customer_number
        deal_number
        rate
        payment_amount
        lease_end_value
        payment_frequency
        last_payment_date
        payment_type
        acode
        record_urn
        # decoding is slow, so we won't use this for now
        # until things are optimized
        # decoded_style {
        #   acode
        #   style_id
        #   stock_photos {
        #     results {
        #       id
        #       cloudinary_public_id
        #     }
        #   }
        # }
      }
    }
  `,
};

export default SalesHistory;
