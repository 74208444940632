// External
import React from 'react';
import gql from 'graphql-tag';

// MUI
import { Box, Chip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

// Internal
import { formatPrice, getFullDateTime, titleCase, withSuffix } from 'utils';

const useStyles = makeStyles(theme => ({
  flexContainer: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  priceFlexContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  body2Text: {
    color: theme.colours.greys.greytext1,
  },
  priceSpacing: {
    margin: '10px 0',
  },
  tagColour: {
    color: theme.actions.confirm.backgroundColor,
    marginLeft: '10px',
  },
}));

const UnitDetails = ({ vehicle }) => {
  const classes = useStyles();

  const yearMake = `${vehicle.model?.make?.year} ${vehicle.model?.make?.name}`;

  return (
    <Box m="20px 10px">
      <Box className={classes.flexContainer}>
        <Typography
          variant="h5"
          style={{ fontWeight: 'bold', marginRight: '10px' }}
        >
          {yearMake}
        </Typography>
        <Typography>{vehicle.model?.name}</Typography>
        <Chip
          size="small"
          label={titleCase(vehicle.stockStatus.name)}
          style={{ marginLeft: '10px' }}
        ></Chip>
      </Box>
      <Typography>{vehicle.model?.make?.manufacturer?.name}</Typography>
      <Typography
        variant="body2"
        className={classes.body2Text}
      >{`${vehicle.model?.npvType.name} / ${vehicle.stockType} / Stock #${vehicle.stockNumber}`}</Typography>
      <Box className={classes.priceFlexContainer}>
        <Typography variant="h5" className={classes.priceSpacing}>
          {/* Special Price is based off of Regular Price */}
          {formatPrice(vehicle.specialPrice)}
        </Typography>
        {vehicle.isOnSpecial && (
          <LocalOfferIcon className={classes.tagColour} />
        )}
      </Box>
      <Typography variant="body2">{`Created: ${getFullDateTime(
        withSuffix(vehicle.createdAt, 'Z'),
      )}`}</Typography>
    </Box>
  );
};

UnitDetails.fragments = {
  vehicle: gql`
    fragment NpvVehicleDetails on NpvVehicle {
      id
      dealerId
      createdAt
      model {
        id
        make {
          id
          manufacturer {
            id
            name
          }
          name
          year
        }
        name
        npvType {
          id
          name
        }
      }
      regularPrice
      specialPrice
      isOnSpecial
      stockNumber
      stockStatus {
        id
        name
      }
      stockType
      trim
    }
  `,
};

export default UnitDetails;
