import React, { useState } from 'react';
import gql from 'graphql-tag';

import {
  Box,
  Divider,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { DealerPicker } from 'components/MaterialUI';
import { useSnackMutation } from 'utils/useSnackMutation';
import { parseErrors } from 'modules/npv_inventory/utils';

import UnitDetails from './UnitDetails';
import UnitSpecs from './UnitSpecs';
import UnitFeatures from './UnitFeatures';
import UnitPhotos from './UnitPhotos';
import UnitMedia from './UnitMedia';
import UnitPricing from './UnitPricing';

const STEP_LABELS = [
  'Details',
  'Pricing',
  'Specs',
  'Features',
  'Photos',
  'Media',
];

const CREATE_NPV_VEHICLE = gql`
  mutation createNpvVehicle($data: NpvVehicleInput!) {
    npv {
      createVehicle(data: $data) {
        id
        cost
        defaultDownPayment
        description
        modelId
        msrp
        odometer
        odometerUnits
        privateNotes
        regularPrice
        specialPriceCash
        specialPriceFinance
        stockNumber
        stockStatusId
        stockType
        vin
        model {
          id
          name
          npvType {
            id
            name
          }
          make {
            id
            name
            year
            manufacturer {
              id
              name
            }
          }
        }
        ...NpvVehiclePhotos
        ...NpvVehicleVideos
        ...NpvVehicleSpecs
        ...NpvVehicleFeatures
      }
    }
  }
  ${UnitPhotos.fragments.vehicle}
  ${UnitMedia.fragments.vehicle}
  ${UnitSpecs.fragments.vehicle}
  ${UnitFeatures.fragments.vehicle}
`;

const UPDATE_NPV_VEHICLE = gql`
  mutation updateNpvVehicle($id: Int!, $data: NpvVehicleInput!) {
    npv {
      updateVehicle(id: $id, data: $data) {
        id
        cost
        defaultDownPayment
        description
        modelId
        msrp
        odometer
        odometerUnits
        privateNotes
        regularPrice
        specialPriceCash
        specialPriceFinance
        stockNumber
        stockStatusId
        stockType
        vin
        model {
          id
          name
          npvType {
            id
            name
          }
          make {
            id
            name
            year
            manufacturer {
              id
              name
            }
          }
        }
        ...NpvVehicleUnitPricing
        ...NpvVehiclePhotos
        ...NpvVehicleVideos
        ...NpvVehicleSpecs
        ...NpvVehicleFeatures
      }
    }
  }
  ${UnitPricing.fragments.vehicle}
  ${UnitPhotos.fragments.vehicle}
  ${UnitMedia.fragments.vehicle}
  ${UnitSpecs.fragments.vehicle}
  ${UnitFeatures.fragments.vehicle}
`;

const CreateUnit = () => {
  const [step, setStep] = useState(0);
  const [vehicle, setVehicle] = useState(null);

  const next = () => setStep(prevStep => prevStep + 1);
  const back = () => setStep(prevStep => prevStep - 1);

  // Queries
  const [createNpvVehicle, { loading: createLoading }] = useSnackMutation(
    CREATE_NPV_VEHICLE,
    'Vehicle created successfully!',
    e => `Error creating vehicle: ${parseErrors(e)}`,
    data => {
      setVehicle(data.npv.createVehicle);
      next();
    },
  );

  // Mutations
  const [updateNpvVehicle, { loading: updateLoading }] = useSnackMutation(
    UPDATE_NPV_VEHICLE,
    'Vehicle updated successfully!',
    e => `Error updating vehicle: ${parseErrors(e)}`,
    data => {
      setVehicle(data.npv.updateVehicle);
      next();
    },
  );

  const stepForm = () => {
    switch (step) {
      case 0:
        return (
          <UnitDetails
            back={back}
            step={step}
            isLoading={createLoading || updateLoading}
            createNpvVehicle={createNpvVehicle}
            updateNpvVehicle={updateNpvVehicle}
            vehicle={vehicle}
          />
        );
      case 1:
        return (
          <UnitPricing
            back={back}
            updateLoading={updateLoading}
            updateNpvVehicle={updateNpvVehicle}
            vehicle={vehicle}
          />
        );
      case 2:
        return (
          <UnitSpecs
            back={back}
            vehicle={vehicle}
            updateLoading={updateLoading}
            updateNpvVehicle={updateNpvVehicle}
          />
        );
      case 3:
        return (
          <UnitFeatures
            back={back}
            vehicle={vehicle}
            updateLoading={updateLoading}
            updateNpvVehicle={updateNpvVehicle}
          />
        );
      case 4:
        return (
          <UnitPhotos
            next={next}
            back={back}
            vehicle={vehicle}
            setVehicle={setVehicle}
          />
        );
      case 5:
        return <UnitMedia step={step} back={back} vehicle={vehicle} />;
      default:
        return null;
    }
  };

  return (
    <Box margin={1}>
      <Box component="div" marginBottom={2}>
        <DealerPicker />
      </Box>
      <Box component="div" marginBottom={3}>
        <Typography variant="h4">Create a Unit</Typography>
      </Box>
      <Box component="div" marginBottom={3}>
        <Stepper activeStep={step} style={{ flexWrap: 'wrap' }}>
          {STEP_LABELS.map((label, index) => (
            <Step key={label}>
              <StepLabel
                icon={
                  step > index ? (
                    <CheckCircleIcon style={{ color: 'green' }} />
                  ) : (
                    <></>
                  )
                }
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Paper>
        <Box paddingLeft={2} paddingTop={2}>
          <Box component="div" marginBottom={3}>
            <Box whiteSpace="pre-line">
              <Typography variant="h5">{STEP_LABELS[step]}</Typography>
            </Box>
            <Divider />
          </Box>
        </Box>
        <Box paddingLeft={2}>{stepForm()}</Box>
      </Paper>
    </Box>
  );
};

export default CreateUnit;
