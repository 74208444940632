import React from 'react';

import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';

import { useMutation } from '@apollo/react-hooks';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from '@mui/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import DriveEtaIcon from '@mui/icons-material/DriveEta';

import { useMediaQuery } from '@mui/material';
import VehicleDetailsModal from './VehicleModal';

const DETACH_VEHICLE_FROM_CUSTOMER = gql`
  mutation detachVehicleFromCustomer($_id: ID!, $vehicle_id: ID!) {
    detachVehicleFromCustomer(_id: $_id, vehicle_id: $vehicle_id) {
      vehicles {
        _id
        acode
        balance_owing
        balloon_amount
        body_type
        cash_price
        date_purchased
        deal_number
        deal_recap {
          costAdjustmentsBackItems {
            cost
            gross
            name
            sale
          }
          costAdjustmentsFrontItems {
            cost
            gross
            name
            sale
          }
          insuranceItems {
            cost
            gross
            name
            sale
          }
          serviceContract {
            cost
            gross
            name
            sale
          }
          totalBackGross
          totalDealerGross
          totalFrontGross
        }
        deal_type
        equity
        exterior_colour
        finance_amount
        finance_term
        first_payment_date
        last_payment_date
        lender
        make
        model
        odometer
        payment_amount
        payment_frequency
        payment_type
        purchase_mbi_cost
        purchased_at {
          dealer_name
        }
        rate
        sales_deal_type
        sell_rate_apr
        trim
        vin
        year
      }
      past_vehicles {
        _id
        acode
        balance_owing
        balloon_amount
        body_type
        cash_price
        date_purchased
        deal_number
        deal_type
        deal_recap {
          costAdjustmentsBackItems {
            cost
            gross
            name
            sale
          }
          costAdjustmentsFrontItems {
            cost
            gross
            name
            sale
          }
          insuranceItems {
            cost
            gross
            name
            sale
          }
          serviceContract {
            cost
            gross
            name
            sale
          }
          totalBackGross
          totalDealerGross
          totalFrontGross
        }
        equity
        exterior_colour
        finance_amount
        finance_term
        first_payment_date
        last_payment_date
        lender
        make
        model
        odometer
        payment_amount
        payment_frequency
        payment_type
        purchase_mbi_cost
        purchased_at {
          dealer_name
        }
        rate
        sales_deal_type
        sell_rate_apr
        trim
        vin
        year
      }
    }
  }
`;

const DELETE_PAST_VEHICLE_FROM_CUSTOMER = gql`
  mutation deletePastVehicleFromCustomer($_id: ID!, $vehicle_id: ID!) {
    deletePastVehicleFromCustomer(_id: $_id, vehicle_id: $vehicle_id) {
      past_vehicles {
        _id
        year
        make
        model
        trim
        vin
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  chip: {
    fontSize: 10,
    paddingLeft: 2,
    paddingRight: 2,
  },
}));

const VehicleListItem = ({
  vehicle,
  customer,
  vehicleStatus,
  refetchCustomer,
}) => {
  const classes = useStyles();
  const title = `${vehicle.year} ${vehicle.make} ${vehicle.model} ${
    !!vehicle.trim && vehicle.trim !== 'None' ? vehicle.trim : ''
  }`;
  const desktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const { enqueueSnackbar } = useSnackbar();
  const [detachVehicleForCustomer] = useMutation(DETACH_VEHICLE_FROM_CUSTOMER);
  const [deletePastVehicleFromCustomer] = useMutation(
    DELETE_PAST_VEHICLE_FROM_CUSTOMER,
  );
  const mutationPayload = {
    variables: {
      _id: customer._id,
      vehicle_id: vehicle._id,
    },
  };
  const alert = customer.alerts.filter(obj => obj.vin === vehicle.vin);

  return (
    <ListItem autoFocus={true}>
      <ListItemAvatar>
        <DriveEtaIcon />
      </ListItemAvatar>
      <ListItemText
        primaryTypographyProps={{
          style: {
            fontSize: 14,
            fontWeight: 'normal',
            maxWidth: desktop ? '' : '75%',
          },
        }}
        primary={title}
        secondary={
          <>
            {alert.length > 0 && (
              <Chip
                size="small"
                label={alert[0].type}
                className={classes.chip}
              />
            )}
          </>
        }
      />
      <ListItemSecondaryAction>
        <IconButton edge="end" aria-label="delete" size="small">
          <VehicleDetailsModal
            customer={customer}
            refetchCustomer={refetchCustomer}
            defaultValues={vehicle}
            vehicleStatus={vehicleStatus}
          />
        </IconButton>
        <IconButton
          edge="end"
          aria-label="delete"
          size="small"
          onClick={() => {
            const deleteIconMutation = arg =>
              vehicleStatus === 'current'
                ? detachVehicleForCustomer(arg)
                : deletePastVehicleFromCustomer(arg);

            deleteIconMutation(mutationPayload)
              .then(r => {
                enqueueSnackbar(`Successfully updated ${customer.fullname}`, {
                  variant: 'success',
                });
                refetchCustomer();
              })
              .catch(e => {
                enqueueSnackbar(`Error in updating ${customer.fullname}`, {
                  variant: 'error',
                });
              });
          }}
        >
          <DeleteIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

VehicleListItem.fragments = {
  customer: gql`
    fragment VehicleListItemFragment on Customer {
      _id
      alerts {
        vin
      }
      fullname
      vehicles {
        _id
        year
        make
        model
        vin
      }
      past_vehicles {
        _id
        year
        make
        model
      }
      ...VehicleDetailsModalFragment
    }
    ${VehicleDetailsModal.fragments.customer}
  `,
};

export default VehicleListItem;
