import React, { useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import { formatPhoto } from '../utils';
import { useMediaQuery } from '@mui/material';

const ImageCarousel = ({
  photos,
  currentIndex: initialIndex = 0,
  formatPhotoOptions = {},
  ...rest
}) => {
  /* TODO: Move this to a common location, or replace with a better common component */
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const [currentIndex, setCurrentIndex] = useState(initialIndex);

  const options = {
    width: `${isDesktop ? 500 : 300}`,
    ...formatPhotoOptions,
  };

  return (
    <Carousel
      index={currentIndex}
      {...rest}
      onChange={newIndex => setCurrentIndex(newIndex)}
      style={{ width: '500px' }}
    >
      {photos.map(photo => (
        <img
          alt="Vehicle"
          key={photo.id}
          style={{
            paddingTop: '1rem',
            width: '100%',
            objectFit: 'contain',
            minHeight: '465px',
            maxHeight: '465px',
          }}
          src={formatPhoto(photo, options)}
        />
      ))}
    </Carousel>
  );
};

export default ImageCarousel;
