import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { useMediaQuery } from '@mui/material';
import { useDealerContext } from 'components/MaterialUI/DealerContext';

import {
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Box,
  useTheme,
} from '@mui/material';

import { BULLETIN_TYPES } from '../constant';
import EditBulletinContent from './EditBulletinContent';
import AddBulletinContent from './AddBulletinContent';
import Loading from 'components/MaterialUI/Loading';
import ConfirmDialog from 'components/MaterialUI/ConfirmDialog';
import { parseErrors } from 'utils';

const ADD_BULLETIN = gql`
  mutation addBulletin($input: BulletinInput) {
    createBulletin(input: $input) {
      _id
    }
  }
`;

const UPDATE_BULLETIN = gql`
  mutation updateBulletin($id: ID!, $input: NoteUpdateInput) {
    updateBulletin(_id: $id, input: $input) {
      _id
    }
  }
`;
const DELETE_BULLETIN = gql`
  mutation deleteBulletin($id: ID!) {
    deleteBulletin(_id: $id) {
      _id
    }
  }
`;
const BulletinModal = ({ handleClose, customer, existingBulletin = {} }) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const desktop = useMediaQuery(theme => theme.breakpoints.up('sm'));

  const [bulletinType, setBulletinType] = useState(
    existingBulletin?.type || '',
  );
  const [bulletin, setBulletin] = useState(existingBulletin?.body || '');
  const [confirmDelete, setConfirmDelete] = useState(false);
  const { dealerId } = useDealerContext();
  const [addBulletin, { loading: addLoading }] = useMutation(ADD_BULLETIN, {
    onCompleted: () => {
      enqueueSnackbar('Bulletin Saved!', { variant: 'success' });
      handleClose();
    },
    onError: err => {
      const errors = parseErrors(err);
      enqueueSnackbar(
        `Could not save Bulletin error: ${errors.error?.join(', ')}`,
        {
          variant: 'error',
        },
      );
    },
  });

  const [updateBulletin, { loading: updateLoading }] = useMutation(
    UPDATE_BULLETIN,
    {
      onCompleted: () => {
        enqueueSnackbar('Bulletin Edited!', { variant: 'success' });
        handleClose();
      },
      onError: err => {
        const errors = parseErrors(err);
        enqueueSnackbar(
          `Could not edit Bulletin error: ${errors.error?.join(', ')}`,
          {
            variant: 'error',
          },
        );
      },
    },
  );

  const [deleteBulletin, { loading: deleteLoading }] = useMutation(
    DELETE_BULLETIN,
    {
      onCompleted: () => {
        enqueueSnackbar('Bulletin Deleted!', { variant: 'success' });
        handleClose();
      },
      onError: err => {
        const errors = parseErrors(err);
        enqueueSnackbar(
          `Could not delete Bulletin error: ${errors.error?.join(', ')}`,
          {
            variant: 'error',
          },
        );
      },
    },
  );

  const handleType = event => {
    setBulletinType(event.target.value);
  };

  const handleBulletin = event => {
    setBulletin(event.target.value);
  };

  const handleSubmit = () => {
    const bulletinData = {
      body: bulletin,
      type: bulletinType,
      customer_id: customer?._id,
      dealer_id: dealerId,
    };
    if (!bulletinData.body) {
      enqueueSnackbar('Did not save, bulletin is empty.', { variant: 'error' });
    }
    if (existingBulletin?._id) {
      updateBulletin({
        variables: {
          id: existingBulletin._id,
          input: {
            body: bulletinData.body,
          },
        },
      });
    } else {
      addBulletin({
        variables: {
          input: bulletinData,
        },
      });
    }
  };

  const handleDelete = () => {
    deleteBulletin({
      variables: {
        id: existingBulletin._id,
      },
    });
  };

  const bulletins = customer?.bulletins || [];
  const customerBulletinTypes = bulletins.map(bulletin => bulletin.type);
  const validBulletinTypes = BULLETIN_TYPES.filter(
    ({ value }) => !customerBulletinTypes?.includes(value),
  );

  const errors = !bulletinType || !bulletin;

  const buttonSpacing = () =>
    desktop && existingBulletin?._id
      ? { width: '60%', justifyContent: 'space-between' }
      : desktop && !existingBulletin?._id
      ? { width: '50%' }
      : { width: '100%', justifyContent: 'space-between' };

  if (addLoading || updateLoading || deleteLoading) {
    return <Loading />;
  }

  return (
    <>
      <ConfirmDialog
        isOpen={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        onConfirm={handleDelete}
        text="Are you sure you want to delete this bulletin?"
        confirmText="Delete"
        abortText="Cancel"
      />
      {existingBulletin ? (
        <EditBulletinContent
          existingBulletin={existingBulletin}
          bulletinType={bulletinType}
          handleType={handleType}
        />
      ) : (
        <AddBulletinContent
          bulletinOptions={validBulletinTypes}
          type={bulletinType}
          handleType={handleType}
        />
      )}
      <DialogContent>
        <TextField
          label="Bulletin Notes"
          value={bulletin}
          onChange={handleBulletin}
          multiline
          maxRows={8}
          style={{ display: 'flex' }}
        />
      </DialogContent>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '10px 24px 24px 24px',
        }}
      >
        <DialogActions style={buttonSpacing()}>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Cancel
          </Button>
          {existingBulletin?._id && (
            <Button
              style={theme.actions.danger}
              onClick={() => setConfirmDelete(true)}
              variant="contained"
              color="primary"
            >
              Delete
            </Button>
          )}
          <Button
            style={errors ? theme.actions.disabled : theme.actions.confirm}
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            disabled={errors}
          >
            Save
          </Button>
        </DialogActions>
      </Box>
    </>
  );
};

export default BulletinModal;
