// External
import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// Material UI
import {
  Box,
  Button,
  Chip,
  Dialog,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import LaunchIcon from '@mui/icons-material/Launch';
import { Alert } from '@mui/material';

// Internal
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import DealerPicker from 'components/MaterialUI/DealerPicker';
import { useUserContext } from 'components/MaterialUI/UserContext';

import { RO_DETAILS } from './Queries';
import RODetailsAttachments from './RODetailsComponents/RODetailsAttachments';
import RODetailsOverview from './RODetailsComponents/RODetailsOverview';
import RODetailsPayments from './RODetailsComponents/RODetailsPayments/Payments';
import RODetailsLegacyPayments from './RODetailsComponents/RODetailsPayments/LegacyPayments';
import RODetailsPhotos from './RODetailsComponents/RODetailsPhotos';
import RODetailsTable from './RODetailsComponents/RODetailsTable';
import RODetailsVideos from './RODetailsComponents/RODetailsVideos';
import ROPhotoCard from './RODetailsComponents/ROPhotoCard';
import ROVideoCard from './RODetailsComponents/ROVideoCard';
import VehicleChecklists from './VehicleChecklists';
import VehicleChecklistsHeaderTable from './VehicleChecklistsHeaderTable';
import { RoleGroup } from 'constants.js';
import ROBulletinsModal from './ROBulletinsModal';
import BulletinAlert from 'modules/customers/components/CustomerDetails/BulletinAlert';
import ErrorDisplay from 'components/MaterialUI/ErrorDisplay';
import { Loading } from 'components/MaterialUI';

const DEALER_QUERY = gql`
  query dealerInfo($dealerId: Int!) {
    inventory {
      getDealer(id: $dealerId) {
        id
        fortellis_subscription_id
        global_payments_merchant_id
        global_payments_merchant_shared_secret
        global_payments_merchant_sub_account
      }
    }
  }
`;

const ADD_VIDEO = gql`
  mutation addVideo(
    $dealerId: Int!
    $roNumber: String!
    $cloudinaryId: String!
  ) {
    rotracker {
      addVideoToRO(
        dealer_id: $dealerId
        ro_number: $roNumber
        cloudinary_public_id: $cloudinaryId
      ) {
        id
        dealer_id
        ro_number
        video_count
        videos {
          ...ROVideoCardVideo
        }
      }
    }
  }
  ${ROVideoCard.fragments.video}
`;

const REMOVE_VIDEO = gql`
  mutation addVideo($dealerId: Int!, $roNumber: String!, $videoId: ID!) {
    rotracker {
      removeVideoFromRO(
        dealer_id: $dealerId
        ro_number: $roNumber
        video_id: $videoId
      ) {
        id
        dealer_id
        ro_number
        video_count
        videos {
          ...ROVideoCardVideo
        }
      }
    }
  }
  ${ROVideoCard.fragments.video}
`;

const SHARE_VIDEO = gql`
  mutation shareVideo($dealerId: Int!, $roNumber: String!, $videoId: ID!) {
    rotracker {
      shareVideo(
        dealer_id: $dealerId
        ro_number: $roNumber
        video_id: $videoId
      ) {
        id
        ...ROVideoCardVideo
      }
    }
  }
  ${ROVideoCard.fragments.video}
`;

const ADD_PHOTO = gql`
  mutation addPhoto(
    $dealerId: Int!
    $roNumber: String!
    $cloudinaryId: String!
  ) {
    rotracker {
      addPhotoToRO(
        dealer_id: $dealerId
        ro_number: $roNumber
        cloudinary_public_id: $cloudinaryId
      ) {
        id
        dealer_id
        ro_number
        photo_count
        photos {
          ...ROPhotoCardPhoto
        }
      }
    }
  }
  ${ROPhotoCard.fragments.photo}
`;

const REMOVE_PHOTO = gql`
  mutation addPhoto($dealerId: Int!, $roNumber: String!, $photoId: ID!) {
    rotracker {
      removePhotoFromRO(
        dealer_id: $dealerId
        ro_number: $roNumber
        photo_id: $photoId
      ) {
        id
        dealer_id
        ro_number
        photo_count
        photos {
          ...ROPhotoCardPhoto
        }
      }
    }
  }
  ${ROPhotoCard.fragments.photo}
`;

const SHARE_PHOTO = gql`
  mutation sharePhoto($dealerId: Int!, $roNumber: String!, $photoId: ID!) {
    rotracker {
      sharePhoto(
        dealer_id: $dealerId
        ro_number: $roNumber
        photo_id: $photoId
      ) {
        id
        ...ROPhotoCardPhoto
      }
    }
  }
  ${ROPhotoCard.fragments.photo}
`;

const UPDATE_RO = gql`
  mutation updateRO($dealerId: Int!, $roNumber: String!, $update: ROUpdate!) {
    rotracker {
      updateRO(dealer_id: $dealerId, ro_number: $roNumber, update: $update) {
        id
        preferred_name
        sms_contact_number
        customer_email
        gocard_id
        gocard {
          cardId
          cardNumber
          cashBalance
        }
      }
    }
  }
`;

const SEND_ATTACHMENT = gql`
  mutation shareAttachment($attachment_id: ID!) {
    rotracker {
      sendAttachment(attachment_id: $attachment_id) {
        id
      }
    }
  }
`;

const REMOVE_ATTACHMENT = gql`
  mutation removeAttachment($attachment_id: ID!) {
    rotracker {
      removeAttachment(attachment_id: $attachment_id) {
        attachments {
          id
          file_name
          created_by
          created_at
        }
      }
    }
  }
`;

const TabPanel = ({ value, index, children }) =>
  value === index && (
    <Box m={2}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={8}>
            {children}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );

const RODetails = ({ roNumber, dealerId }) => {
  const { dealers, dealerId: dealerPickerId, setDealerId } = useDealerContext();
  dealerId = parseInt(dealerId);

  useEffect(() => {
    if (
      dealerId &&
      dealerPickerId &&
      dealerId !== dealerPickerId &&
      dealers.map(x => x.dealer_id).includes(dealerId)
    ) {
      setDealerId(dealerId);
      enqueueSnackbar(
        `Set current dealer to: ${
          dealers.find(x => x.dealer_id === dealerId)?.dealer_name
        } `,
        { variant: 'warning' },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealerPickerId, dealers]);

  const [paymentsConfigured, setPaymentsConfigured] = useState(false);
  const { data: dealerData, loading: dealerLoading } = useQuery(DEALER_QUERY, {
    variables: { dealerId },
  });
  const { data, loading, refetch, updateQuery } = useQuery(RO_DETAILS, {
    variables: { dealerId, roNumber },
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  });
  const { currentUser } = useUserContext();

  const [addVideo] = useMutation(ADD_VIDEO);
  const [removeVideo] = useMutation(REMOVE_VIDEO);
  const [shareVideo] = useMutation(SHARE_VIDEO);
  const [addPhoto] = useMutation(ADD_PHOTO);
  const [removePhoto] = useMutation(REMOVE_PHOTO);
  const [sharePhoto] = useMutation(SHARE_PHOTO);
  const [updateRO] = useMutation(UPDATE_RO);
  const [sendAttachment] = useMutation(SEND_ATTACHMENT);
  const [removeAttachment] = useMutation(REMOVE_ATTACHMENT);
  const [tabIndex, setTabIndex] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const urlParams = useParams();
  const history = useHistory();
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const [showBulletinModal, setShowBulletinModal] = useState(false);

  useEffect(() => {
    const t =
      {
        videos: 1,
        photos: 2,
      }[urlParams.initialTab] || 0;
    setTabIndex(t);
  }, [urlParams.initialTab]);

  useEffect(() => {
    if (!dealerLoading) {
      const {
        fortellis_subscription_id,
        global_payments_merchant_id,
        global_payments_merchant_shared_secret,
        global_payments_merchant_sub_account,
      } = dealerData?.inventory.getDealer ?? {};

      if (
        fortellis_subscription_id &&
        global_payments_merchant_id &&
        global_payments_merchant_shared_secret &&
        global_payments_merchant_sub_account
      )
        setPaymentsConfigured(true);
    }
  }, [dealerLoading, dealerData]);

  const ro = data?.rotracker?.ro;
  if (loading) {
    return <Loading />;
  } else if (!ro) {
    return <ErrorDisplay>RO {roNumber} not found</ErrorDisplay>;
  }
  const backUrl = `/rotracker`;
  const isUserAllowedPayments = RoleGroup.SERVICE_PAYMENTS.includes(
    currentUser.role,
  );

  // First try to get welcome SMS error msg from rotracker. If there was none,
  // get it from market-sms as that'll be the last point where an error gets set
  const welcome_sms_error_msg =
    ro.welcome_sms_error_msg || ro.welcomeSMS?.error_msg;

  const allBulletins = [
    ...(ro.smsIdentity?.customer?.bulletins || []),
    ...(ro.customer?.bulletins || []),
    ...(ro.emailIdentity?.customer?.bulletins || []),
  ];

  const hasBulletins = allBulletins.length > 0;

  return (
    <>
      <Box marginLeft={1} marginTop={1} paddingLeft={'20px'}>
        <DealerPicker disabled />
      </Box>
      <Toolbar>
        <IconButton
          color="inherit"
          onClick={() => history.push(backUrl)}
          size="small"
        >
          <ArrowBackIosIcon />
        </IconButton>
        <Grid container alignItems="center">
          {/* xs={12} sm={0} doesn't seem to work, it just overrides to xs={12} */}
          <Grid item xs={12} m={4} lg={3} sx={{ margin: 0 }}>
            <Typography variant="h6">
              {ro.preferred_name || ro.customer_name || 'Unknown Customer'}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={2}>
            <Typography variant="subtitle1">
              <b>RO#</b> {ro.ro_number}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={2}>
            <Chip label={`${ro.status}`} color="secondary" />
          </Grid>
        </Grid>
      </Toolbar>
      <Dialog maxWidth="sm" open={showBulletinModal} fullWidth={true}>
        <ROBulletinsModal
          onClose={() => setShowBulletinModal(false)}
          bulletins={allBulletins}
        />
      </Dialog>
      {hasBulletins && (
        <BulletinAlert
          bulletins={ro.customer?.bulletins ?? []}
          after={
            <Button
              variant="contained"
              style={{
                color: 'white',
                backgroundColor: '#2196f3',
                marginLeft: '20px',
              }}
              size="small"
              endIcon={<LaunchIcon />}
              onClick={() => setShowBulletinModal(true)}
            >
              View
            </Button>
          }
        />
      )}
      <Tabs
        indicatorColor="secondary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        value={tabIndex}
        onChange={(_, tabIndex) => setTabIndex(tabIndex)}
      >
        <Tab label="Overview" />
        <Tab label="Videos" />
        <Tab label="Photos" />
        <Tab label="Attachments" />
        <Tab label="Details" />
        <Tab label="Checklists" />
        {isUserAllowedPayments && paymentsConfigured && (
          <Tab
            label="Payments"
            onClick={() => {
              refetch({
                dealerId: ro.dealer_id,
                roNumber: ro.ro_number,
                dart: true,
              });
            }}
          />
        )}
      </Tabs>
      {welcome_sms_error_msg && (
        <Alert severity="warning">
          Failure sending Welcome SMS: {welcome_sms_error_msg}
        </Alert>
      )}
      <TabPanel value={tabIndex} index={0}>
        <RODetailsOverview isDesktop={isDesktop} ro={ro} updateRO={updateRO} />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <RODetailsVideos
          isDesktop={isDesktop}
          ro={ro}
          removeVideo={removeVideo}
          shareVideo={shareVideo}
          addVideo={addVideo}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <RODetailsPhotos
          isDesktop={isDesktop}
          ro={ro}
          removePhoto={removePhoto}
          sharePhoto={sharePhoto}
          addPhoto={addPhoto}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index={3}>
        <RODetailsAttachments
          ro={ro}
          refetch={refetch}
          sendAttachment={sendAttachment}
          removeAttachment={removeAttachment}
          updateQuery={updateQuery}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index={4}>
        <RODetailsTable ro={ro} isDesktop={isDesktop} />
      </TabPanel>
      <TabPanel value={tabIndex} index={5}>
        <VehicleChecklistsHeaderTable ro={ro} dealerId={dealerId} />
        <VehicleChecklists
          ro={ro}
          customer={ro.preferred_name || ro.customer_name || 'Unknown Customer'}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index={6}>
        {ro.uses_billing_system ? (
          <RODetailsPayments ro={ro} isDesktop={isDesktop} refetch={refetch} />
        ) : (
          <RODetailsLegacyPayments
            ro={ro}
            isDesktop={isDesktop}
            refetch={refetch}
          />
        )}
      </TabPanel>
    </>
  );
};

export default RODetails;
